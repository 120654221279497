/*md
# Button link

Button styles like a simple link

```html_example
<button class="b-button_link">Remove</button>
```
*/

.b-button_link {
	@include g-button_link;
}
