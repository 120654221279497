$search-cta-icon-size: 15px;
$second-row-padding-top: 31px;
$hamburger-tools-height: 20px;
$hamburger-tools-padding: 7px $hamburger-tools-height;
$search-suggestions-wrapper-padding: rh(12);

/* stylelint-disable no-descending-specificity */
.b-header {
	position: relative;
	z-index: z(header);

	&-wrapper {
		--side-margin: calc((100vw - 1440px) / 2);
		--area-width: 200px;

		background: color(light);
		display: grid;
		grid-template-areas:
			'.    tools   promo   account     .'
			'.    searchCta   logo    shopping   .'
			'nav    nav      nav     nav    nav'
			'promo2 promo2 promo2 promo2 promo2';
		grid-template-columns: var(--side-margin) minmax(var(--area-width), 1fr) auto minmax(var(--area-width), 1fr) var(--side-margin);

		@include media(l) {
			--side-margin: calc((100vw - 1200px) / 2);
		}

		@include media(m) {
			--side-margin: 34px;
			--area-width: 150px;

			grid-template-areas:
				'.          locale     promo   tools     .'
				'hamburger  hamburger  logo    shopping  .'
				'search     search     search  search    search'
				'nav        nav        nav     nav       nav ';
			grid-template-columns: var(--side-margin) minmax(var(--area-width), 1fr) minmax(var(--area-width), 400px) minmax(var(--area-width), 1fr) var(--side-margin);
		}

		@include media(s) {
			--side-margin: 20px;

			grid-template-areas:
				'promo      promo   promo'
				'hamburger  logo    shopping'
				'search     search  search'
				'nav        nav     nav';
			grid-template-columns: auto 1fr auto;
		}

		&::before {
			align-self: stretch;
			background: #27251f;
			content: '';
			grid-area: 1 / 1 / 2 / -1;
			pointer-events: none;
			user-select: none;
		}

		.b-country_selector-control {
			color: color(dark);
		}
	}

	&-links {
		display: none;

		@include media(l-up) {
			align-items: center;
			display: flex;
			grid-area: account;
			justify-content: center;
			justify-self: right;
			padding: 5px 0;
		}

		&_link:not(:first-child) {
			margin-left: 30px;
		}

		img {
			max-height: 20px;
			width: auto;
		}
	}

	&-tools {
		display: flex;
		grid-area: tools;
		justify-self: left;
		position: relative;
		z-index: 2;

		@include media(m) {
			justify-self: right;
		}

		@include media(s) {
			display: none;
		}

		@include media(m-down) {
			.b-country_selector {
				display: none;
			}
		}

		@include media(l-up) {
			.b-country_selector {
				margin-left: rh(5);
			}
		}
	}

	&-locale {
		display: none;
		grid-area: locale;

		@include media(m) {
			display: flex;
		}
	}

	/* stylelint-enable */
	&-promo {
		@include font(regular, 10px);

		align-self: center;
		color: color(dark);
		grid-area: promo;
		position: relative;
		text-align: center;
		text-transform: uppercase;
	}

	&-promo_content {
		line-height: 30px;
	}

	&-account {
		@include font(regular, 10px);

		color: color(dark);
		display: none;
		justify-self: right;
		margin-right: 20px;
		position: relative;
		text-transform: uppercase;
		z-index: 2;

		@include media(l-up) {
			align-items: center;
			display: flex;
		}

		.b-account_links-link {
			align-items: center;
			color: color(dark);
			display: flex;

			@include icon(user) {
				font-size: 14px;
				margin-right: 4px;

				@include media(m-down) {
					font-size: 18px;
				}
			}

			&.m-logout {
				display: none;
			}

			&.m-sign_in {
				.b-account_links-text {
					display: none;
				}
			}
		}
	}

	&-search_cta {
		grid-area: searchCta;
		padding-top: $second-row-padding-top;

		@include media(m-down) {
			display: none;
		}
	}

	&-search_cta_control {
		@include font(regular, 12px);

		align-items: center;
		background: color(light);
		border: none;
		border-bottom: 1px solid color(lighter4);
		color: color(lighter2);
		display: flex;
		justify-content: space-between;
		margin-top: rh(-2);
		padding: rh(2 0);
		position: relative;
		text-align: left;
		width: 200px;

		@include icon(search, after) {
			font-size: 15px;
		}

		&:focus {
			outline: none;
		}
	}

	&-hamburger_cta {
		align-items: center;
		display: flex;
		grid-area: hamburger;

		@include media (l-up) {
			display: none;
		}
	}

	&-hamburger_cta_control {
		@include g-button_control;

		height: 100%;
		padding: 0 var(--side-margin);

		@include icon(hamburger) {
			display: block;
			font-size: 14px;
			width: 22px;
		}
	}

	&.m-hamburger_opened &-hamburger_cta_control {
		@include icon(close) {
			font-size: 17px;
		}
	}

	&-hamburger_links {
		@include media(l-up) {
			display: none;
		}
		/* stylelint-disable selector-class-pattern */
		&-link {
			padding: 10px 20px;

			img {
				max-height: 24px;
			}

			&.m-rewards_link {
				background: color(navigation_gradient_yellow_bg);
			}

			&.m-blog_link {
				background: color(navigation_gradient_blue_bg);
			}
		}
		/* stylelint-enable selector-class-pattern */
	}

	&-hamburger_tools {
		@include font(regular, 14px);

		border-top: 1px solid color(lighter5);
		padding: rh(6 0 15);

		@include media(l-up) {
			display: none;
		}
	}

	&-hamburger_tools .b-wishlist_link-inner,
	&-hamburger_tools .b-account_links,
	&-hamburger_tools .b-customer_service_menu-control {
		align-items: center;
		display: flex;
		line-height: $hamburger-tools-height;
		padding: $hamburger-tools-padding;

		&::before {
			font-size: 14px;
			margin-right: rh(3);
			width: 20px;
		}
	}

	&-hamburger_tools .m-custom_link {
		.content-asset {
			align-items: center;
			display: flex;
			justify-content: flex-start;
		}

		img {
			height: auto;
			margin-right: rh(3);
			max-width: 20px;
		}
	}

	&-hamburger_tools .b-customer_service_menu-control {
		&::before {
			font-size: 18px;
		}
	}

	&-hamburger_tools .m-custom_link,
	&-hamburger_tools .b-customer_service_menu-control,
	&-hamburger_tools .b-country_selector-control {
		@include font(regular, 14px);

		color: color(dark);
		line-height: $hamburger-tools-height;
		padding: $hamburger-tools-padding;
		text-align: left;
		text-transform: uppercase;
		width: 100%;

		&::after {
			display: none;
		}
	}

	&-hamburger_tool.m-wishlist_link {
		.b-wishlist_link-text {
			display: inline;
			text-transform: uppercase;
		}
	}

	&-hamburger_tool.m-customer_service,
	&-hamburger_tool.m-locale {
		@include media(m) {
			display: none;
		}
	}

	&-hamburger_tool.m-customer_service {
		.b-customer_service_menu-control {
			@include icon(info);
		}
	}

	&-logo {
		grid-area: logo;
		justify-self: center;
		padding: rh(4 0);
		transition: width 0.5s;
		width: 145px;

		@include media(m) {
			width: 200px;
		}

		@include media(l-up) {
			padding: $second-row-padding-top 0 0;
			width: 220px;
		}
	}

	&-shopping {
		align-items: center;
		display: flex;
		grid-area: shopping;
		justify-self: right;

		@include media(l-up) {
			padding-top: $second-row-padding-top;
		}

		@include media(s) {
			margin: 0 var(--side-margin) 0 rh(2);
		}
	}

	&-wishlist {
		margin-right: 20px;
	}

	&-main_navigation {
		grid-area: nav;

		@include media(l-up) {
			border-bottom: 1px solid color(lighter5);
			margin-top: rh(1);
		}
	}

	&-bottom_slot {
		@include font(regular, 12px);

		background-color: color(light);
		grid-area: promo2;
	}

	&-bottom_slot_wrapper {
		align-items: center;
		display: flex;
		justify-content: center;
		padding: rh(4 0);

		@include media(m-down) {
			flex-direction: column;
		}
	}

	&-bottom_slot_col {
		padding: rh(0 14);

		@include media(m-down) {
			padding: rh(1);
		}
	}

	&-tools .b-nav-back_button_cta {
		@include media(m-up) {
			display: none;
		}
	}

	&-search_logo {
		display: none;
		height: 27px;
		margin: rh(6) auto;
		width: 151px;

		@include media(l-up) {
			display: block;
		}
	}

	&-search {
		border-top: 1px solid color(lighter5);
		display: block;
		grid-area: search;

		@include media(l-up) {
			display: none;
		}
	}

	&-search .f-input_text-field {
		border-width: 0;
		padding-right: rh(25);
	}

	&-hamburger_search {
		position: relative;

		@include media(l l-up) {
			display: none;
		}

		.b-header-search_logo {
			display: block;
			width: 138px;
		}

		.b-quick_search {
			padding: 0 20px;
		}

		.b-quick_search-btn {
			&::before {
				@include media(s) {
					font-size: 12px;
				}
			}
		}

		.f-input_text-field {
			@include media(s) {
				line-height: 30px;
			}
		}

		.b-quick_search-input {
			border-bottom: 1px solid color(lighter2);
		}
	}

	&-hamburger_navigation {
		@include media(m-down) {
			background: color(light);
			border-top: 1px solid color(dark);
			height: 100vh;
			min-width: 270px;
			overflow-x: hidden;
			overflow-y: auto;
			overscroll-behavior: contain;
			position: absolute;
			top: 0;
			width: 50%;
			z-index: 3;
		}

		@include media(s) {
			width: calc(100% - 50px);
		}

		.b-quick_search-suggestions_wrapper {
			@include media(m-down) {
				display: none;
			}
		}
	}

	&-hamburger_navigation_close {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 0;
		height: 42px;
		justify-content: center;
		line-height: 0;
		opacity: 0;
		pointer-events: none;
		position: fixed;
		right: calc(50% - 43px);
		top: 0;
		transition: none;
		width: 42px;
		z-index: 5;

		@include icon(close, 12px) {
			color: color(light);
		}

		@include media(s) {
			right: 4px;
		}

		@include media(l-up) {
			display: none;
		}
	}

	&-hamburger_navigation.m-search-open {
		.b-header-hamburger_navigation_content {
			@include media(m-down) {
				display: none;
			}
		}

		.b-quick_search-suggestions_wrapper {
			@include media(m-down) {
				display: block;
			}
		}
	}

	&.m-sticky {
		position: fixed;
		top: 0;
		width: 100%;
	}

	&.m-sticky &-search_logo,
	&.m-sticky .b-quick_search-close {
		display: none;
	}

	&.m-sticky &-wrapper {
		align-items: center;
		grid-template-areas:
			'.       logo    nav     searchCta  account  shopping  .'
			'search  search  search  search     search   search    search';
		grid-template-columns: var(--side-margin) auto 1fr auto auto auto var(--side-margin);
		height: 100%;

		@include media(m-down) {
			grid-template-areas:
				'hamburger  logo    searchCta  shopping'
				'search     search  search     search'
				'nav        nav     nav        nav';
			grid-template-columns: auto 1fr auto;
		}
	}

	&.m-sticky &-wrapper::before {
		display: none;
	}

	&.m-sticky &-logo,
	&.m-sticky &-search_cta,
	&.m-sticky &-search_cta_control,
	&.m-sticky &-shopping {
		padding: 0;
	}

	&.m-sticky &-logo {
		width: 120px;

		@include media(m) {
			justify-self: center;
			width: 180px;
		}

		@include media(xl) {
			width: 170px;
		}

		@include media(m-down) {
			padding: rh(4 0);
		}
	}

	&.m-sticky &-shopping {
		align-items: center;

		@include media(m-down) {
			margin: 0 var(--side-margin) 0 0;
		}
	}

	&.m-sticky &-tools,
	&.m-sticky &-promo,
	&.m-sticky &-search_cta_control_text,
	&.m-sticky &-locale,
	&.m-sticky &-search {
		display: none;
	}

	&.m-sticky &-main_navigation {
		@include media(l-up) {
			border: none;
			justify-self: center;
			margin-top: 0;
		}
	}

	&.m-sticky .b-nav {
		padding-top: 0;
	}

	&.m-sticky .b-nav-text_1 {
		&::after {
			display: none;
		}
	}

	&.m-sticky .b-nav-link_1,
	&.m-sticky .b-nav-link_2,
	&.m-sticky .b-nav-link_3,
	&.m-sticky .b-nav-view_all {
		@include media(l-up) {
			font-size: 11px;
		}
	}

	&:not(.m-sticky) {
		.b-nav-text {
			@include media(l-up) {
				font-size: var(--category-font-size-desktop);
			}

			@include media(m) {
				font-size: var(--category-font-size-tablet);
			}

			@include media(s) {
				font-size: var(--category-font-size-mobile);
			}

			&.b-nav-viewall {
				@include media(m-down) {
					@include font(bold);
				}
			}
		}
	}

	&.m-sticky &-hamburger_cta {
		height: 100%;
	}

	&.m-sticky &-search_cta {
		@include media(m-down) {
			display: block;
		}
	}

	&.m-sticky &-search_cta_control {
		@include hide(text);

		border: none;
		color: color(dark);
		margin-top: 0;
		width: auto;

		&::after {
			font-size: 15px;
			line-height: 1;
			padding-left: 3px;

			@include media(m-down) {
				font-size: 19px;
			}
		}
	}

	&.m-sticky &-links {
		display: none;
	}

	&.m-sticky .b-account_links {
		color: color(dark);
	}

	&.m-sticky &-wishlist,
	&.m-sticky &-account,
	&.m-sticky &-search_cta_control {
		align-self: center;
		margin-right: rh(6);

		@include media(s) {
			margin-right: rh(5);
		}
	}

	&.m-sticky &-account .b-account_links-text {
		display: none;
	}

	&.m-sticky .b-account_links-link.m-logout {
		@include media(l-up) {
			display: none;
		}
	}

	&.m-sticky &-hamburger_navigation {
		@include media(m-down) {
			border-top: 1px solid color(lighter5);
		}
	}

	&.m-sticky .b-logo-image {
		display: none;
	}

	&.m-sticky .b-logo-image_sticky {
		display: block;
	}

	&.m-sticky .b-quick_search,
	&.m-sticky .b-suggestions {
		@include media(l-up) {
			padding: 0;
		}
	}

	&.m-sticky .b-nav-list_1 {
		@include media(l-up) {
			position: static;
		}
	}

	&.m-sticky:not(.m-search_opened) &-wrapper {
		border-bottom: 1px solid color(lighter5);
	}

	&.m-search_opened &-main_navigation,
	&.m-search_opened &-bottom_slot {
		display: none;
	}

	&.m-search_opened .b-logo-image_suggestion {
		display: block;
	}

	&.m-search_opened:not(.m-sticky) .b-quick_search-input .f-input_text-field {
		@include media(l-up) {
			border-width: 1px;
			line-height: 28px;

			&::placeholder {
				color: color(light);
			}
		}
	}

	&.m-search_opened:not(.m-sticky) .b-quick_search-input {
		@include media(l-up) {
			@include f-input_text(_style_1);

			.f-input_text-field {
				text-align: left;
			}
		}
	}

	&.m-search_opened:not(.m-sticky) .b-quick_search-suggestions_wrapper {
		@include media(l-up) {
			margin-top: -$search-suggestions-wrapper-padding;
		}
	}

	&.m-search_opened:not(.m-sticky) &-search {
		@include media(l-up) {
			background: color(light);
			display: block;
			grid-column: 1 / -1;
			grid-row: searchCta / promo2;
			padding-bottom: $search-suggestions-wrapper-padding;
			z-index: 9999;
		}
	}

	&.m-sticky.m-search_opened &-main_navigation {
		display: block;
	}

	&.m-sticky.m-search_opened &-search {
		display: block;
		grid-area: search;
	}

	&.m-sticky.m-search_opened .b-quick_search-suggestions_wrapper {
		max-height: calc(100vh - var(--header-height));
		overflow: auto;
		overscroll-behavior: contain;
	}

	&.m-sticky .b-nav-item_1.m-hide_in_sticky_header {
		display: none;
	}

	&.m-sticky &-hamburger_search &-search_logo,
	&.m-sticky &-hamburger_search &-search {
		display: block;
	}

	&.m-mini_cart_opened .b-overlay {
		z-index: 4;
	}
}

/*
<header class="h-hamburger m-hamburger_opened m-hamburger-level_${level}">
    <div class="h-hamburger-navigation">
        <div class="h-hamburger-navigation_content">
            <div class="h-hamburger-nav m-active_level_${level}">
                <ul class="h-hamburger-list_1">
                    <li class="h-hamburger-item_1 m-active">
                        <div class="h-hamburger-megamenu">
                            <div class="h-hamburger-megamenu_wrapper">
                                <ul class="h-hamburger-list_2">
                                    <li class="h-hamburger-item_2 m-active">
                                        <ul class="h-hamburger-list_3">
                                            <li class="h-hamburger-item_3"></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</header>
*/

.h-hamburger {
	@include media(m-down) {
		&-navigation {
			transform: translateX(-100%);
			transition: transform 0.3s linear;
		}

		&-navigation_content {
			overflow: hidden;
			transform: translateX(0);
			transition: transform 0.5s;

			.m-hamburger-level_2 &,
			.m-hamburger-level_3 & {
				overflow: visible;
			}
		}

		&.m-hamburger-level_2 &-navigation_content,
		&.m-hamburger-level_3 &-navigation_content {
			transform: translateX(-100%);
		}

		&.m-hamburger_opened &-navigation {
			transform: translateX(0);
		}

		&.m-hamburger_opened .b-overlay {
			z-index: 3;
		}

		&.m-hamburger_opened .b-header-hamburger_navigation_close {
			opacity: 1;
			transition: opacity 0.3s linear;
			transition-delay: 0.3s;
		}

		&-list_1,
		&-list_2 {
			display: flex;
			flex-flow: column nowrap;
			justify-content: flex-start;
			width: 100%;

			@include media(m-down) {
				align-content: flex-start;
				align-items: center;
				flex-flow: row wrap;
			}
		}

		.b-nav-megamenu_wrapper {
			left: 100%;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 10;
		}

		.b-customer_service_menu-dropdown_list,
		&-list_3 {
			left: 100%;
			position: absolute;
			top: 0;
			visibility: hidden;
			width: 100%;
			z-index: 10;
		}

		&.m-hamburger-level_3 &-list_3 {
			transform: translateX(-100%);
		}

		&-item_1.m-active .b-nav-megamenu_wrapper,
		&-item_1.m-active .b-customer_service_menu-dropdown_list,
		&-item_2.m-active &-list_3 {
			visibility: visible;

			&::before {
				background: color(light);
				bottom: 0;
				content: '';
				display: block;
				height: 100vh;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: -1;
			}
		}
	}

	&-megamenu_slot {
		display: none;

		@include media(m-down) {
			display: block;
			height: 0;
			opacity: 0;
			visibility: hidden;
			width: 100%;
		}
	}
}
