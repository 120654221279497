/*
	<div class="b-dropdown" data-component="global/Dropdown">
		<div class="b-dropdown-title" data-js-dropdown-control>
			<span class="b-dropdown-item_name">Value 1</span>
		</div>

		 <div class="b-dropdown-wrapper" data-js-dropdown-wrapper>
			<div class="b-dropdown-item" data-js-dropdown-item>
				<span class="b-dropdown-item_name">Value 1</span>
			</div>

			<div class="b-dropdown-item" data-js-dropdown-item>
				<span class="b-dropdown-item_name">Value 2</span>
			</div>
		</div>
	</div>
*/

//Functional Dropdown styles

@mixin g-dropdown(
	$_: root
) {
	@if $_ == root {}

	@if $_ == _wrapper {
		@include scrollbar ($_scrollbar: lighter5, $_thumb: lighter3);

		max-height: 0;
		-webkit-overflow-scrolling: touch;
		position: relative;
		transition: max-height 0.3s ease-in-out;
	}

	@if $_ == _wrapper_expanded {
		max-height: rh(52);
	}

	@if $_ == _item {
		cursor: pointer;
		display: flex;
	}

	@if $_ == _item_disabled {
		pointer-events: none;
	}

	@if $_ == _item_name {
		display: block;
		line-height: 20px;
		min-height: rh(10);
		padding: rh(2.5 1);
		pointer-events: none;
		text-shadow: 0 0 6px color(dark);
		width: 100%;
	}

	@if $_ == _control {
		@include icon(arrow-down, after) {
			color: color(light);
			font-size: 6px;
			position: absolute;
			right: 0;
		}
	}

	@if $_ == _control_expanded {
		&::after {
			content: icon-char(arrow-up);
		}
	}
}
