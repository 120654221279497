/*md
# Banner type 4

Image banner with bold uppercased text and CTA

## Example #1. Centered content:

```html_example
<figure class="g-banner_4">
	<picture class="g-banner_4-image">
		<img src="//placehold.it/800x400" alt="How to shop sale" />
	</picture>

	<figcaption class="g-banner_4-wrapper">
		<p class="g-banner_4-text">
			<span class="g-banner_4-title">
				How to shop<br/>
				the sale like a pro
			</span>
			<span class="g-banner_4-text_block">Your guide to navigating sale season: don’t go shopping without it.</span>
		</p>

		<div class="g-banner_4-cta">
			<a class="g-banner_4-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #2. Left-aligned content:

```html_example
<figure class="g-banner_4 m-left">
	<picture class="g-banner_4-image">
		<img src="//placehold.it/800x400" alt="How to shop sale" />
	</picture>

	<figcaption class="g-banner_4-wrapper">
		<p class="g-banner_4-text">
			<span class="g-banner_4-title">
				How to shop<br/>
				the sale like a pro
			</span>
			<span class="g-banner_4-text_block">Your guide to navigating sale season: don’t go shopping without it.</span>
		</p>

		<div class="g-banner_4-cta">
			<a class="g-banner_4-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #3. Right-aligned content:

```html_example
<figure class="g-banner_4 m-right">
	<picture class="g-banner_4-image">
		<img src="//placehold.it/800x400" alt="How to shop sale" />
	</picture>

	<figcaption class="g-banner_4-wrapper">
		<p class="g-banner_4-text">
			<span class="g-banner_4-title">
				How to shop<br/>
				the sale like a pro
			</span>
			<span class="g-banner_4-text_block">Your guide to navigating sale season: don’t go shopping without it.</span>
		</p>

		<div class="g-banner_4-cta">
			<a class="g-banner_4-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #4. Centered content, image as link:

```html_example
<figure class="g-banner_4">
	<a href="#" class="g-banner_4-image_link">
		<picture class="g-banner_4-image">
			<img src="//placehold.it/800x400" alt="How to shop sale" />
		</picture>
	</a>
	<figcaption class="g-banner_4-wrapper">
		<p class="g-banner_4-text">
			<span class="g-banner_4-title">
				How to shop<br/>
				the sale like a pro
			</span>
			<span class="g-banner_4-text_block">Your guide to navigating sale season: don’t go shopping without it.</span>
		</p>

		<div class="g-banner_4-cta">
			<a class="g-banner_4-cta_link" href="#1">Shop now</a>
		</div>
	</figcaption>
</figure>
```
*/

.g-banner_4 {
	@include g-banner_4;

	&-image {
		@include g-banner_4(_image);
	}

	&-image_link {
		@include g-banner_4(_image_link);
	}

	&-image_link &-image_wrapper {
		@include g-banner_4(_image_link_pic);
	}

	&-cta_link {
		@include g-banner_4(_cta_link);
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_4(_wrapper);
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&-text {
		@include g-banner_4(_text);
	}

	&-title {
		@include g-banner_4(_title);
	}

	&-cta {
		@include g-banner_4(_cta);
	}

	&.m-left &-wrapper {
		@include g-banner_4(_wrapper, align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_4(_wrapper, align_right);
	}

	&.m-white_bg &-text {
		background-color: #fff;
		max-width: 600px;
		padding: rh(10 20);

		@include media(s) {
			padding: rh(3 5);
		}
	}

	&.m-white_bg &-title {
		@include font(bold, 30px, 1.2);

		color: #000;
		margin-bottom: rh(3);
		text-transform: capitalize;

		@include media(s) {
			font-size: 20px;
		}
	}

	&.m-white_bg &-text_block {
		color: #000;

		@include media(s) {
			line-height: 1;
		}
	}

	&.m-white_bg &-cta {
		margin-top: rh(4);
	}

	&.m-white_bg &-cta_link {
		color: #000;
		text-decoration: underline;
	}
}
