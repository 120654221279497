/*md
# Plain text

### Html example

```
<p class="t-text">Regular text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-medium">Bold text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-bold">Bold text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-italic">Italic text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-underline">Underlined text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-uppercase">Uppercased text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-error">Error text: Lorem ipsum dolor sit amet.</p>
```

### Visual presentation

```html_example
	<p class="t-text">Regular text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-medium">Bold text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-bold">Bold text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-italic">Italic text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-underline">Underlined text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-uppercase">Uppercased text: Lorem ipsum dolor sit amet.</p>
	<p class="t-text t-error">Error text: Lorem ipsum dolor sit amet.</p>
```

## Text alignment:

You can use t-text_align-center class to center text and t-text_align-right class to align it to right

### Html example

```
<p class="t-text">Regular text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-text_align-center">Regular text: Lorem ipsum dolor sit amet.</p>
<p class="t-text t-text_align-right">Regular text: Lorem ipsum dolor sit amet.</p>
```

### Default alignment:
```html_example
	<p class="t-text">Regular text: Lorem ipsum dolor sit amet.</p>
```

### Center alignment:
```html_example
	<p class="t-text t-text_align-center">Regular text: Lorem ipsum dolor sit amet.</p>
```

### Right alignment:
```html_example
	<p class="t-text t-text_align-right">Regular text: Lorem ipsum dolor sit amet.</p>
```
*/

.t-text {
	@include t-text;
}

.t-medium {
	@include font(medium);
}

.t-bold {
	@include font(bold);
}

.t-italic {
	font-style: italic;
}

.t-underline {
	text-decoration: underline;
}

.t-uppercase {
	text-transform: uppercase;
}

.t-capitilize {
	text-transform: capitalize;
}

/* stylelint-disable declaration-no-important */
.t-error {
	@include t-text;

	color: color(error) !important;
}
/* stylelint-enable declaration-no-important */
.t-text_align-center {
	text-align: center;
}

.t-text_align-right {
	text-align: right;
}
