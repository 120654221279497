.b-product_price {
	@include font(regular, 18px);
	// stylelint-disable
	.range &-sales {
		color: color(red);
	}

	.range &-list {
		@include media(m-down) {
			display: block;
		}
	}

	.l-pdp .range &-list {
		display: block;
		padding-top: rh(1);
	}
	// stylelint-enable
	&-list &-value {
		color: color(red);
	}

	&-sales + &-list &-value {
		color: color(lighter3);
		font-size: 14px;
		text-decoration: line-through;
	}

	&-sales.m-discount > &-value {
		color: color(red);
	}

	&-label {
		display: none;
	}
}
