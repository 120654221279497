/*
---
name: Titles
category: Typography/Titles
tag:
  - typography
---

Here is all titles you can use inside your content assets.

Additionaly you can add ```m-center``` class to center align title's text

```html
	<div class="t-title-1">Title 1</div>

	<div class="t-title-2">Title 2</div>

	<div class="t-title-3">Title 3</div>

	<div class="t-title-4">Title 4</div>

	<div class="t-title-5">Title 5</div>

	<div class="t-title-blog">Title blog</div>
```
*/

@mixin t-title-1 {
	@include font(bold, 26px, 1);

	color: color(text);
	text-transform: uppercase;

	@include media(s) {
		font-size: 24px;
	}
}

@mixin t-title-2 {
	@include font(bold, 24px, 1);

	color: color(text);

	@include media(s) {
		font-size: 22px;
	}
}

@mixin t-title-3 {
	@include font(bold, 22px, 1);

	color: color(text);
	text-transform: uppercase;

	@include media(s) {
		font-size: 18px;
	}
}

@mixin t-title-4 {
	@include font(bold, 18px, 1);

	color: color(text);
	text-transform: uppercase;

	@include media(s) {
		font-size: 16px;
	}
}

@mixin t-title-5 {
	@include font(bold, 14px, 1);

	color: color(text);
	text-transform: uppercase;
}

@mixin t-title-blog {
	@include font(bold, 20px, 1);

	color: color(text);

	@include media(s) {
		font-size: 18px;
	}
}

@mixin t-title-loyalty {
	@include font(bold, 35px);

	color: color(text);
	margin-bottom: rh(15);
	text-align: center;
	text-transform: uppercase;

	@include media(s) {
		font-size: 18px;
		margin-bottom: rh(9);
	}
}
