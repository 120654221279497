/*md
# Spinner

```html_example
<div style="width: 200px; height: 200px; position: relative;">
	<div class="b-spinner"></div>
</div>
```
*/

.b-spinner {
	background: color(loader_background);
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 3;

	&::after {
		background: url('../../images/loader.svg') no-repeat 50% 50%;
		background-size: contain;
		bottom: 0;
		content: '';
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
}
