/*md
@no-stat

# Color palette

All colors have to be grouped into 2 main lists - `palette` and `semantic_colors`

You can't use hard-coded color values while styling - use `color()` function instead

## Palette

Palette is like an artist's palette. In this list we
have a basic colors, which then can be mapped to `semantic_colors`.
Here we can have main dark and main light colors (originally it's black and white) and etc.

## Semantic colors

It's a good idea to map palette's colors to semantic colors.
For example: in `palette` map we have a red color which then might be used for an error color in
`semantic_colors` map.

```html_example
<style>
.colors {
	display: flex;
	flex-flow: row wrap;
}

[data-color] {
	align-items: stretch;
	border: 1px solid;
	display: flex;
	flex-flow: column;
	height: 150px;
	justify-content: center;
	margin: 10px;
	padding: 10px;
	text-align: center;
	width: 150px;
}

[data-color]::before,
[data-color]::after {
	background: #fff;
	content: attr(data-color);
	font-weight: bold;
	padding: 5px;
}

[data-color]::before {
	background: #fff;
	content: attr(data-text);
}

</style>

<p>Palette</p>
<div class="colors">
	<div data-color="#000000" data-text="dark"     style="background-color: var(--color-dark)" ></div>
	<div data-color="#434343" data-text="lighter1" style="background-color: var(--color-lighter1)"></div>
	<div data-color="#808080" data-text="lighter2" style="background-color: var(--color-lighter2)"></div>
	<div data-color="#a0a0a0" data-text="lighter3" style="background-color: var(--color-lighter3)"></div>
	<div data-color="#d8d8d8" data-text="lighter4" style="background-color: var(--color-lighter4)"></div>
	<div data-color="#ededed" data-text="lighter5" style="background-color: var(--color-lighter5)"></div>
	<div data-color="#f9f9f9" data-text="lighter6" style="background-color: var(--color-lighter6)"></div>
	<div data-color="#ffffff" data-text="light"    style="background-color: var(--color-light)"></div>
	<div data-color="#f3a41b" data-text="yellow"   style="background-color: #f3a41b"></div>
	<div data-color="#d61717" data-text="red"      style="background-color: #d61717"></div>
	<div data-color="#e51a2b" data-text="red2"     style="background-color: #e51a2b"></div>
	<div data-color="#38b756" data-text="green"    style="background-color: #38b756"></div>
	<div data-color="#f7e1e3" data-text="pink"     style="background-color: #f7e1e3"></div>
</div>
```

## Usage

As it was mentioned before - in order to use colors you have to use `color()` function.

Here is how it looks

```scss
.b-block {
	background: color(dark); // this function returns a value from the palette map
}
```
*/

/* stylelint-disable */
// Function returns color from the Color Palette ($palette map)

@function get-color-from($map, $colorName) {
	@if map-has-key($map, $colorName) == false {
		@warn There is no such color '#{$colorName}';
		@return null;
	} @else {
		@return map-get($map, $colorName);
	}
}

// Function searches for a color in both color palettes

@function color($colorName) {
	@if map-has-key($palette, $colorName) {
		@return get-color-from($palette, $colorName);
	} @else if map-has-key($semantic_colors, $colorName) {
		@return get-color-from($semantic_colors, $colorName);
	} @else {
		@warn There is no such color '#{$colorName}';
		@return null;
	}
}

@mixin css-variables-colors {
	:root {
		--color-dark: #000;
		--color-lighter1: #434343;
		--color-lighter2: #808080;
		--color-lighter3: #a0a0a0;
		--color-lighter4: #d8d8d8;
		--color-lighter5: #ededed;
		--color-lighter6: #f9f9f9;
		--color-lighter7: #f4f4f4;
		--color-light: #fff;
		--color-pdp-background: transparent;
	}
}

$palette: (
	dark:          var(--color-dark), // BLACK
	lighter1:      var(--color-lighter1), // GREY 1
	lighter2:      var(--color-lighter2), // GREY 2
	lighter3:      var(--color-lighter3), // GREY 3
	lighter4:      var(--color-lighter4), // GREY 4
	lighter5:      var(--color-lighter5), // GREY 5
	lighter6:      var(--color-lighter6), // GREY 6
	lighter7:      var(--color-lighter7), // GREY 7
	light:         var(--color-light),    // LIGHT

	yellow:        #f3a41b, // YELLOW
	orange:        #ffeed8,
	orange2:       #ff9900,
	red:           #d61717, // RED
	red2:          #e51a2b,
	red3:          #e20000,
	green:         #38b756, // GREEN
	green2:        #b2fce4, // Clearpay GREEN
	transparent:   transparent, // TRANSPARENT
	pink:          #f7e1e3, // PINK
	pink2:         #ffb3c7,
	pink3:         #ff0194,
	pink4:         #ff2358,
	pink5:         #cc0065,
	pink6:         #ff0280,
	pinkhover:     #ff006b
);

$semantic_colors: (
	//# Core semantic colors
	error:                     color(red),
	text:                      color(dark),
	loader_background:         rgba(#fff, 0.7),
	button_3_background:       rgba(#fff, 0.3),

	border:                    color(lighter1),
	border_error:              color(red),

	inputs_border:             color(lighter4),

	link:                      color(lighter1),
	link_hover:                color(lighter1),

	modal_overlay:             rgba(0,0,0,0.4),

	in_stock:                  color(green),

	pdp_background:            var(--color-pdp-background),

	pdp_wishlist:              rgba(246,246,246, 0.65),
	pdp_wishlist_hover:        rgba(246,246,246, 0.85),
	pdp_dark_wishlist:         rgba(249,249,249, 0.2),
	pdp_dark_wishlist_hover:   rgba(249,249,249, 0.3),
	pdp_color_wishlist:        rgba(249,249,249, 0.3),
	pdp_color_wishlist_hover:  rgba(249,249,249, 0.5),

	pdp_add_to_bag_disable:    rgba(0,0,0, 0.15),
	pdp_add_to_bag_text:       rgba(0,0,0, 0.3),

	pdp_slider_arrow:          rgba(246,246,246, 0.65),
	pdp_slider_arrow_hover:    rgba(246,246,246, 0.85),

	zoom_tip:                  rgba(0,0,0,0.5),

	play_btn:                  rgba(255,255,255,0.75),
	play_btn_bg:               rgba(0,0,0,0.75),

	success_message_bg:        rgba(9, 211, 56, 0.1),

	product_slider_bg:         #ffeed8,
	header_promo_bg:           #ffeed8,

	navigation_gradient_yellow_bg: linear-gradient(to left, #fee9c0, #fef6e0),
	navigation_gradient_blue_bg:   linear-gradient(to left, #c6e5f6, #ebf8ff -1%),

	skincare4:		#d3dbef,
	skincare3:		#CDE5D7,
	skincare2:		#C5E5F5,
	skincare1:		#FEE7C1,
	obsession1:		#FEBFD2,
	obsession2:		#F76A4C,
	obsession3:		#FECF64,
	obsession4:		#99E1EE,
	obsession5:		#3E79A3,
	obsession6:		#E3B2F2,
	revpro1:		#F2DDD5,
	revpro2:		#EDEDED,
	revpro3:		#222554,
	iheart1:		#FECFE6,
	iheart2:		#E4E4F3,
	iheart3:		#92CCB3,
	iheart4:		#9AD3E1,
	iheart5:		#58AAB3,
	iheart6:		#FEBA80,
	iheart7:		#FAE98F,
	revmakeup1:		#EF9183,
	revmakeup2:		#FECFE6,
	revmakeup3:		#FEE7C1,
	revmakeup4:		#C5E5F5,
	revmakeup5:		#F1AB9B,
	revmakeup6:		#99A6AA,
	revmakeup7:		#D81340,
	revmakeup8:		#000000,
	pride1:			#ff00ab,

	loyalty_pink:	#ffcee6,
	loyalty_yellow:	#ffebbf,
	loyalty_blue:	#c5e5f6
);
/* stylelint-enable */
