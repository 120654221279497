.b-bonus_summary {
	text-align: left;

	&-title {
		@include media(s) {
			margin-bottom: rh(4);
			pointer-events: none;

			&::after {
				font-size: 7px;
				line-height: 14px;
			}
		}
	}

	&.m-expanded &-title {
		@include media(s) {
			margin-bottom: 0;
		}
	}

	&-title_copy {
		@include font(regular, 16px);

		@include media(s) {
			font-size: 14px;
		}
	}

	&-error_copy {
		@include font(bold, 16px);

		color: color(error);
	}

	&-list {
		@include scrollbar;

		padding-right: rh(2);

		@include media(m) {
			max-height: 100px;
		}

		@include media(l-up) {
			max-height: 145px;
		}
	}

	&.m-expanded &-list {
		@include media(s) {
			@include g-dropdown(_wrapper_expanded);

			max-height: 175px;
			padding: rh(4 2 0 0);
		}
	}
}
