@mixin g-button_1($el: root, $mod: default) {
	@if $mod == default {
		@include g-button;

		background-color: color(dark);
		color: color(light);
		display: block;

		@include media(l-up) {
			&:hover {
				background-color: #000;
				border-color: color(lighter1);
			}
		}
	}

	@if $mod == centered {
		margin: 0 auto;
		padding-left: rh(12);
		padding-right: rh(12);
		width: auto;

		@supports (width: fit-content) {
			width: fit-content;
		}
	}

	@if $mod == small {
		@include media(m) {
			font-size: 11px;
			padding-left: rh(3);
			padding-right: rh(3);
		}
	}
}
