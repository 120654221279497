/*md
# Banners Our Family

50% width image banners stacked on top of eachother.

## Example #1. Three banners, 50% wide image, two of which are flipped along x-axis

```html_example
<figure class="g-banner_ourfamily m-xflipped m-site_width m-smaller_image">

    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>

<figure class="g-banner_ourfamily m-site_width m-pro_logo  m-smaller_image">
    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_Pro_logo_gold.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>

<figure class="g-banner_ourfamily m-xflipped m-site_width  m-smaller_image">
    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_skincare_svg.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>
```

## Example #2. Three full width banners two of which are flipped along x-axis.

```html_example
<figure class="g-banner_ourfamily m-xflipped  m-smaller_image">

    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>

<figure class="g-banner_ourfamily m-pro_logo  m-smaller_image">
    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_Pro_logo_gold.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>

<figure class="g-banner_ourfamily m-xflipped  m-smaller_image">
    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_skincare_svg.svg?$staticlink$" alt="logo"/>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>
```

## Example #2.5. Our family with different CTAs & no logo.

```html_example
<div class="g-text_tile m-centered" style="margin-bottom: 50px; padding-top: 50px;">
    <p class="g-text_tile-main">
        <span class="g-text_tile-text_block">THE REVOLUTIONARIES</span>
    </p>
    <p class="g-text_tile-secondary" style="margin-bottom: 20px;">
        <span class="g-text_tile-text_block">Discover our weekly drops</span>
    </p>
</div>

<figure class="g-banner_ourfamily m-xflipped  m-smaller_image">

    <img class="g-banner_ourfamily-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/pride-pallettes.jpg?$staticlink$" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
         <p class="g-banner_ourfamily-title" style="font-variant: sweet-sans; font-size: 28px;">newness</p>

        <p class="g-banner_ourfamily-small_text">Discover our weekly drops</p>

        <button class="b-button_4">SHOP NOW</button>
    </figcaption>
</figure>

<figure class="g-banner_ourfamily m-pro_logo  m-smaller_image">
    <img class="g-banner_ourfamily-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/conceal-define-foundation.jpg?$staticlink$" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <p class="g-banner_ourfamily-title" style="font-variant: sweet-sans; font-size: 28px;">conceal & define</p>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <button class="b-button_4">SHOP NOW</button>
    </figcaption>
</figure>
```

## Example #3. One full width banner 80/40 (default), with text on the right side (m-xflipped), center aligned (default).

```html_example
<figure class="g-banner_ourfamily m-xflipped">

    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

		<p class="g-banner_ourfamily-title">TITLE</p>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>
```

## Example #4. One full width banner 80/40 (default), text on the right side (m-xflipped), left aligned (m-align_left).

```html_example
<figure class="g-banner_ourfamily m-xflipped m-align_left">

    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt=""/>
    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

		<p class="g-banner_ourfamily-title">TITLE</p>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>
```

## Example #4. One image/text 50/50 (m-smaller_image), text on the left side (default), right aligned (m-align_right), with side margin (m-site_width).

```html_example
<figure class="g-banner_ourfamily m-site_width m-align_right m-smaller_image">

    <img class="g-banner_ourfamily-image" src="//placehold.it/960x960" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <img class="g-banner_ourfamily-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

		<p class="g-banner_ourfamily-title">TITLE</p>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <a class="g-banner_ourfamily-cta_button" href="#">Button Type 2</a>
    </figcaption>
</figure>
```

## Example #5. RevPro Section

```html_example

<div style="
	background-image: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_16_9_bg_blk_1.jpg?$staticlink$);
	background-position: top right;
	background-repeat: no-repeat;
	background-size: 50% 50%;">

	<div class="g-text_tile m-centered m-smallcaps" style="padding: 60px 0;">
		<p class="g-text_tile-main" style="margin: 20px 0;">
			<span class="g-text_tile-text_block">strong pigments, refined formulas,<br> added skincare benefits</span>
		</p>
	</div>

	<div class="b-section m-site_width"  style="margin-bottom:50px">
		<a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center  m-2column" style="align-items: flex-start;">
			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black m-fullwidth m-2column" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_1_1_rev_pro_liner.jpg?$staticlink$" alt="Banner copy" />

					<figcaption class="g-banner_3-wrapper ">
						<p class="g-banner_3-main" style="font-variant: small-caps;">
							skin, meet bakuchol
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px" >
							Everyones talking about this plant-based alternative to Retinol. A dream for sensitive skin.
						</p>
						<button class="b-button_4">BUTTON TYPE 4</button>
					</figcaption>
				</figure>
			</div>

			<div class="b-section-column" >
				<figure class="g-banner_3 m-top_padding m-separated m-black m-fullwidth m-2column m-flip_y" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_4_3_rev_pro_lip.jpg?$staticlink$" alt="Banner copy" />

					<figcaption class="g-banner_3-wrapper">
						<p class="g-banner_3-main" style="font-variant: small-caps;">
							lips
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px" >
							Give your lips some luxury they deserve with our most indulgent lip pigments.
						</p>
						<button class="b-button_4">SHOP NEW IN</button>
					</figcaption>
				</figure>
			</div>
		</a>
	</div>
</div>

<figure class="g-banner_ourfamily m-xflipped m-smaller_image m-bg_color" style="--bgcolor: #EDEDED; margin-bottom: 80px;">

    <img class="g-banner_ourfamily-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_1_1_rev_pro_palettes.gif?$staticlink$" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <p class="g-banner_ourfamily-title">eyeshadow palettes</p>

        <p class="g-banner_ourfamily-small_text">Meet Makeup Obsession's All Star Line up. Everything you need to keep your face in place.</p>

        <button class="b-button_4">SHOP NEW IN</button>
    </figcaption>
</figure>

<div style="
	background-image: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_16_9_bg_blk_2.jpg?$staticlink$);
	background-position: bottom left;
	background-repeat: no-repeat;
	background-size: 80% 80%;">

	<div class="b-section m-site_width"  style="padding-bottom:50px">
		<a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center  m-2column" style="align-items: flex-start;">

			<div class="b-section-column" >
				<figure class="g-banner_3 m-top_padding_square m-separated m-black m-fullwidth m-2column m-flip_y" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_1_1_rev_pro_mascara.jpg?$staticlink$" alt="Banner copy" />

					<figcaption class="g-banner_3-wrapper">
						<p class="g-banner_3-main" style="font-variant: small-caps;">
							lips
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px" >
							Give your lips some luxury they deserve with our most indulgent lip pigments.
						</p>
						<button class="b-button_4">SHOP NEW IN</button>
					</figcaption>
				</figure>
			</div>

			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black m-fullwidth m-2column" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_1_1_rev_pro_primer.jpg?$staticlink$" alt="Banner copy" />

					<figcaption class="g-banner_3-wrapper ">
						<p class="g-banner_3-main" style="font-variant: small-caps;">
							skin, meet bakuchol
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px" >
							Everyones talking about this plant-based alternative to Retinol. A dream for sensitive skin.
						</p>
						<button class="b-button_4">BUTTON TYPE 4</button>
					</figcaption>
				</figure>
			</div>
		</a>
	</div>
</div>
```
*/

.g-banner_ourfamily {
	align-items: center;
	display: flex;

	@include media(m-up) {
		flex-direction: row-reverse;
	}

	@include media(s) {
		flex-direction: column;
	}

	&-image {
		width: 66%;

		@include media(s) {
			width: 100%;
		}
	}

	&.m-smaller_image &-image {
		width: 50%;

		@include media(s) {
			width: 100%;
		}
	}

	&-svg_logo {
		align-self: center;
		grid-area: contents;
		margin: 0 auto rh(8);
		max-width: 300px;
		padding: 0;

		@include media(s) {
			width: 90%;
		}
	}

	&.m-pro_logo &-svg_logo {
		max-width: 440px;
	}

	&-title {
		@include font(bold, 40px, 1.3);

		color: color(dark);
		font-variant: small-caps;

		@include media(s) {
			font-size: 28px;
		}
	}

	&-small_text {
		@include font(medium, 16px, 1.5);

		color: color(dark);
		margin: rh(2) auto rh(4);
		max-width: 380px;
		padding: rh(0 8);
	}

	&-cta_button {
		@include g-button_1;

		border: none;
		margin: 0 auto;
		max-width: 250px;
		position: relative;

		@include media(m) {
			max-width: fit-content;
		}
	}

	&-wrapper {
		flex: 1 1 auto;
		padding: rh(5);
		text-align: center;

		@include media(s) {
			padding: rh(9 0 9);
			width: 100%;
		}
	}

	&-text {
		@include font(bold, 26px, 1.3);

		color: color(dark);

		@include media(s) {
			@include font(bold, 24px);
		}
	}

	&.m-bg_color {
		background-color: var(--bgcolor);
	}

	&-cta {
		@include font(medium, 14px);

		border-bottom: 1px solid color(dark);
		color: color(dark);
		display: inline-block;
		margin-top: rh(5);
		text-transform: uppercase;
	}

	&.m-reverse {
		@include media(m-up) {
			flex-direction: row;
		}
	}

	&.m-initial &-wrapper {
		align-self: flex-start;
		padding: 0;
		text-align: left;
	}

	&.m-reverse &-wrapper {
		padding: 0;
		text-align: left;

		@include media(l-up) {
			padding-left: rh(15);
		}

		@include media(m) {
			padding-left: rh(7);
		}
	}

	&.m-xflipped {
		@include media(m-up) {
			flex-direction: initial;
		}
	}

	&.m-site_width {
		margin-left: auto;
		margin-right: auto;
		max-width: var(--content-width);
		padding: rh(15);

		@include media(s) {
			padding: 0;
		}
	}

	&.m-align_left &-wrapper,
	&.m-align_left &-title {
		text-align: left;

		@include media(s) {
			text-align: center;
		}
	}

	&.m-align_left &-small_text {
		margin-left: 0;
		padding-left: 0;
		text-align: left;

		@include media(s) {
			margin: 0 auto rh(8);
			text-align: center;
		}
	}

	&.m-align_left &-cta_button {
		margin: initial;

		@include media(s) {
			margin: 0 auto;
		}
	}

	&.m-align_right &-small_text {
		margin-right: 0;
		padding-right: 0;
		text-align: right;
	}

	&.m-align_right &-cta_button {
		margin: 0 0 0 auto;

		@include media(s) {
			margin: 0 auto;
		}
	}

	&.m-align_right &-wrapper,
	&.m-align_right &-title {
		text-align: right;

		@include media(s) {
			text-align: center;
		}
	}
}
