/*md
# Links

### Html Example

```
<a href="#" class="t-link">Regular link: Lorem ipsum dolor sit amet</a>
<a href="#" class="t-link_underlined">Underlined link: Lorem ipsum dolor sit amet</a>
```

### Regular Link
```html_example
	<a href="#" class="t-link">Regular link: Lorem ipsum dolor sit amet</a>
```

### Underlined Link
```html_example
	<a href="#" class="t-link_underlined">Underlined link: Lorem ipsum dolor sit amet</a>
```
*/

.t-link {
	@include link(regular, lighter1);
}

.t-link_underlined {
	@include link(underlined, lighter1);
}
