.b-suggestions {
	@include site-width;

	&-header {
		@include font(medium, 12px);

		color: color(lighter2);
		display: flex;
		flex-direction: column;
		margin: rh(6 0 9);

		@include media(s) {
			margin: rh(7 0 8);
		}
	}

	&-header_link {
		color: color(dark);
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	&-body {
		display: flex;
		justify-content: space-between;
		margin-top: rh(6);
		padding-bottom: rh(5);

		@include media(s) {
			flex-wrap: wrap;
		}

		.b-header-hamburger_search & {
			@include media(m-down) {
				flex-wrap: wrap;
			}
		}
	}

	&-products_title,
	&-title {
		@include font(medium, 14px);

		margin-bottom: rh(7);
	}

	&-products_title span,
	&-title {
		text-transform: uppercase;
	}

	&-products_list {
		display: flex;
		flex-wrap: wrap;
	}

	&-products_item {
		width: 50%;

		@include media(s) {
			width: 100%;
		}

		.b-header-hamburger_search & {
			@include media(m-down) {
				width: 100%;
			}
		}
	}

	&-blog {
		display: flex;
	}

	&-blog_picture {
		flex-shrink: 0;
		width: 100px;
	}

	&-blog_image_link {
		@include image_container;

		display: block;
	}

	&-blog_item {
		width: 100%;

		@include media(s) {
			width: 50%;
		}
	}

	&-blog_content {
		flex-grow: 1;
		margin-bottom: 15px;
		min-height: 100px;
		padding: rh(0 5);
		width: 100%;

		@include media(s) {
			width: 50%;
		}
	}

	&-blog_image {
		@include image_container(_children);
	}

	&-blog_title {
		@include font(bold, 14px);

		margin-bottom: 10px;
		margin-top: 5px;
	}

	&-products_item .b-shortproduct {
		margin-bottom: rh(6);
	}

	&-categories {
		border-left: 1px solid color(lighter5);
		flex-shrink: 0;
		padding-left: 5%;
		width: 33%;

		@include media(s) {
			border-left: none;
			border-top: 1px solid color(lighter5);
			padding-left: 0;
			padding-top: rh(9);
			width: 100%;
		}

		.b-header-hamburger_search & {
			@include media(m-down) {
				border-left: none;
				border-top: 1px solid color(lighter5);
				padding-left: 0;
				padding-top: rh(9);
				width: 100%;
			}
		}
	}

	&-list {
		margin-bottom: rh(10);
	}

	&-item + &-item {
		margin-top: rh(2);
	}

	&-item_link {
		@include font(medium, 12px);

		color: color(lighter2);

		strong {
			color: color(dark);
		}
	}

	&-short_desc {
		display: block;
		line-height: 1.8em;
		max-height: 3.6em;
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
	}
}
