/*md
# Brand banner

Image banner with bold main text and tiny secondary info text.
May have regular or reversed content alignments.

May have optional background-color (by inline style).
May have optional top gutter (usually used for the first banner on the page).

Banner title goes on the top for small viewports.

## SCSS usage example:
```
.g-brand_banner {
	@include g-brand_banner(root, default);

	&-section {
		@include site-width;
	}

	&-wrapper {
		@include g-brand_banner(_wrapper);
	}

	&.m-reverse &-wrapper {
		@include g-brand_banner(_wrapper, reverse);
	}

	&-title {
		@include g-brand_banner(_title);

		@include media(m-up) {
			margin-right: rh(15);
		}
	}

	&.m-reverse &-title {
		@include media(m-up) {
			margin-left: rh(15);
			margin-right: 0;
		}
	}

	&-media {
		@include g-brand_banner(_media);
	}

	&-text {
		@include g-brand_banner(_text);

		@include media(m-up) {
			margin-right: rh(15);
		}
	}

	&.m-reverse &-text {
		@include media(m-up) {
			margin-left: rh(15);
			margin-right: 0;
		}
	}

	&-cta {
		@include g-brand_banner(_cta);
	}
}
```
*/

@mixin g-brand_banner($el: root, $mod: default) {
	@if $el == root {}

	@if $el == _wrapper {
		@if $mod == default {
			display: grid;
			grid-template-areas:
				'title    media'
				'text     media';
			grid-template-columns: 1fr 2fr;

			@include media(m-up) {
				grid-template-rows: auto 1fr;
			}

			@include media(m) {
				grid-template-columns: 1fr 1fr;
			}

			@include media(s) {
				grid-template-areas:
					'title'
					'media'
					'text';
				grid-template-columns: 1fr;
			}
		}

		@if $mod == reverse {
			@include media(l-up) {
				grid-template-columns: 2fr 1fr;
			}

			@include media(m-up) {
				grid-template-areas:
					'media    title'
					'media    text';
			}
		}
	}

	@if $el == _title {
		@if $mod == default {
			@include font(bold, 35px);

			color: color(dark);
			display: block;
			grid-area: title;
			text-transform: uppercase;

			@include media(s) {
				font-size: 24px;
			}
		}
	}

	@if $el == _media {
		@if $mod == default {
			grid-area: media;
		}
	}

	@if $el == _text {
		@if $mod == default {
			@include font(medium, 14px, 1.4);

			grid-area: text;
		}
	}

	@if $el == _cta {
		@if $mod == default {
			@include font(medium, 14px);

			border-bottom: 1px solid color(dark);
			color: color(dark);
			display: inline-block;
			text-transform: uppercase;
		}
	}
}
