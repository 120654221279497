///	Animation
///	@example
///		@include scrollbar;

@mixin scrollbar(
	$_scrollbar:  transparent,
	$_thumb:      lighter4
) {
	overflow-y: auto;
	overscroll-behavior: contain;

	&::-webkit-scrollbar {
		background-color: color($_scrollbar);
		width: rh(1.5);
	}

	&::-webkit-scrollbar-thumb {
		background-color: color($_thumb);
		border-radius: rh(1);
	}

	@content;
}
