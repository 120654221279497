.b-swatch_dropdown {
	position: relative;

	&.m-expanded {
		z-index: 1;
	}

	&.m-disabled {
		pointer-events: none;
	}

	&-title {
		@include g-button_1;

		border: none;
		line-height: rh(10);
		padding: 0;
		position: relative;
	}

	&-wrapper {
		@include g-dropdown(_wrapper);

		overflow-y: scroll;
		width: 100%;

		@include media(m-up) {
			position: absolute;
			top: 100%;
		}
	}

	&.m-expanded &-wrapper {
		@include g-dropdown(_wrapper_expanded);

		@include media(s) {
			max-height: rh(41);
		}
	}

	&-item {
		@include g-dropdown(_item);
		@include font(medium, 14px, rh(10));

		background-color: color(lighter5);
		color: color(light);
		text-align: center;
		text-transform: uppercase;
	}

	&-item.m-disabled {
		@include g-dropdown(_item_disabled);
	}

	&-item_name {
		@include g-dropdown(_item_name);
	}

	&-title &-item_name {
		@include g-dropdown(_control);

		padding: rh(2 10 2 2);

		&::after {
			line-height: rh(10);
			right: rh(6);
			top: 0;
		}
	}

	&-item_name.m-white {
		color: color(dark);
		text-shadow: 0 0 6px color(light);

		&::after {
			color: color(dark);
		}
	}

	&-title &-item_name.m-white {
		border: 1px solid color(dark);
	}

	&.m-expanded &-title &-item_name {
		@include g-dropdown(_control_expanded);
	}

	&-item_oos {
		@include font(medium, 11px);

		padding-left: rh(1);
		text-transform: none;
	}

	&-title &-item_oos {
		display: none;
	}
}
