.b-wishlist_button {
	background-color: color(pdp_wishlist);
	border-radius: 50%;
	cursor: pointer;
	display: inline-block;
	font-size: 0;
	height: 36px;
	position: relative;
	text-align: center;
	transition: background-color 0.2s ease;
	width: 36px;

	@include icon(heart) {
		font-size: 14px;
		line-height: 36px;
	}

	&:hover {
		background-color: color(pdp_wishlist_hover);
	}

	&-text {
		display: none;
	}

	&.m-active {
		@include icon(heart-fill) {
			color: color(red2);
			opacity: 1;
			transition: opacity 0.2s linear;
		}

		&:hover::before {
			opacity: 0.8;
		}
	}
}
