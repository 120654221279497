/*md
# Banner type 1

Image banner with bold uppercase text and CTA.
May have one of three content alignments: Center (by default), Left or Right.

May use one of three color schemes:
- White text with black background (by default)
	In this case CTA has black text color, no background.
- Black text
- White text

Each line of text is supposed to go inside of a separate `<span>` container.

## HTML structure example:
```
<figure class="g-banner_1 m-right m-black">
	<img class="g-banner_1-image" src="banner_1.jpg" alt="" />

	<figcaption class="g-banner_1-wrapper">
		<p class="g-banner_1-text">
			<span class="g-banner_1-text_block">Your text line 1</span><br />

			<span class="g-banner_1-text_block">Text line 2</span>
		</p>

		<div class="g-banner_1-cta">
			<a class="g-banner_1-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## SCSS usage example:
```
.g-banner_1 {
	@include g-banner_1;

	&-image {
		@include g-banner_1(_image);
	}

	&-image_link {
		@include g-banner_1(_image_link);
	}

	&-image_link &-image {
		@include g-banner_1(_image_link_pic);
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_1(_wrapper);
	}

	&.m-left &-wrapper {
		@include g-banner_1(_align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_1(_align_right);
	}

	&-text {
		@include g-banner_1(_text);
	}

	&-text_block {
		@include g-banner_1(_text_block);
	}

	&.m-black &-text_block {
		@include g-banner_1(_text_block, black);
	}

	&.m-white &-text_block {
		@include g-banner_1(_text_block, white);
	}

	&-cta {
		@include g-banner_1(_cta);
	}

	&-cta_link {
		@include g-banner_1(_cta_link);
	}

	&.m-white &-cta_link {
		@include g-banner_1(_link_white);
	}
}
```
*/

@mixin g-banner_1($el: root, $mod: default) {
	@if $el == root {
		@if $mod == default {
			display: grid;
			grid-template-areas: 'contents';
		}
	}

	@if $el == _image {
		@if $mod == default {
			align-self: stretch;
			grid-area: contents;
			object-fit: cover;
			width: 100%;
		}
	}

	@if $el == _image_link {
		@if $mod == default {
			align-self: stretch;
			display: flex;
			grid-area: contents;
			object-fit: none;
		}
	}

	@if $el == _image_link_pic {
		@if $mod == default {
			grid-area: auto;
		}
	}

	@if $el == _wrapper {
		@if $mod == default {
			display: grid;
			grid-area: contents;
			grid-template-areas: 'empty' 'text' 'cta';
			grid-template-rows: 1fr auto 1fr;
			justify-self: center;
			padding: rh(0 17);
			text-align: center;

			@include media(s) {
				padding: rh(0 5);
			}
		}

		@if $mod == align_left {
			justify-self: start;
			text-align: left;
		}

		@if $mod == text_center {
			text-align: center;
		}

		@if $mod == align_right {
			justify-self: end;
			text-align: right;
		}
	}

	@if $el == _text {
		@if $mod == default {
			@include font(bold, 40px, 1.3);

			grid-area: text;
			text-transform: uppercase;

			@include media(m) {
				@include font(bold, 30px);
			}

			@include media(s) {
				@include font(bold, 26px);
			}
		}
	}

	@if $el == _cta {
		@if $mod == default {
			grid-area: cta;
		}
	}

	@if $el == _text_block {
		@if $mod == default {
			background-color: color(dark);
			color: color(light);
			padding: rh(0 2);
		}

		@if $mod == black {
			background-color: color(transparent);
			color: color(dark);
			padding: 0;
		}

		@if mod == white {
			background-color: color(transparent);
			color: color(light);
			padding: 0;
		}
	}

	@if $el == _cta_link {
		@if $mod == default {
			@include font(medium, 14px);

			border-bottom: 1px solid color(dark);
			color: color(dark);
			display: inline-block;
			margin: rh(10 0 11);
			text-transform: uppercase;

			@include media(s) {
				margin: rh(15 0 7);
			}
		}

		@if $mod == white {
			border-bottom-color: color(light);
			color: color(light);
		}
	}
}
