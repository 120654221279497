/* stylelint-disable declaration-no-important */

/*
#Helper classes
*/

/*
##Hide element with display: none
*/

.h-hidden {
	display: none !important;
}

.h-site_width {
	@include site-width;
}

.h-hide-invisible {
	@include hide(invisible, important);
}

.h-hide-text {
	@include hide(text, important);
}

.h-hide-visually {
	@include hide(visually, important);
}

.h-font-smallcaps {
	@include font(regular);

	font-variant: small-caps;
}

.h-font-smallcapsbold {
	@include font(bold);

	font-variant: small-caps;
}

.h-hide {
	&-s {
		@include media(s) {
			display: none !important;
		}
	}

	&-m {
		@include media(m) {
			display: none !important;
		}
	}

	&-l {
		@include media(l-up) {
			display: none !important;
		}
	}
}

.h-show_only {
	&-s {
		@include media(m-up) {
			display: none !important;
		}
	}

	&-m {
		@include media(s) {
			display: none !important;
		}

		@include media(l-up) {
			display: none !important;
		}
	}

	&-l {
		@include media(m-down) {
			display: none !important;
		}
	}
}

.h-ellipsis {
	display: block;
	height: 100%;
	overflow: hidden;
	position: relative;

	&-line {
		display: inline-block;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		vertical-align: top;
		white-space: nowrap;
		word-wrap: normal;
	}
}

/*
##No hover/click events
*/

.h-no_events {
	pointer-events: none;
}

/*
---
name: Margin Classes
category: Helpers/CSS Classes
---

Margin classes are used for adding top, bottom, left and right spacing to HTML blocks

Numeral ending means number of pixels and it's even to 5 (max number is 30).

```html
	<p class="h-margin-top_5 h-margin-btm_10">Top margin = 5. Bottom margin = 10</p>
	<p class="h-margin-btm_20">Bottom margin = 20</p>
	<p class="h-margin-top_15 h-margin-btm_5">Top margin = 15. Bottom margin = 5</p>
```
*/

@for $i from 0 through 6 {
	.h-margin-top_#{$i * 5} {
		margin-top: rh($i) !important;
	}

	.h-margin-btm_#{$i * 5} {
		margin-bottom: rh($i) !important;
	}

	.h-margin-left_#{$i * 5} {
		margin-left: rh($i) !important;
	}

	.h-margin-right_#{$i * 5} {
		margin-right: rh($i) !important;
	}
}

.h-clearfix {
	@include clearfix;
}

.h-image_loader {
	@include image_loader;
}

/*
Loyalty blocks backgrounds
*/

.h-swell_bg {
	&-silver {
		background-color: color(loyalty_blue) !important;
	}

	&-gold {
		background-color: color(loyalty_yellow) !important;
	}

	&-platinum {
		background-color: color(loyalty_pink) !important;
	}
}
