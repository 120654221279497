.b-tracking_consent {
	@include media(m-up) {
		max-width: 400px;
	}

	&-title {
		@include t-title-3;

		margin-bottom: rh(8);
		text-align: center;

		@include media(s) {
			margin-bottom: rh(6);
		}
	}

	&-content {
		font-size: 14px;
		line-height: 20px;
		margin-bottom: rh(9);

		@include media(s) {
			margin-bottom: rh(6);
		}
	}

	&-actions {
		display: grid;

		@include media(s) {
			grid-row-gap: rh(3);
		}

		@include media(m-up) {
			grid-column-gap: rh(3);
			grid-template-columns: 1fr 1fr;
		}
	}

	&-reject {
		@include g-button_2;
	}

	&-accept {
		@include g-button_1;

		@include media(s) {
			grid-row-start: 1;
		}
	}
}
