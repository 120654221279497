.b-add_to_bag {
	&-button {
		@include g-button_1;
	}

	&-hazardous_warning {
		@include g-error;

		margin-top: rh(2);
	}

	&.m-sticky {
		background: color(light);
		border-top: 1px solid color(lighter5);
		bottom: 0;
		left: 0;
		padding: rh(5 10);
		position: fixed;
		right: 0;
		z-index: z(pdp_sticky_buttons);
	}
}

.b-product_tile-action.m-plp .b-add_to_bag {
	margin: rh(0 1);
}
