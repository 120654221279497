.b-account_links {
	color: color(light);

	&-link {
		color: currentColor;
	}

	&-link + &-link {
		&::before {
			content: '/';
		}
	}
}
