/*md
# Underlined Tabs

Tabs with underlined controls

## Layout Example:
```html_example
<div class="g-tabs_underlined" data-component="global/Tabs">
	<div class="g-tabs_underlined-head">
		<ul class="g-tabs_underlined-list" data-js-tabs>
			<li class="g-tabs_underlined-control m-tabs-active" data-js-tab-item="1">
				<span class="g-tabs_underlined-control_name">Tab 1</span>
			</li>

			<li class="g-tabs_underlined-control" data-js-tab-item="2">
				<span class="g-tabs_underlined-control_name">Tab 2</span>
			</li>
		</ul>
	</div>

	<div class="g-tabs_underlined-content" data-js-tabs-content>
		<div class="g-tabs_underlined-item m-tabs-shown" data-js-tab-content="1">Tab 1 contents</div>

		<div class="g-tabs_underlined-item" data-js-tab-content="2">Tab 2 contents</div>
	</div>
</div>
```
*/

.g-tabs_underlined {
	@include g-tabs_underlined;

	&-head {
		@include g-tabs_underlined(_head);
	}

	&-list {
		@include g-tabs_underlined(_list);
	}

	&-control {
		@include g-tabs_underlined(_control);
	}

	&-control.m-tabs-active {
		@include g-tabs_underlined(_control_active);
	}

	&-control_name {
		@include g-tabs_underlined(_control_name);
	}

	&-item {
		@include g-tabs_underlined(_item);
	}

	&-item.m-tabs-shown {
		@include g-tabs_underlined(_item_shown);
	}
}
