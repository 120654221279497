.b-wishlist_link {
	&-inner {
		@include icon(heart) {
			font-size: 15px;
			vertical-align: bottom;

			@include media(m-down) {
				font-size: 18px;
			}
		}
	}

	&-inner.m-active {
		@include icon(heart-fill) {
			color: color(red2);
			opacity: 1;
			transition: opacity 0.2s linear;
		}

		&:hover::before {
			opacity: 0.8;
		}
	}

	&-text {
		display: none;
	}
}
