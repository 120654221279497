/*md
# Image tile banner

Image tile with a label

### HTML structure example:
```
<div class="g-image_tile">
	<a href="#" class="g-image_tile-inner">
		<img class="g-image_tile-image" src="//placehold.it/600x600" alt="" />
		<span class="g-image_tile-label">CATEGORY NAME AS LABEL</span>
	</a>
</div>
```

### SCSS structure example:
```
.g-image_tile {
	&-inner {
		@include g-image_tile(_inner);
	}

	&-image {
		@include g-image_tile(_image);
	}

	&-label {
		@include g-image_tile(_label);
	}
}
```
*/

@mixin g-image_tile($el: root) {
	@if $el == _image {
		object-fit: cover;
		width: 100%;
	}

	@if $el == root {
		display: block;
		position: relative;
	}

	@if $el == _label {
		@include font(medium, 16px);

		background-color: color(dark);
		bottom: rh(5);
		color: color(light);
		left: rh(5);
		padding: rh(2);
		position: absolute;
		text-transform: uppercase;
	}
}
