/*md
# Banner type 2

Image banner with text and CTA aside

## Example #1. Black text, centered content:

```html_example
<figure class="g-banner_2">
	<img class="g-banner_2-image" src="//placehold.it/900x500" alt="" />

	<figcaption class="g-banner_2-wrapper">
		<p class="g-banner_2-text">Your text</p>

		<a class="g-banner_2-cta" href="#">Shop now</a>
	</figcaption>
</figure>
```

## Example #2. Black text, top-left-aligned content:

```html_example
<figure class="g-banner_2 m-initial">
	<img class="g-banner_2-image" src="//placehold.it/900x500" alt="" />

	<figcaption class="g-banner_2-wrapper">
		<p class="g-banner_2-title">Your title</p>

		<p class="g-banner_2-text">Your text</p>

		<a class="g-banner_2-cta" href="#">Shop now</a>
	</figcaption>
</figure>
```

## Example #3. Image on the right-hand, black text, top-left-aligned content:

```html_example
<figure class="g-banner_2 m-initial m-reverse">
	<img class="g-banner_2-image" src="//placehold.it/900x500" alt="" />

	<figcaption class="g-banner_2-wrapper">
		<p class="g-banner_2-title">Your title</p>

		<p class="g-banner_2-text">Your text</p>

		<a class="g-banner_2-cta" href="#">Shop now</a>
	</figcaption>
</figure>
```
*/

.g-banner_2 {
	@include g-banner_2(root);

	&-image {
		@include g-banner_2(_image);
	}

	&-wrapper {
		@include g-banner_2(_wrapper);
	}

	&-title {
		@include g-banner_2(_title);
	}

	&-text {
		@include g-banner_2(_text);
	}

	&-cta {
		@include g-banner_2(_cta);
	}

	&.m-reverse {
		@include g-banner_2(root, reverse);
	}

	&.m-initial &-wrapper {
		@include g-banner_2(_wrapper, initial);
	}

	&.m-reverse &-wrapper {
		@include g-banner_2(_wrapper, reverse);
	}
}
