.b-overlay {
	background: color(dark);
	bottom: 0;
	left: 0;
	opacity: 0.5;
	position: fixed;
	right: 0;
	top: 0;
	z-index: -1;
}
