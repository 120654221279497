.b-swatch_colors {
	padding: rh(0 0 7.5);

	@include media(m-down) {
		padding: rh(0 0 5);
	}

	&-title {
		@include font(regular, 14px, 1);

		color: color(dark);
		margin-bottom: rh(3);
	}

	&-value {
		@include font(bold);
	}

	&-value.m-error {
		@include g-error;

		font-size: 14px;
	}

	&-name {
		@include font-smoothing;

		color: color(dark);
	}

	&-wrapper {
		display: flex;
	}

	&-item {
		@include g-swatch;
	}

	&-item.m-selected {
		@include g-swatch(root, selected);
	}

	&-item.m-disabled {
		@include g-swatch(root, disabled);
	}

	&-item.m-hide {
		display: none;
	}

	&-item.m-crossed {
		@include g-swatch(root, out_of_stock);
	}

	&-item.m-crossed.m-selected {
		@include g-swatch(root, selected_out_of_stock);
	}

	&-thumb {
		@include g-swatch(_thumb);
	}

	&-more {
		@include font(medium, 14px);

		color: color(lighter3);

		@include media(l-up) {
			margin-left: rh(2);
		}

		@include media(m-down) {
			line-height: 24px;
			text-align: center;
			width: 24px;
		}
	}

	&-more_count {
		@include media(m-down) {
			display: none;
		}
	}

	&-item + &-item {
		margin-left: rh(3);
	}
}
