.b-bonus_product {
	border-bottom: 1px solid color(lighter5);

	@include media(m-up) {
		display: grid;
		grid-template-areas: 'images info';
		grid-template-columns: 1fr 1fr;
	}

	&:last-child {
		border: none;
	}

	&-images {
		grid-area: images;
		overflow: hidden;
		padding: rh(10 20);

		@include media(m) {
			padding: rh(10 15);
		}

		@include media(s) {
			padding: rh(4);
		}
	}

	&-info {
		grid-area: info;
		padding: rh(10);

		@include media(m-up) {
			border-left: 1px solid color(lighter5);
		}

		@include media(s) {
			padding: rh(4);
		}
	}

	&-load_more {
		margin: 0 auto;
		max-width: 200px;
		padding: rh(5 0);
	}

	&-load_more_button {
		@include g-button_2;
	}

	&-variations {
		margin-bottom: rh(1);
	}

	&-hazardous_warning {
		@include g-error;

		margin-bottom: rh(3);
	}

	.b-product_name {
		margin-bottom: rh(10);
	}

	.b-product_brand {
		margin-bottom: rh(2);
	}
}
