.b-breadcrumbs {
	@include font(medium, 10px, 10px);

	color: color(dark);
	display: flex;
	flex-wrap: wrap;

	@include media(s) {
		font-size: 12px;
		line-height: 14px;
		padding-bottom: 20px;
	}

	&-item {
		@include media(s) {
			margin-top: 10px;
		}
	}

	&-item:not(:first-child) {
		@include icon(arrow-left, before, 6px);

		&::before {
			padding: 0 rh(1) 0 rh(2);

			@include media(s) {
				font-size: 8px;
			}
		}
	}

	&-link {
		position: relative;

		&::after {
			border-bottom: solid 1px color(dark);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			transform: scaleX(0);
			transition: transform 0.2s ease-in-out;
			width: 100%;
		}
	}

	&-link:hover {
		&::after {
			transform: scaleX(1);
		}
	}
}
