/*md
# Info Tabs

Tabs with gray-shaded controls

HTML structure example:
```
<div class="g-tabs_info"
	data-component="global/Tabs">

	<div class="g-tabs_info-head">
		<ul class="g-tabs_info-list" data-js-tabs>
			<li class="g-tabs_info-control" data-js-tab-item="1">
				<span class="g-tabs_info-control_name">Item 1 Title</span>
			</li>
			<li class="g-tabs_info-control" data-js-tab-item="2">
				<span class="g-tabs_info-control_name">Item 2 Title</span>
			</li>
			<li class="g-tabs_info-control" data-js-tab-item="3">
				<span class="g-tabs_info-control_name">Item 3 Title</span>
			</li>
			<li class="g-tabs_info-control" data-js-tab-item="4">
				<span class="g-tabs_info-control_name">Item 4 Title</span>
			</li>
		</ul>
	</div>

	<div class="g-tabs_info-content" data-js-tabs-content>
		<div class="g-tabs_info-item" data-js-tab-content="1">Item 1 Content</div>
		<div class="g-tabs_info-item" data-js-tab-content="2">Item 2 Content</div>
		<div class="g-tabs_info-item" data-js-tab-content="3">Item 3 Content</div>
		<div class="g-tabs_info-item" data-js-tab-content="4">Item 4 Content</div>
	</div>
</div>
```

SCSS structure example:
```
.g-tabs_info {
	@include g-tabs_info;

	&-head {
		@include g-tabs_info(_head);
	}

	&-list {
		@include g-tabs_info(_list);
		@include site-width;
	}

	&-control {
		@include g-tabs_info(_control);
	}

	&-control.m-tabs-active {
		@include g-tabs_info(_control_active);
	}

	&-control_name {
		@include g-tabs_info(_control_name);
	}

	&-item {
		@include g-tabs_info(_item);

		padding: rh(20 0);
	}

	&-item.m-tabs-shown {
		@include g-tabs_info(_item_shown);
	}

	&-content {
		@include site-width;
	}
}
```
*/

//Functional Tabs styles

@mixin g-tabs_info(
	$_: root
) {
	@if $_ == root {
		background-color: color(lighter6);
	}

	@if $_ == _head {
		background-color: color(lighter5);
	}

	@if $_ == _list {
		@include g-tabs(_list);
	}

	@if $_ == _control {
		@include g-tabs(_control);
		@include font(medium, 14px, 1.2);

		background-color: color(transparent);
		color: color(lighter2);
		text-transform: uppercase;

		@include media(m) {
			@include font(medium, 12px);
		}
	}

	@if $_ == _control_name {
		@include g-tabs(_control_name);

		align-self: center;
		padding: rh(6 2);
		text-align: center;
		width: 100%;
	}

	@if $_ == _control_active {
		@include g-tabs(_control_active);
		@include font(bold);

		background-color: color(lighter6);
		color: color(dark);
		transition: background-color 0.3s, color 0.3s linear;
	}

	@if $_ == _item {
		@include g-tabs(_item);
		@include font(regular, 14px);
	}

	@if $_ == _item_shown {
		@include g-tabs(_item_shown);
	}
}
