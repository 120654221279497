/*
	<div class="b-swatch">
		<span class="b-swatch-thumb">Value 1</span>
	</div>
*/

@mixin g-swatch(
	$_: root,
	$mod: default,
) {
	@if $_ == root {
		@if $mod == default {
			color: color(lighter5);
			cursor: pointer;
			display: block;
			outline: 1px solid currentColor;
			overflow: hidden;
			position: relative;

			&::after {
				border-top: 1px solid currentColor;
				left: -50%;
				position: absolute;
				top: 50%;
				transform: rotate(-45deg);
				width: 200%;
			}
		}

		@if $mod == selected {
			color: color(dark);
		}

		@if $mod == disabled {
			color: color(lighter3);

			&::after {
				content: '';
			}
		}

		@if $mod == out_of_stock {
			color: color(lighter5);

			&::after {
				content: '';
			}
		}

		@if $mod == selected_out_of_stock {
			color: color(dark);

			&::after {
				content: '';
			}
		}
	}

	@if $_ == _thumb {
		display: block;
		height: rh(10);
		pointer-events: none;
		width: rh(10);
	}
}
