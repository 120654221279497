/*md
@no-stat
# Masonry group

```html_example
<div
	class="b-masonry_group"
	data-component="global/Masonry"
	data-component-options='{
		"numberOfColumns": 3,
		"verticalRhythm": 20
	}'
>
	<div class="b-masonry_group-header">
		<div class="b-masonry_group-item">
			<div class="g-text_tile">
				<p class="g-text_tile-main">
					Experts in Beauty<br /> &amp; Makeup
				</p>

				<p class="g-text_tile-secondary">
					We stock one of the widest range of beauty products available and are constantly developing and evaluating new products.
				</p>

				<div class="g-text_tile-cta">
					<a class="g-text_tile-cta_link" href="#">Shop All</a>
				</div>
			</div>
		</div>
		<div class="b-masonry_group-item m-column_span_2">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/920x445" alt="" />
				<span class="g-image_tile-label">EYES</span>
			</a>
		</div>
	</div>
	<div
		class="b-masonry_group-content"
		data-js-masonry-content
	>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<figure class="g-blogpost_tile" data-js-masonry-item-content>
				<img class="g-blogpost_tile-image" alt="blogpost-hero" src="//placehold.it/250x150" />
				<figcaption class="g-blogpost_tile-content">
					<p class="g-blogpost_tile-label">Blog</p>
					<p class="g-blogpost_tile-title">Saturday Sparcles</p>
					<p class="g-blogpost_tile-info">
						Our eyes are often the first to shows signs of ageing, as the skin that surrounds them is fragile. A lack of naturel oils together with frequent...
					</p>
					<a href="#!" class="g-blogpost_tile-cta">Read more</a>
				</figcaption>
			</figure>
		</div>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<a href="#" class="g-image_tile" data-js-masonry-item-content>
				<img class="g-image_tile-image" src="//placehold.it/440x615" alt="" />
				<span class="g-image_tile-label">FACE</span>
			</a>
		</div>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<a href="#" class="g-image_tile" data-js-masonry-item-content>
				<img class="g-image_tile-image" src="//placehold.it/440x360" alt="" />
				<span class="g-image_tile-label">SKIN</span>
			</a>
		</div>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<figure class="g-blogpost_tile" data-js-masonry-item-content>
				<img class="g-blogpost_tile-image" alt="blogpost-hero" src="//placehold.it/250x150" />
				<figcaption class="g-blogpost_tile-content">
					<p class="g-blogpost_tile-label">Blog</p>
					<p class="g-blogpost_tile-title">Amazing Look</p>
					<p class="g-blogpost_tile-info">
						It is now well into winter and with temperatures plummeting (and hot tods a very necessary evil), you are doubtless suffering from a) a cold, b)...
					</p>
					<a href="#!" class="g-blogpost_tile-cta">Read more</a>
				</figcaption>
			</figure>
		</div>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<a href="#" class="g-image_tile" data-js-masonry-item-content>
				<img class="g-image_tile-image" src="//placehold.it/440x505" alt="" />
				<span class="g-image_tile-label">LIPS</span>
			</a>
		</div>
		<div class="b-masonry_group-item" data-js-masonry-item>
			<a href="#" class="g-image_tile" data-js-masonry-item-content>
				<img class="g-image_tile-image" src="//placehold.it/440x420" alt="" />
				<span class="g-image_tile-label">HAIR</span>
			</a>
		</div>
	</div>
</div>
```
*/

.b-masonry_group {
	--masonryNumberOfColumns: 0;
	--masonryVerticalRhythm: 0;

	&-header,
	&-content {
		display: grid;
		grid-template-columns: repeat(var(--masonryNumberOfColumns), 1fr);

		@include media(s) {
			display: block;
		}
	}

	&-header {
		margin: 0 calc(-1 * var(--grid-gutter)) calc(2 * var(--grid-gutter));
	}

	&-content &-item {
		margin: var(--grid-gutter);
	}

	&-header &-item {
		padding: 0 var(--grid-gutter);
	}

	&-header &-item + &-item {
		@include media(s) {
			margin-top: var(--grid-gutter);
		}
	}

	&-header .g-image_tile,
	&-header .g-image_tile-image {
		height: 100%;
	}

	&-item.m-column_span_2 {
		grid-column-end: span 2;
	}

	&-content {
		grid-auto-rows: var(--masonryVerticalRhythm);
		margin: calc(-1 * var(--grid-gutter));
		visibility: hidden;
	}

	&-content.m-fit_masonry_elements {
		.g-image_tile,
		.g-text_tile {
			display: flex;
			height: 100%;
		}
	}
}
