/*md
# Badges

HTML structure example:

## PDP

```html_example
<style>
	.atlas-playground-test-frame > section {display: flex; align-items: center;}
	.atlas-playground-test-frame > section > div + div {margin-left: 25px;}
</style>
<section>
	<div class="b-badge_pdp m-sale">
		<span>SEASON</span><span>Sale</span>
	</div>
	<div class="b-badge_pdp m-bonus">
		<span>bonus</span>
	</div>
	<div class="b-badge_pdp m-new">
		<span>New</span>
	</div>
</section>
```

## PLP

```html_example
<style>
	.atlas-playground-test-frame > section {display: flex; align-items: center;}
	.atlas-playground-test-frame > section > div + div {margin-left: 25px;}
</style>
<section>
	<div class="b-badge_plp m-sale">
		<span>SEASON</span><span>Sale</span>
	</div>
	<div class="b-badge_plp m-bonus">
		<span>bonus</span>
	</div>
	<div class="b-badge_plp m-new">
		<span>New</span>
	</div>
</section>
```

## Cart

```html_example
<style>
	.atlas-playground-test-frame > section {display: flex; align-items: center;}
	.atlas-playground-test-frame > section > div + div {margin-left: 25px;}
</style>
<section>
	<div class="b-badge_cart m-sale">
		<span>SEASON</span><span>Sale</span>
	</div>
	<div class="b-badge_cart m-bonus">
		<span>bonus</span>
	</div>
	<div class="b-badge_cart m-new">
		<span>New</span>
	</div>
</section>
```
*/

.b-badge {
	@include g-badge;
}

.b-badge_pdp {
	&.m-sale {
		@include g-badge($bg: red);
	}

	&.m-bonus {
		@include g-badge;
	}

	&.m-new {
		@include g-badge;
	}
}

.b-badge_plp {
	&.m-sale {
		@include g-badge($bg: red, $type: plp);
	}

	&.m-bonus {
		@include g-badge($type: plp);
	}

	&.m-new {
		@include g-badge($type: plp);
	}
}

.b-badge_cart {
	&.m-sale {
		@include g-badge($bg: red, $type: cart);
	}

	&.m-bonus {
		@include g-badge($type: cart);
	}

	&.m-new {
		@include g-badge($type: cart);
	}
}
