/*md
# Banner type 3

Image banner with bold main text and tiny secondary info text

## Example #1. White text, centered content:

```html_example
<figure class="g-banner_3">
	<img class="g-banner_3-image" src="//placehold.it/900x100" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## Example #2. White text, left-aligned content content:

```html_example
<figure class="g-banner_3 m-left">
	<img class="g-banner_3-image" src="//placehold.it/900x300" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## Example #3. White text, bottom-aligned content content:

```html_example
<figure class="g-banner_3 m-bottom">
	<img class="g-banner_3-image" src="//placehold.it/900x300" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## Example #4. Square image; Black text, right-aligned content content:

```html_example
<figure class="g-banner_3 m-right m-black">
	<img class="g-banner_3-image" src="//placehold.it/900x300" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## Example #5. Rectangle image; Black text, separated content, Animate On Scroll:

Animations
- Check which animations you need with visual guide: https://michalsnik.github.io/aos/.
- Settings:

- offset: 120, // offset (in px) from the original trigger point
- delay: 0, // values from 0 to 3000, with step 50ms
- duration: 400, // values from 0 to 3000, with step 50ms
- easing: 'ease', // default easing for AOS animations -> https://github.com/michalsnik/aos
- once: false, // whether animation should happen only once - while scrolling down
- mirror: false, // whether elements should animate out while scrolling past them
- anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should
- trigger the animation -> https://github.com/michalsnik/aos

```html_example
<figure class="g-banner_3 m-separated m-black" data-component="global/AnimateOnScroll" data-aos-easing="linear" data-aos-once="false" data-aos="zoom-in" data-aos-duration="1500" data-component-context="medium">
	<img class="g-banner_3-image" src="//placehold.it/900x100" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>

		<div class="g-banner_3-cta">
            <a class="g-banner_3-cta_link" href="#">Shop now</a>
        </div>
	</figcaption>
</figure>
```

## Example #6. White text, centered content, image as link, Animate On Scroll:

```html_example
<figure class="g-banner_3" data-aos="fade-right" data-aos-offset="100" data-aos-easing="ease-in-sine">
	<a href="#" class="g-banner_3-image_link">
		<img class="g-banner_3-image" src="//placehold.it/900x100" alt="" />
	</a>
	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## Example #7. Two column design:

```html_example

<div class="b-section h-site_width">
    <a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center">
        <div class="b-section-column">
            <figure class="g-banner_3 m-separated m-black ">
				<img class="g-banner_3-image" src="//placehold.it/900x900" alt="image" />
				<figcaption class="g-banner_3-wrapper ">
					<img class="g-banner_3-svg_logo" style="margin-bottom: 20px" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_skincare_svg.svg?$staticlink$" alt="logo"/>
					<p class="g-banner_3-main" style="font-variant: small-caps;">
						new year, new you
					</p>
					<p class="g-banner_3-secondary" style="margin-bottom: 30px" >

						Refresh your makeup bag for 2019

					</p>

					<span class="b-button_1 m-centered">Shop Now</span>
				</figcaption>
            </figure>
        </div>

        <div class="b-section-column">
            <figure class="g-banner_3 m-separated m-black ">
                <img class="g-banner_3-image_zooming" src="//placehold.it/900x900" alt="image" />
                <figcaption class="g-banner_3-wrapper">

                    <img class="g-banner_3-svg_logo"style="margin-bottom: 20px" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

                    <p class="g-banner_3-main" style="font-variant: small-caps;">
                    	new year, new you
                    </p>

                    <p class="g-banner_3-secondary" style="margin-bottom: 30px" >
                        Refresh your makeup bag for 2019
                    </p>

					<span class="b-button_1 m-centered">Shop Now</span>

                </figcaption>
            </figure>
        </div>
    </a>
</div>
```
## Example #8. Two column design from homepage slot 5:

```html_example

<div class="b-section h-site_width">
    <a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center  m-2column" style="align-items: flex-start;">
        <div class="b-section-column">
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
		<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_hp_1_1_skin.jpg?$staticlink$" alt="image" />
		<figcaption class="g-banner_3-wrapper ">
			<div class="g-banner_3-logowrapper">
				<img class="g-banner_3-svg_logo" style="margin-top: 20px; width: 70%" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_skincare_svg.svg?$staticlink$" alt="logo"/>
			</div>
			<p class="g-banner_3-main" style="font-variant: small-caps;">
				skin, meet bakuchol
			</p>
			<p class="g-banner_3-secondary" style="margin-bottom: 30px" >
				Everyones talking about this plant-based alternative to Retinol. A dream for sensitive skin.
			</p>
			<span class="b-button_1 m-centered">SHOP LATEST</span>
		</figcaption>
            </figure>
        </div>

        <div class="b-section-column" >
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
                <img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_hp_1_1_pro.jpg?$staticlink$" alt="image" />
                <figcaption class="g-banner_3-wrapper">
		    <div class="g-banner_3-logowrapper">
				<img class="g-banner_3-svg_logo"style="margin-top: 20px; width: 100%" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_Pro_logo_gold.svg?$staticlink$" alt="logo"/>
			</div>
                    <p class="g-banner_3-main" style="font-variant: small-caps;">
                    	makeup that does more
                    </p>
                    <p class="g-banner_3-secondary" style="margin-bottom: 30px" >
                        Our new Revolution PRO collection works as hard as you do.
                    </p>
			<span class="b-button_1 m-centered">EXPLORE PRO</span>
                </figcaption>
            </figure>
        </div>
    </a>
</div>
```

## Example #9. Multi Column with various components:

```html_example

<div class="g-text_tile m-centered" style="margin-bottom: 50px;">
    <p class="g-text_tile-main">
        <span class="g-text_tile-text_block">OUR REVOLUTION FAMILY</span>
    </p>
    <p class="g-text_tile-secondary" style="margin-bottom: 20px;">
        <span class="g-text_tile-text_block">Secondary text block 2</span>
    </p>
    <span class="b-button_1 m-centered" >shop all</span>
</div>

<div class="g-banner_ourfamily-background_texture" style="background-image: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/XXX.jpg?$staticlink$);">
	<div class="b-section h-site_width m-homepage_big_gap">
		<a href="#!" class="b-section-row m-gaps m-x-center m-y-center">
			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black ">
					<div class="g-banner_3-image ">
						<img class="g-banner_3-image_zooming " src="//placehold.it/900x900" alt="Banner copy" />
					</div>
					<figcaption class="g-banner_3-wrapper ">
						<span class="b-button_1 m-centered m-small">Shop Eyes</span>
					</figcaption>
				</figure>
			</div>
			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black ">
					<img class="g-banner_3-image_zooming" src="//placehold.it/900x900" alt="Banner copy" />
					<figcaption class="g-banner_3-wrapper">
						<span class="b-button_1 m-centered m-small">Shop Lips</span>
					</figcaption>
				</figure>
			</div>
		</a>
	</div>

	<div class="b-section h-site_width">
		<div class="b-section-row m-x-stretched m-4row m-gaps">
			<a class="b-section-column" href="#!">
				<figure class="g-banner_3 m-separated m-black  m-4column">
					<img class="g-banner_3-image" src="//placehold.it/900x900" alt="Banner copy" />
					<figcaption class="g-banner_3-wrapper">
					<span class="b-button_1 m-centered m-small">shop face</span>
					</figcaption>
				</figure>
			</a>

			<a class="b-section-column" href="#!">
				<figure class="g-banner_3 m-separated m-black   m-4column">
					<img class="g-banner_3-image" src="//placehold.it/900x900" alt="Banner copy" />
					<figcaption class="g-banner_3-wrapper">
					<span class="b-button_1 m-centered m-small">shop accessories</span>
					</figcaption>
				</figure>
			</a>

			<a class="b-section-column" href="#!">
				<figure class="g-banner_3 m-separated m-black   m-4column">
					<img class="g-banner_3-image" src="//placehold.it/900x900" alt="Banner copy" />
					<figcaption class="g-banner_3-wrapper">
					<span class="b-button_1 m-centered m-small">shop hair</span>
					</figcaption>
				</figure>
			</a>

			<a class="b-section-column" href="#!">
			<figure class="g-banner_3 m-separated m-black   m-4column">
					<img class="g-banner_3-image" src="//placehold.it/900x900" alt="Banner copy" />
					<figcaption class="g-banner_3-wrapper">
					<span class="b-button_1 m-centered m-small">shop bath</span>
					</figcaption>
				</figure>
			</a>
		</div>
	</div>
</div>
```

## Example #10. Simple SVG Banner:

```html_example
<figure class="g-banner_3">
	<picture class="g-banner_3-svg">
		<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/desktop_values.svg?$staticlink$">
		<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/mobile_values.svg?$staticlink$">
		<img class="g-banner_3-svg" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/desktop_values.svg?$staticlink$" alt="svg logo">
	</picture>
</figure>
```

## Example #11. Obsession various assets:

```html_example
<figure class="b-hero_sideinfo m-roxy m-content_left m-obsession m-druk">
	<div class="b-hero_sideinfo-imagewrapper" style="background-color:#FFD543" >
		<img class="b-hero_sideinfo-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_4_3_obsession_pride.jpg?$staticlink$" alt="background image">
	</div>

    <figcaption class="b-hero_sideinfo-wrapper " style="background-color:#FFD543; ">
        <p class="b-hero_sideinfo-main" style="font-variant: small-caps; ">
                we are proud
            </p>
            <p class="b-hero_sideinfo-secondary" style="margin-bottom: 30px;" >
                We’re giving £25,000 to the Dignity Trust from the sales of our Pride palette
            </p>
            <span class="b-button_1 m-centered">SHOP NOW</span>
    </figcaption>
</figure>

<div class="g-text_tile m-centered m-smallcaps m-druk" style="margin: 40px 0 50px;">
    <p class="g-text_tile-main" style="margin: 20px 0; ">
        <span class="g-text_tile-text_block">who are we <span class="g-text_tile-question">?<span></span>
    </p>
</div>

<figure class="b-vimeo m-multi_bg m-4725" style="--urlbg: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/makeup-obsession/2019/wk30/bgobsession2.svg?$staticlink$);">
    <div class="b-vimeo-video_desktop" style="margin-bottom: 70px;">
       <iframe src="https://player.vimeo.com/video/343457878?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
	</div>
	<div class="b-vimeo-video_mobile" style="margin-bottom: 70px;">
       <iframe src="https://player.vimeo.com/video/343457866?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>

     <p class="b-vimeo-beyond_text">You are the Revolution</p>
      <p class="b-vimeo-beyond_smalltext" style=" margin-bottom: 60px;">Have fun and show us  how to wear it @iheartrevolution</p>

<div style="width: 100%;">
	<div id="olapic_specific_widget"></div><script type="text/javascript" src="https://photorankstatics-a.akamaihd.net/743d2e78a76dedeb07e0745158547931/static/frontend/latest/build.min.js"  data-olapic="olapic_specific_widget" data-instance="4f4d11b7e0b19ecddf45d2b93c52d9c1" data-apikey="3d1e19985e948755bf7ca124a4be581c17903842b369dfafb80c2d2871b671b3" async="async"></script>
</div>

</figure>
```

## Example #12. Skincare BLP page banners.

```html_example
<div class="g-text_tile m-centered" style="margin-bottom: 70px;">
    <p class="g-text_tile-main" style="font-variant: sweet-sans; margin-bottom: 20px;">
        <span class="g-text_tile-text_block">Shop our skincare saviours</span>
    </p>

    <p class="g-text_tile-secondary">
        <span class="g-text_tile-text_block">Show us your results @revolutionskincare</span>
    </p>
</div>

<div class="b-section h-site_width">
    <a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center  m-2column m-40pc_column" style="align-items: flex-start;">
        <div class="b-section-column">
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
				<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_oils-serums.gif?$staticlink$" alt="image" />
				<figcaption class="g-banner_3-wrapper ">
					<p class="g-banner_3-main" style="font-variant: sweet-sans;">
						Serum & oils
					</p>
					<p class="g-banner_3-secondary" style="margin-bottom: 30px" >
						Targeted solutions that address real skin woes
					</p>
					<button class="b-button_4">SHOP LATEST</button>
				</figcaption>
            </figure>
        </div>

        <div class="b-section-column" >
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
                <figure class="b-vimeo" style="--urlbg: url(//placehold.it/1920x1080)">
					<div class="b-vimeo-video" style="padding-top: 100.25%;">
						<iframe src="https://player.vimeo.com/video/343681733?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
					</div>
				</figure>
                <figcaption class="g-banner_3-wrapper">
                    <p class="g-banner_3-main" style="font-variant: sweet-sans;">
                    	Skin stressing?
                    </p>
                    <p class="g-banner_3-secondary" style="margin-bottom: 30px" >
                        Meet our new masks to calm, nourish and soothe
                    </p>
					<button class="b-button_4">SHOP MASKS</button>
                </figcaption>
            </figure>
        </div>
    </a>
</div>

<figure class="g-banner_ourfamily m-xflipped m-smaller_image">
    <img class="g-banner_ourfamily-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/essence-sprays.jpg?$staticlink$" alt="" />

    <figcaption class="g-banner_ourfamily-wrapper">
        <p class="g-banner_ourfamily-title" style="font-variant:initial;">Thirsty skin?</p>

        <p class="g-banner_ourfamily-small_text" style="margin-bottom: 50px;">Let your skin drink up our new Essence sprays</p>

        <span class="b-button_1 m-centered">Button type 1</span>
    </figcaption>
</figure>

<div class="b-section h-site_width">
    <a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-gaps m-x-center m-y-center  m-2column m-40pc_column" style="align-items: flex-start;">
        <div class="b-section-column">
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
				<figure class="b-vimeo" style="--urlbg: url(//placehold.it/1920x1080)">
					<div class="b-vimeo-video" style="padding-top: 100.25%;">
						<iframe src="https://player.vimeo.com/video/343681636?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
					</div>
				</figure>
				<figcaption class="g-banner_3-wrapper ">
					<p class="g-banner_3-main" style="font-variant: sweet-sans;">
						Holiday heroes
					</p>
					<p class="g-banner_3-secondary" style="margin-bottom: 30px" >
						There is no reason to let your skincare slip on holiday. Meet your holiday hotlist
					</p>
					<button class="b-button_4">SHOP LATEST</button>
				</figcaption>
            </figure>
        </div>

        <div class="b-section-column" >
            <figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column" style="overflow:hidden">
                <img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_hp_1_1_skin.jpg?$staticlink$" alt="image" />
                <figcaption class="g-banner_3-wrapper">
                    <p class="g-banner_3-main" style="font-variant: sweet-sans;">
                    	Skin, meet Bakuchiol
                    </p>
                    <p class="g-banner_3-secondary" style="margin-bottom: 30px" >
                        Everyones talking about this plant-based alternative to Retinol. A dream for sensitive skin
                    </p>
					<button class="b-button_4">SHOP MASKS</button>
                </figcaption>
            </figure>
        </div>
    </a>
</div>

<figure class="b-vimeo m-full_bg m-no_bg">
    <div class="b-vimeo-video_desktop" style="padding-top: 47.25%; margin-bottom:10px;">
       <iframe src="https://player.vimeo.com/video/342977721?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
	</div>
	<div class="b-vimeo-video_mobile" style="margin-bottom:20px;">
       <iframe src="https://player.vimeo.com/video/343457901?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</figure>

<div class="g-text_tile m-centered">
    <p class="g-text_tile-main">
        <span class="g-text_tile-text_block" style="font-size:28px">Made in Britain</span>
    </p>
    <p class="g-text_tile-secondary">
        <span class="g-text_tile-text_block">Go behind the scenes at the factory <br> where our skincare is made</span>
    </p>
</div>
```

## Example #13. Various obsession banners.

```html_example
<div class="g-text_tile m-centered m-smallcaps m-druk" style="margin: 40px 0 50px;">
    <p class="g-text_tile-main" style="margin: 20px 0; ">
        <span class="g-text_tile-text_block">latest obsessions</span>
    </p>
</div>

<div class="b-section-bgwrapper" style="--urlbg: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/makeup-obsession/2019/wk30/bgobsession1.svg?$staticlink$);">
	<div class="m-svgbg">
		<a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-percent_gaps m-x-center m-y-center  m-2column" style="align-items: flex-start;">
			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column m-druk" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_1_1_obsession_eyes.gif?$staticlink$" alt="image" />
					<figcaption class="g-banner_3-wrapper ">
						<p class="g-banner_3-main">
							WINK, WINK
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px;">
							Open your eyes to our bold bright and pretty pastel eyeshadow palettes
						</p>
						<span class="b-button_1 m-centered m-small" style="margin-bottom: 20px">SHOP NOW</span>
					</figcaption>
				</figure>
			</div>

			<div class="b-section-column" >
				<figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column m-druk" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_hp_brand_obsession_1_1_SQUARE.jpg?$staticlink$" alt="image" />
					<figcaption class="g-banner_3-wrapper">
						<p class="g-banner_3-main" style="font-variant: druk-web;">
							HIGHLIGHT GOALS
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px;" >
							Glow up with our mega-watt liquid highlighters
						</p>
						<span class="b-button_1 m-centered m-small" style="margin-bottom: 20px">SHOP NOW</span>
					</figcaption>
				</figure>
			</div>
		</a>
	</div>

	<div class="h-site_width m-svgbg">
		<a href="$url('Search-Show', 'cgid', 'lips', 'srule', 'new-descending')$" class="b-section-row m-percent_gaps m-x-center m-y-center  m-2column" style="align-items: flex-start;">
			<div class="b-section-column">
				<figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column m-druk" style="overflow:hidden">
					<img class="g-banner_3-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/wk1_4_5_obsession_mvp.jpg?$staticlink$" alt="image" />
					<figcaption class="g-banner_3-wrapper ">
						<p class="g-banner_3-main" style="font-variant: druk-web;">
							MEET THE MVP
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px;" >
							Our Most Valuable Pencil to create the ultimate brows
						</p>
						<span class="b-button_1 m-centered m-small" style="margin-bottom: 20px">SHOP NOW</span>
					</figcaption>
				</figure>
			</div>

			<div class="b-section-column" >
				<figure class="g-banner_3 m-separated m-black m-image_zoom m-fullwidth m-2column m-druk" style="overflow:hidden">
					<div class="g-banner_3-video">
						<figure class="b-vimeo m-5x4">
							<div class="b-vimeo-video">
							<iframe src="https://player.vimeo.com/video/343457901?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
							</div>
						</figure>
					<div>
					<figcaption class="g-banner_3-wrapper">
						<p class="g-banner_3-main" style="font-variant: druk-web;">
							ACE YOUR BASE
						</p>
						<p class="g-banner_3-secondary" style="margin-bottom: 20px;" >
							Prime, conceal and fix to lock in your look
						</p>
						<span class="b-button_1  m-centered m-small" style="margin-bottom: 20px">SHOP NOW</span>
					</figcaption>
				</figure>
			</div>
		</a>
	</div>
</div>
```
*/

.g-banner_3 {
	@include g-banner_3;

	&-image {
		@include g-banner_3(_image);

		transition: 0.7s;
	}

	&-image_link {
		@include g-banner_3(_image_link);
	}

	&-image_link &-image {
		@include g-banner_3(_image_link_pic);
	}

	&-cta_link {
		@include g-banner_3(_cta_link);
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_3(_wrapper);
	}

	&.m-top_padding &-image {
		@include media(m-up) {
			margin: 0 auto;
			max-height: 530px;
			max-width: 400px;
		}
	}

	&.m-top_padding_square &-image {
		@include media(m-up) {
			margin: 0 auto;
			max-height: 400px;
			max-width: 400px;
		}
	}

	&-logowrapper {
		margin-bottom: rh(3);
		margin-left: auto;
		margin-right: auto;
		min-height: 70px;
		width: 80%;

		@include media(s) {
			margin-bottom: rh(6);
			min-height: initial;
			width: 270px;
		}
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&.m-left &-wrapper {
		@include g-banner_3(_align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_3(_align_right);
	}

	&.m-black &-wrapper {
		@include g-banner_3(_text_black);
	}

	&.m-bottom &-wrapper {
		@include g-banner_3(_align_bottom_wrapper);
	}

	&.m-separated {
		@include g-banner_3(_separated);
	}

	&.m-separated &-wrapper {
		@include g-banner_3(_separated_wrapper);
	}

	&-svg_logo {
		width: 70%;

		@include media(s) {
			width: 200px;
		}
	}

	&-secondary {
		@include g-banner_3(_secondary);
	}

	&.m-separated &-secondary {
		@include media(s) {
			margin-left: auto;
			margin-right: auto;
			max-width: 305px;
		}
	}

	&.m-fullwidth &-wrapper {
		@include media(s) {
			width: 100%;
		}
	}

	&-main {
		@include g-banner_3(_main);
	}

	&-text_block {
		@include g-banner_3(_text_block);
	}

	&-cta {
		@include g-banner_3(_cta);
	}

	&.m-black &-cta_link {
		@include g-banner_3(_link_black);
	}

	&.m-sticky_text &-wrapper {
		@include g-banner_3(_sticky_text);
	}

	&.m-image_zoom &-image {
		&:hover {
			transform: scale(1.04) translateY(-2.4%);
			transition: 0.7s;
		}
	}

	&.m-70p_mobile &-wrapper {
		@include media(s) {
			margin-bottom: rh(18);
		}
	}

	&.m-4column &-wrapper {
		padding: rh(7 0);
	}

	&.m-top_padding {
		@include media(m-up) {
			padding-top: rh(18);
		}
	}

	&.m-top_padding_square {
		@include media(m-up) {
			padding-top: rh(38);
		}
	}

	&.m-4column &-main {
		@include media(m) {
			font-size: 20px;
		}
	}

	&.m-4column &-secondary {
		@include media(m) {
			font-size: 14px;
		}

		@include media(m-up) {
			min-height: 70px;
		}
	}

	&-svg {
		padding: rh(0 8);

		@include media(m-up) {
			padding: rh(0 18);
		}

		@include media(l-up) {
			padding: rh(0 40);
		}
	}

	&.m-24mobile &-svg {
		@include media(s) {
			margin: rh(6 0);
		}
	}

	&-image_upside {
		&:hover {
			transform: scale(1.04) translateY(+2.4%);
			transition: 0.7s;
		}
	}

	&.m-2column &-secondary {
		margin-left: auto;
		margin-right: auto;
		max-width: 305px;

		@include media(m-up) {
			margin-bottom: rh(5);
		}
	}

	&.m-flip_y {
		@include media(m-up) {
			flex-direction: column-reverse;
		}
	}

	&.m-druk &-main {
		@include font(druk, 30px, 1.3);

		letter-spacing: 1px;
	}

	&.m-20mobile &-svg {
		@include media(s) {
			margin-top: 20px;
		}
	}

	&.m-wide_logo &-svg_logo {
		width: 85%;

		@include media(s) {
			width: 290px;
		}
	}
}
