@mixin f-input_tel {
	.f-input_tel {
		&-field {
			@include f-input_field;
		}

		&-pswd {
			@include font(password);
		}

		&.m-error &-field {
			@include f-input_field(error);
		}

		&.m-disabled &-field {
			@include f-input_field(disabled);
		}

		&.m-prefilled &-field {
			@include f-input_field(prefilled);
		}

		&-label {
			@include f-input_label;
		}

		// &.m-required &-label {
		// 	@include f-input_label($mod: required);
		// }
		&-optional_copy {
			@include f-input_label(_optional_copy);
		}

		&-label_description {
			@include f-input_label(_label_description);
		}

		&-label_link {
			@include f-input_label(_label_link);
		}
	}
}
