.b-miniproduct {
	display: grid;
	grid-column-gap: rh(5);
	grid-template: auto 1fr / auto 1fr;
	grid-template-areas:
		'image info'
		'image subtotal';

	@include media(s) {
		grid-column-gap: rh(4);
	}

	&-image {
		align-self: center;
		grid-area: image;
		width: 100px;

		@include media(s) {
			width: 70px;
		}
	}

	&-product_image {
		max-width: 100%;
	}

	&-info {
		grid-area: info;
	}

	&-subtotal {
		align-items: flex-end;
		display: flex;
		grid-area: subtotal;
		justify-content: space-between;
	}

	&-title {
		display: flex;
		margin-bottom: rh(4);
	}

	&-name {
		@include font(bold, 16px);

		max-width: 240px;

		@include media(s) {
			font-size: 14px;
			max-width: none;
			padding-right: rh(2);
		}
	}

	&-remove_item {
		margin-left: auto;
	}

	&-remove_item_control {
		@include g-button_control;

		@include icon(close) {
			font-size: 13px;
		}
	}

	&-qty,
	&-attribute_list {
		@include font(regular, 14px);

		@include media(s) {
			font-size: 12px;
		}
	}

	&-qty {
		line-height: 1.3;
	}

	&-total_price {
		.b-product_price {
			@include font(bold, 16px);
		}
	}

	&.m-bonus &-total_price {
		text-transform: uppercase;
	}

	&-price {
		margin-bottom: rh(5);

		.b-product_price {
			@include media(s) {
				font-size: 16px;
			}
		}

		.b-product_price-sales.m-discount {
			> .b-product_price-value {
				@include font(bold, 16px);
			}
		}
	}

	&-price + &-price {
		margin-top: rh(3);
	}

	&-availability {
		margin-bottom: rh(5);
	}

	&-attribute_item {
		line-height: 16px;
		margin-bottom: rh(3);

		@include media(s) {
			margin-bottom: rh(1.5);
		}
	}

	&-attribute_value,
	&-qty_value {
		@include font(bold, 14px);
	}
}
