/* General styles for the whole site */

/*
	stylelint-disable
	selector-max-universal,
	selector-no-qualifying-type,
	declaration-no-important
*/

*,
*::before,
*::after {
	box-sizing: border-box;
}

::selection {
	background: color(dark);
	color: color(light);
}

input[type='search'] {
	appearance: none;
	box-sizing: border-box;
}

button::-moz-focus-inner {
	border: 0 !important;
	padding: 0 !important;
}

/* GENERAL */
html,
body {
	box-sizing: border-box;
	margin: 0;
	overflow-x: hidden;
	padding: 0;
	width: 100%;
}

html {
	height: 100%;
}

body {
	@include font(regular, 16px);

	font-feature-settings: 'liga', 'kern';
}

img {
	max-width: 100%;
	vertical-align: bottom;
}

picture {
	img {
		width: 100%;
	}
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

.cc-revoke {
	z-index: 2 !important;
}
