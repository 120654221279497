.b-quick_search {
	@include site-width;

	align-items: center;
	display: flex;

	&-body {
		flex-grow: 1;
		position: relative;
	}

	&-form {
		position: relative;
	}

	&-btn {
		@include g-button_control;

		position: absolute;
		right: 0;
		top: 50%;
		transform: translate(0, -50%);

		@include icon(search) {
			font-size: 18px;
		}
	}

	&-close {
		cursor: pointer;
		display: none;
		flex-shrink: 0;
		margin-left: rh(6);

		@include icon(close) {
			font-size: 18px;
		}

		@include media(l-up) {
			display: block;
		}
	}

	&-input {
		width: 100%;
	}

	&-suggestions_wrapper {
		background-color: color(light);
		border-bottom: 1px solid color(dark);
		left: 0;
		position: absolute;
		top: 100%;
		width: 100%;

		&:empty {
			display: none;
		}
	}

	&-clear {
		@include font(regular, 12px);

		color: color(lighter2);
		cursor: pointer;
		position: absolute;
		right: rh(10);
		text-transform: uppercase;
		top: 50%;
		transform: translate(0, -50%);
	}

	.f-input_text-field {
		padding: 0;
		text-align: left;

		&:hover,
		&:focus {
			border-color: color(lighter3);
		}
	}
}
