/*md
# Banner type 5

Image banner with note below.

Used in BLOG

## HTML structure example:
```
<figure class="g-banner_5">
	<picture class="g-banner_5-image_wrapper">
		<source media="(min-width:768px)" srcset="//placehold.it/800x400 2x">
		<img class="g-banner_5-image" src="//placehold.it/800x400" alt="">
	</picture>
	<figcaption class="g-banner_5-note">I HEART REVOLUTION, Fall/Winter 2019</figcaption>
</figure>
```

## SCSS usage example:
```
.g-banner_5 {
	@include g-banner_5;

	&-image_wrapper {
		@include g-banner_5(_image_wrapper);
	}

	&-image {
		@include g-banner_5(_image);
	}

	&-note {
		@include g-banner_5(_note);
	}
}
```
*/

@mixin g-banner_5($el: root, $mod: default) {
	@if $el == root {
		@if $mod == default {
			display: inline-block;
		}
	}

	@if $el == _image_wrapper {
		@if $mod == default {
			margin-bottom: rh(2);
		}
	}

	@if $el == _note {
		@if $mod == default {
			@include font(medium, 10px);

			display: block;
			text-align: left;
		}
	}
}
