/*md
# Brand Banner

Banner with title and text content on one hand and media area on the other

## Example #1. Regular one with top gutter image used as a title:

```html_example
<div class="b-clp_brand_banner">
    <div class="b-clp_brand_banner-section">
        <div class="b-clp_brand_banner-wrapper">
            <div class="b-clp_brand_banner-media">
                <img src="//placehold.it/900x500" alt="">
            </div>
            <div class="b-clp_brand_banner-title">
                <img src="//placehold.it/200x40" alt="">
            </div>
            <div class="b-clp_brand_banner-text">
                <p>Our eyes are the first to tell tales of our late night endeavours, poor diet and stress! Luckily, the right eye make up can disguise a multitude of sins so invest in a flesh-toned pencil, such as Charlotte Tilbury's Rock N' Kohl Eye Cheat to brighten the white; a highlighter dotted beside inner corners to widen and youthify; a black {eye liner} to help instantly fake fuller lashes and a volumising mascara, to lengthen thicken and create the best frame for your peepers.</p>
                <a class="b-clp_brand_banner-cta" href="#">Shop Revolution</a>
            </div>
        </div>
    </div>
</div>
```

## Example #2. Reversed one with text title:

```html_example
<div class="b-clp_brand_banner m-reverse">
    <div class="b-clp_brand_banner-section">
        <div class="b-clp_brand_banner-wrapper">
            <div class="b-clp_brand_banner-media">
                <img src="//placehold.it/900x500" alt="">
            </div>
            <div class="b-clp_brand_banner-title">
                The Brand
            </div>
            <div class="b-clp_brand_banner-text">
                <p>Our eyes are the first to tell tales of our late night endeavours, poor diet and stress! Luckily, the right eye make up can disguise a multitude of sins so invest in a flesh-toned pencil, such as Charlotte Tilbury's Rock N' Kohl Eye Cheat to brighten the white; a highlighter dotted beside inner corners to widen and youthify; a black {eye liner} to help instantly fake fuller lashes and a volumising mascara, to lengthen thicken and create the best frame for your peepers.</p>
                <a class="b-clp_brand_banner-cta" href="#">Shop the Brand</a>
            </div>
        </div>
    </div>
</div>
```
*/

.b-clp_brand_banner {
	@include g-brand_banner(root);

	&-section {
		@include site-width;

		margin-bottom: rh(20);

		@include media(s) {
			margin-bottom: rh(12);
		}
	}

	&-wrapper {
		@include g-brand_banner(_wrapper);
	}

	&.m-reverse &-wrapper {
		@include g-brand_banner(_wrapper, reverse);
	}

	&-title {
		@include g-brand_banner(_title);

		margin-bottom: rh(7);

		@include media(l-up) {
			margin-top: rh(12);
		}

		@include media(m-up) {
			margin-right: rh(15);
		}

		@include media(s) {
			margin-bottom: rh(5);
		}
	}

	&.m-reverse &-title {
		@include media(m-up) {
			margin-left: rh(15);
			margin-right: 0;
		}
	}

	&-media {
		@include g-brand_banner(_media);
	}

	&-text {
		@include g-brand_banner(_text);

		@include media(m-up) {
			margin-right: rh(15);
			padding-bottom: rh(7);
		}

		@include media(s) {
			padding-top: rh(5);
		}
	}

	&.m-reverse &-text {
		@include media(m-up) {
			margin-left: rh(15);
			margin-right: 0;
		}
	}

	&-cta {
		@include g-brand_banner(_cta);

		margin-top: rh(5);
	}
}
