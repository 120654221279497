/*md
# Hero type centered

Hero with centered items which go below on mobile.

## Example #1. Content centered to bottom, white sub text turns black and goes below on small screen, white button:

```html_example
<figure class="b-hero_centered m-separated_mobile m-bottom_content">
	<picture  class="b-hero_centered-image">
		<source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
		<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
		<img src="//placehold.it/1920x1080" alt="background image">
	</picture>
    <figcaption class="b-hero_centered-wrapper">
		<picture>
			<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$">
			<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$">
			<img class="b-hero_centered-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$" alt="logo">
		</picture>

		<p class="b-hero_centered-main">
			<span class="b-hero_centered-text_block ml16">let your wild side roam free</span>
		</p>

		<p class="b-hero_centered-secondary">
			<span class="b-hero_centered-subtext">18 shades of matte and shimmer pressed pigments</span>
		</p>

		<span class="b-button_2 m-centered">Button type 1</span>
    </figcaption>
</figure>
```

## Example #2. Content centered to middle, white sub text turns black and goes below on small screen, white button:

```html_example
<figure class="b-hero_centered m-separated_mobile" data-js-animatable>
	<picture  class="b-hero_centered-image">
		<source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
		<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
		<img src="//placehold.it/1920x1080" alt="background image">
	</picture>
    <figcaption class="b-hero_centered-wrapper">
		<picture>
			<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$">
			<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$">
			<img class="b-hero_centered-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$" alt="logo">
		</picture>

		<p class="b-hero_centered-main">
			<span class="b-hero_centered-text_block ml16">let your wild side roam free</span>
		</p>

		<p class="b-hero_centered-secondary">
			<span class="b-hero_centered-subtext">18 shades of matte and shimmer pressed pigments</span>
		</p>

		<span class="b-button_2 m-centered">Button type 1</span>
    </figcaption>
</figure>
```

## Example #3. Content centered to top, white sub text turns black and goes below on small screen, white button:

```html_example
<figure class="b-hero_centered m-separated_mobile m-top_content" data-js-animatable>
	<picture  class="b-hero_centered-image">
		<source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
		<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
		<img src="//placehold.it/1920x1080" alt="background image">
	</picture>
	<figcaption class="b-hero_centered-wrapper">
		<picture>
			<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$">
			<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$">
			<img class="b-hero_centered-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_white_svg.svg?$staticlink$" alt="logo">
		</picture>

		<p class="b-hero_centered-main">
			<span class="b-hero_centered-text_block ml16">let your wild side roam free</span>
		</p>

		<p class="b-hero_centered-secondary">
			<span class="b-hero_centered-subtext">18 shades of matte and shimmer pressed pigments</span>
		</p>

		<span class="b-button_2 m-centered">Button type 1</span>
    </figcaption>
</figure>
```

*/

.b-hero_centered {
	display: grid;
	grid-template-areas: 'contents';
	height: 100%;
	overflow: hidden;

	&-image {
		align-self: stretch;
		grid-area: contents;
		object-fit: cover;
		transition: 0.7s;
		width: 100%;
	}

	&-subtext {
		@include media(s) {
			color: color(dark);
		}
	}

	&-main {
		@include font(bold, 32px, 1.3);

		font-variant: small-caps;
		margin-bottom: rh(3);
	}

	&-wrapper {
		align-self: center;
		grid-area: contents;
		padding: rh(13 17);
		position: relative;
		text-align: center;

		@include media(s) {
			padding: rh(4 5);
		}
	}

	&-secondary {
		@include font(medium, 16px, 1.4);

		color: color(light);
		margin-bottom: rh(8);
	}

	&-svg_logo {
		align-self: center;
		grid-area: contents;
		margin: rh(8) auto;
		max-width: 400px;
		padding: 0;

		@include media(s) {
			margin: 30px auto;
		}
	}

	&-text_block {
		@include font(bold);

		color: color(light);
		display: inline-block;
		font-size: 87px;
		font-variant: small-caps;
		line-height: 1;
		margin-left: -100%;
		margin-right: -100%;
		max-width: 800px;
		position: relative;
		text-align: center;
		text-shadow: 4px 4px 20px rgba(0, 0, 0, 0.99);

		@include media(m) {
			font-size: rh(13);
		}

		@include media(s) {
			color: color(dark);
			font-size: 28px;
			text-shadow: none;
			width: 100%;
		}
	}

	&.m-bottom_content &-wrapper {
		align-self: end;
	}

	&.m-top_content &-wrapper {
		align-self: start;
	}

	&.m-separated_mobile {
		@include media(s) {
			display: flex;
			flex-direction: column;
		}
	}
}
