/*md
# Button type 3

Simple light button, uppercased

```html_example
<span class="b-button_3">Button type 3</span>
```

Simple light button, uppercased, centered

```html_example
<span class="b-button_3 m-centered">Button type 3</span>
```
*/

.b-button_3 {
	@include g-button;

	background-color: color(light);
	border-color: color(lighter4);
	margin: 0 auto;
	padding-left: rh(12);
	padding-right: rh(12);
	position: relative;
	width: auto;
	z-index: 2;

	@include media (l-up) {
		&:hover {
			border-color: color(lighter3);
		}
	}

	@supports (width: fit-content) {
		width: fit-content;
	}

	@include media(s) {
		background-color: transparent;
		border: none;
		border-bottom: 1px solid color(light);
		color: color(light);
		padding-bottom: 2px;
		padding-left: 0;
		padding-right: 0;
	}
}

.b-button_3.m-centered {
	padding-left: rh(12);
	padding-right: rh(12);
	width: auto;

	@supports (width: fit-content) {
		width: fit-content;
	}
}
