/*md
# Button type 1

Simple dark button, uppercased

```html_example
<span class="b-button_1">Button type 1</span>
```

Simple dark button, uppercased centered

```html_example
<span class="b-button_1 m-centered">Button type 1</span>
```
*/

.b-button_1 {
	@include g-button_1;
}

.b-button_1.m-centered {
	@include g-button_1(root, centered);
}

.b-button_1.m-small {
	@include g-button_1(root, small);
}
