/*md
# Blog Group

## Example #1. Blog:

```html_example
<div class="b-blog_group h-site_width">

	<p class="b-blog_group-beyond_text" style="margin-bottom:30px;">Our Story</p>

	<button class="b-button_4" style="margin-bottom:50px;">WHO WE ARE</button>

	<div class="b-blog_group-row">
		<div class="b-blog_group-column">
		<p class="b-blog_group-text">Revolution serves, represents and champions a diverse set of customers. In an industry where beauty norms have only just begun to be challenged, Revolution Beauty has been breaking down these rules and standing for inclusivity since the day it was born.
		<br><br>
		Back in 2014, diversity was still a niche concept. Inclusivity wasn’t very high on brands’ agendas. A small disruptor, then named Makeup Revolution, emerged with a clear concept – to challenge existing standards and democratise an industry which plays a key role in defining society.
		</p>
		<img class="b-blog_group-image" src="//placehold.it/600x300" alt="background image">
		<p class="b-blog_group-text">Revolution serves, represents and champions a diverse set of customers. In an industry where beauty norms have only just begun to be challenged, Revolution Beauty has been breaking down these rules and standing for inclusivity since the day it was born.
		<br><br>
		Back in 2014, diversity was still a niche concept. Inclusivity wasn’t very high on brands’ agendas. A small disruptor, then named Makeup Revolution, emerged with a clear concept – to challenge existing standards and democratise an industry which plays a key role in defining society.
		</p>
		<img class="b-blog_group-image" src="//placehold.it/350x300" alt="background image">
		</div>

		<div class="b-blog_group-column">
		<p class="b-blog_group-text" style="font-weight: bold;"><b>Adam Minto, founder, says: </b></p>
		<p class="b-blog_group-text" style="text-align: center; font-style: italic;">
		“Five years ago Revolution was created out of a vision for a better beauty company, a ‘new normal’. A business that values its consumers and creates incredible products directly from their feedback. <br> <br>
		 We have now grown to become a global brand that still stands for the same values – inclusivity, diversity, disrupting outdated beauty perceptions.    <br> <br>
		We believe everyone should have access to quality make up if they want to, and that every definition of beauty deserves to be celebrated.”
		</p>
		<img class="b-blog_group-image" src="//placehold.it/400x350" alt="background image">
		<p class="b-blog_group-text">Revolution serves, represents and champions a diverse set of customers. In an industry where beauty norms have only just begun to be challenged, Revolution Beauty has been breaking down these rules and standing for inclusivity since the day it was born.
		<br><br>
		Back in 2014, diversity was still a niche concept. Inclusivity wasn’t very high on brands’ agendas. A small disruptor, then named Makeup Revolution, emerged with a clear concept – to challenge existing standards and democratise an industry which plays a key role in defining society.
		</p>
		<img class="b-blog_group-image" src="//placehold.it/300x350" alt="background image">
		<p class="b-blog_group-heading"> - Beauty’s brands include:</p>
		<p class="b-blog_group-text">
		<span  style="font-weight: bold;">Back in 2014, diversity was still a niche concept</span>. Inclusivity wasn’t very high on brands’ agendas. A small disruptor, then named Makeup Revolution, emerged with a clear concept – to challenge existing standards and democratise an industry which plays a key role in defining society.
		</p>
		<img class="b-blog_group-image" src="//placehold.it/550x350" alt="background image">
		</div>
	</div>

</div>
```
*/

.b-blog_group {
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;

	&-beyond_text {
		@include font(beyond_infinity, 150px, 1);

		@include media(s) {
			font-size: 88px;
		}
	}

	&-row {
		display: flex;
		flex-direction: row;

		@include media(s) {
			flex-direction: column;
		}
	}

	&-column {
		padding: rh(3);
		width: 50%;

		@include media(s) {
			width: 100%;
		}
	}

	&-image {
		margin-bottom: rh(5);
		width: 100%;
	}

	&-heading {
		@include font(medium, 26px);

		line-height: 1.5;
		padding-bottom: 10px;
		padding-right: rh(6);
	}

	&-text {
		@include font(regular, 16px);

		line-height: 1.5;
		padding-bottom: rh(5);
		padding-right: rh(6);
	}
}
