.b-product_tile {
	height: 100%;
	position: relative;

	@include media(l-up) {
		&:hover &-body {
			background: color(light);
		}

		&-action {
			display: block;
		}
	}

	&-body {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&-content {
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	&-badge {
		@include g-badge($type: plp);

		position: absolute;
		right: 10px;
		top: 10px;
	}

	&-badge_place {
		display: flex;
		flex-wrap: wrap;
		height: 30px;
		position: absolute;
		text-align: center;
		top: 10px;
		width: auto;
	}

	&-badge_item {
		height: 30px;
		margin-bottom: 7px;
		margin-right: 7px;

		@include media(m-down) {
			height: 20px;
		}
	}

	&-picture {
		padding-bottom: 105%;
		position: relative;

		@include media(m-down) {
			margin-bottom: rh(1.5);
		}
	}

	&-picture_container {
		align-items: center;
		display: flex;
		height: 100%;
		justify-content: center;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 100%;

		@include media(m-down) {
			div:not(:first-child) img {
				display: none;
			}
		}

		img {
			@include image_loader;

			background-size: auto;
		}
	}

	&-picture .tns-nav {
		left: 0;
		position: absolute;
		top: 100%;
		width: 100%;
	}

	&-size_label {
		@include font(regular, 12px);
	}

	&-wishlist {
		bottom: 14px;
		position: absolute;
		right: 4px;
	}

	&-category {
		@include font(medium, 10px);

		color: color(lighter2);
		margin: rh(1 0 3);
		text-align: center;
		text-transform: uppercase;
	}

	&-title {
		@include font(bold, 16px);

		margin: rh(3 2 4);
		text-align: center;
	}

	&-price {
		margin-top: 0;
		text-align: center;
	}

	&-view_set {
		@include g-button_1;
	}

	&-ratings_wrapper {
		margin-bottom: rh(1.5);
	}

	&-promo,
	&-availability,
	&-ratings_wrapper,
	&-back_in_stock {
		margin-top: rh(1);
		text-align: center;
	}

	&-availability .b-availability-inner {
		margin: 0;
	}

	&-ratings {
		display: flex;
		justify-content: center;
	}

	&-ratings .yotpo-stars {
		margin-right: rh(2);
	}

	&-ratings .yotpo-stars + .text-m {
		@include font(regular, 12px);
	}

	&-swatches {
		margin-bottom: rh(2);
		margin-top: rh(2);
	}

	&-swatches_text {
		text-align: center;
	}

	&-swatches .b-swatch_colors {
		padding-bottom: 0;
	}

	&-swatches .b-swatch_colors-item {
		@include media(m-down) {
			&:nth-child(3) {
				display: none;
			}
		}
	}

	&-swatches .b-swatch_colors-wrapper {
		align-items: center;
		justify-content: center;
	}

	&-swatches .b-swatch_colors-thumb {
		height: 22px;
		width: 22px;
	}

	&-swatches .b-swatch_colors-item + .b-swatch_colors-item {
		margin-left: 5px;
	}

	&-action {
		margin-top: auto;

		@include media(l-up) {
			background: color(light);
			left: 0;
			padding: rh(0 5 5);
			position: absolute;
			top: 100%;
			width: 100%;

			@include media(s) {
				padding: rh(0 2 2);
			}
		}
	}

	.b-notify_me {
		padding-top: 0;

		&-description {
			margin-bottom: rh(4);
		}

		&-description,
		&-description_copy {
			display: none;
		}

		&-button {
			@include g-button_1;
		}
	}

	& &-availability {
		.b-notify_me-description,
		.b-notify_me-description_copy {
			@include font(bold, 14px);

			display: block;
			margin-bottom: rh(2);
		}
	}

	&-error {
		@include g-error;

		margin-bottom: rh(1.5);
		margin-top: rh(1.5);
		text-align: center;
	}
}
