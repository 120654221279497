.b-footer_security {
	@include media(m-up) {
		max-width: 360px;
		text-align: left;
	}

	@include media(m) {
		width: 30%;
	}

	@include media(s) {
		margin-top: rh(10);
		padding: 0 rh(5);
	}

	&_external {
		display: flex;
    	align-items: end;
	}

	&-title {
		@include font(medium, 14px);

		margin-bottom: rh(6);
		text-transform: uppercase;

		@include media(s) {
			display: none;
		}
	}

	&-item {
		@include font(regular, 12px);

		& + & {
			margin-top: rh(5);

			@include media(s) {
				margin-top: rh(2.5);
			}
		}
	}

	&-copy {
		margin-bottom: rh(4);

		@include media(s) {
			display: none;
		}
	}

	&-payments_images,
	&-secure_images {
		display: flex;
		flex-wrap: wrap;

		img {
			max-height: 22px;
		}
	}

	&-payments_images {
		justify-self: center;
		justify-content: center;
		margin: 100px 10px 0 10px;
		@include media(s) {
			margin-top: 30px;
		}
	}

	&-payment_image {
		background-image: url('../../images/payments/sprite.png');
		background-repeat: no-repeat;
		display: inline-block;
		font-size: 0;
		line-height: 22px;
		margin: rh(0 3 2.5);

		@include media(m) {
			margin: rh(0 2.5 2.5);
		}

		@include media(s) {
			max-height: 25px;
		}

		&.m-paypal {
			background-position: -10px -110px;
			width: 45px;
		}

		&.m-amex {
			background-position: -64px -152px;
			max-height: 25px;
			width: 30px;
		}

		&.m-mastercard {
			background-position: -114px -152px;
			width: 29px;
		}

		&.m-maestro {
			background-position: -163px -152px;
			width: 28px;
		}

		&.m-visa {
			background-position: -135px -110px;
			width: 39px;
		}

		&.m-klarna {
			background-position: -147px -80px;
			height: 20px;
			margin-right: -9px;
			transform: scale(0.85);
			transform-origin: left;
			width: 71px;
		}

		&.m-cartebleue {
			background-position: -180px -109px;
			height: 35px;
			margin-right: 6px;
			transform: scale(0.65);
			transform-origin: top left;
			width: 46px;
		}

		&.m-amazon {
			background-position: -86px -50px;
			height: 35px;
			margin-bottom: 0;
			margin-left: 0;
			transform: scale(0.75);
			transform-origin: top left;
			width: 39.3px;
		}

		&:last-child {
			margin-right: 0;
		}
	}

	&-secure_image {
		background-image: url('../../images/payments/sprite.png');
		background-repeat: no-repeat;
		display: inline-block;
		font-size: 0;
		line-height: 22px;

		&.m-norton {
			background-position: -75px -110px;
			width: 40px;
		}

		&.m-worldpay {
			background-position: -135px -50px;
			width: 60px;
		}

		&.m-pci {
			background-position: -10px -152px;
			width: 34px;
		}

		& + & {
			margin-left: rh(5);
		}
	}
}
