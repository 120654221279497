/*md
# Banner type 4

Image banner with bold uppercase text and CTA.
May have one of three content alignments: Center (by default), Left or Right.

May use one of three color schemes:
- White text with black background (by default)
	In this case CTA has black text color, no background.
- Black text
- White text

Each line of text is supposed to go inside of a separate `<span>` container.

## HTML structure example:
```
<figure class="g-banner_4 m-right>
	<picture class="g-banner_4-image">
		<img src="//placehold.it/800x400" alt="How to shop sale" />
	</picture>

	<figcaption class="g-banner_4-wrapper">
		<p class="g-banner_4-text">
			<span class="g-banner_4-title">
				How to shop<br/>
				the sale like a pro
			</span>
			<span class="g-banner_4-text_block">Your guide to navigating sale season: don’t go shopping without it.</span>
		</p>

		<div class="g-banner_4-cta">
			<a class="g-banner_4-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## SCSS usage example:
```
.g-banner_4 {
	@include g-banner_4;

	&-image {
		@include g-banner_4(_image);
	}

	&-image_link {
		@include g-banner_4(_image_link);
	}

	&-image_link &-image {
		@include g-banner_4(_image_link_pic);
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_4(_wrapper);
	}

	&.m-left &-wrapper {
		@include g-banner_4(_align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_4(_align_right);
	}

	&-text {
		@include g-banner_4(_text);
	}

	&-text_block {
		@include g-banner_4(_text_block);
	}

	&-cta {
		@include g-banner_4(_cta);
	}

	&-cta_link {
		@include g-banner_4(_cta_link);
	}
}
```
*/

@mixin g-banner_4($el: root, $mod: default) {
	@if $el == root {
		@if $mod == default {
			display: grid;
			grid-template-areas: 'contents';
		}
	}

	@if $el == _image_link {
		@if $mod == default {
			align-self: stretch;
			display: flex;
			grid-area: contents;
			object-fit: none;
		}
	}

	@if $el == _image {
		@if $mod == default {
			@if $mod == default {
				align-self: stretch;
				display: flex;
				grid-area: contents;
				object-fit: cover;
				width: 100%;

				img {
					object-fit: cover;
				}
			}
		}
	}

	@if $el == _wrapper {
		@if $mod == default {
			color: color(light);
			display: grid;
			grid-area: contents;
			grid-template-areas: 'empty' 'text' 'cta';
			grid-template-rows: 1fr auto 1fr;
			justify-self: center;
			padding: rh(5 17);
			text-align: center;

			@include media(s) {
				padding: rh(0 7);
			}
		}

		@if $mod == align_left {
			justify-self: start;
			text-align: left;
		}

		@if $mod == align_right {
			justify-self: end;
			text-align: right;
		}
	}

	@if $el == _text {
		@if $mod == default {
			@include font(medium, 14px, 20px);

			grid-area: text;
		}
	}

	@if $el == _title {
		@if $mod == default {
			@include font(secondary_bold, 40px, 1);

			display: block;
			margin-bottom: rh(6);
			text-transform: uppercase;

			@include media(s) {
				font-size: 26px;
			}
		}
	}

	@if $el == _cta {
		@if $mod == default {
			grid-area: cta;
			margin-top: rh(9);
		}
	}

	@if $el == _cta_link {
		@if $mod == default {
			@include font(medium, 14px);

			border-bottom: 1px solid color(light);
			display: inline-block;
			padding-bottom: rh(1);
			text-transform: uppercase;
		}
	}
}
