.b-notify_popup {
	text-align: left;
	width: 310px;

	@include media(s) {
		width: 240px;
	}

	&-title {
		@include t-title-3;

		margin-bottom: rh(10);
		text-align: center;

		@include media(s) {
			margin: 0 auto rh(8);
			max-width: 150px;
		}
	}

	&-product {
		align-items: flex-start;
		display: flex;
		margin-bottom: rh(10);

		@include media(s) {
			margin-bottom: rh(7);
		}
	}

	&-product_image {
		margin-right: rh(5);
		max-height: 100px;
	}

	&-product_name {
		@include font(bold, 16px, normal);

		margin-bottom: rh(4);

		@include media(s) {
			font-size: 14px;
		}
	}

	&-variation_key {
		font-size: 14px;

		&::after {
			content: ':';
		}
	}

	&-variation_value {
		@include font(bold, 14px);
	}

	&-variation_image_wrapper {
		border: 1px solid color(lighter5);
		height: 40px;
		margin-top: rh(3);
		padding: rh(1.5);
		width: 40px;
	}

	&-variation_image {
		height: 100%;
		object-fit: cover;
		width: 100%;
	}

	&-form_description {
		@include font(regular, 14px, 24px);

		margin-bottom: rh(9);

		@include media(s) {
			margin-bottom: rh(7);
		}
	}

	&-submit,
	&-continue_shopping {
		@include g-button_1;

		margin-top: rh(2);
	}

	&-success_title {
		@include font(bold, 14px, 24px);

		margin-bottom: rh(2);
	}

	&-success_copy {
		@include font(regular, 14px, 24px);

		margin-bottom: rh(7);
	}
}
