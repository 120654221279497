/*md
@no-stat
# Input Phone

## HTML structure example:

```
<label class="f-input_tel">
	<span class="f-input_tel-label">Telephone</span>
	<input class="f-input_tel-field" type="tel" name="tel">
</label>
```

### Default phone field

```html_example
<label class="f-input_tel">
	<span class="f-input_tel-label">Telephone</span>
	<input class="f-input_tel-field" type="tel" name="tel">
</label>
```

### Phone field is required

```html_example
<label class="f-input_tel m-required">
	<span class="f-input_tel-label">Telephone</span>
	<input class="f-input_tel-field" type="tel" name="tel" required>
</label>
```

### Active phone field (optional)

```html_example
<label class="f-input_tel m-prefilled">
	<span class="f-input_tel-label">Telephone<span class="f-input_tel-optional_copy"> (optional)</span></span>
	<input class="f-input_tel-field" type="tel" name="tel" value="+985623245">
</label>
```

### Phone field in error state

```html_example
<label class="f-input_tel m-error">
	<span class="f-input_tel-label">Telephone</span>
	<input class="f-input_tel-field" type="tel" name="tel" value="233">
</label>
```

### Disabled phone field

```html_example
<label class="f-input_tel m-disabled">
	<span class="f-input_tel-label">Telephone</span>
	<input class="f-input_tel-field" type="tel" name="tel" disabled>
</label>
```

### Phone field without label

```html_example
<label class="f-input_tel">
	<input class="f-input_tel-field" type="tel" name="tel" required>
</label>
```
*/

@include f-input_tel;

.f-input_tel {
	&-pswd {
		&::placeholder {
			color: transparent;
		}
	}
}
