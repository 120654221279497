.b-notify_me {
	padding-top: rh(3);

	&-description {
		text-align: center;
	}

	&-description_title {
		@include font(bold, 14px);

		margin-bottom: 8px;
	}

	&-description_copy {
		@include font(regular, 12px);

		margin-bottom: 16px;
	}

	&-actions {
		display: flex;
	}

	&-button {
		@include g-button_1;

		flex: 1;
		margin-right: 10px;
		padding: 0;
	}
}
