///	Grid column
///	@example
///		@include grid-column;

@use 'sass:math';

@mixin grid-column($viewportSize: 'xlarge', $numberOfColumns: 12) {
	@for $i from 1 through $numberOfColumns {
		&-column.m-size_#{$viewportSize}_#{$i} {
			/* stylelint-disable */
			flex: percentage(math.div($i, $numberOfColumns));
			max-width: percentage(math.div($i, $numberOfColumns));
			/* stylelint-enable */
		}
	}
}
