.b-email_sign_up {
	@include media(s) {
		padding: 0 20px;
	}

	&-title {
		@include font(bold, 26px);

		margin-bottom: 10px;
		text-transform: uppercase;

		@include media(s) {
			font-size: 22px;
		}
	}

	&-description {
		@include font(regular, 14px, 1.6);

		margin-bottom: 24px;
	}

	&-form {
		@include f-input_email(_style_1);

		margin-bottom: 16px;
	}

	&-email_input,
	&-name_input {
		margin: 0 auto 20px;
		max-width: 380px;

		&.f-field {
			text-align: center;
		}
	}

	&-email_input .f-input_email-field,
	&-name_input .f-input_text-field {
		border-left: 0;
		border-right: 0;
		border-top: 0;
		text-align: center;
	}

	&-submit {
		@include g-button_2;

		margin: 0 auto;
		width: 180px;
	}

	&-success_message {
		@include font(bold, 13px);

		margin-bottom: 96px;
		margin-top: 90px;

		@include media(s) {
			margin-bottom: 40px;
			margin-top: 40px;
		}
	}

	&-terms {
		@include font(regular, 10px);

		color: color(lighter2);
		margin: 0 auto;
		max-width: 280px;
	}

	&-field.m-success + &-terms {
		display: none;
	}

	&-terms_link {
		@include link(underlined, lighter2) {
			font-size: 10px;
		}
	}
}
