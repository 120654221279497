/*md
# Image tile banner

Text tile 'sweet-sans'

```html_example
<div class="g-text_tile">
	<p class="g-text_tile-main">
		<span class="g-text_tile-text_block">YOUR SOLID TEXT BLOCK</span>
	</p>

	<p class="g-text_tile-secondary">
		Secondary text block 1
		<span class="g-text_tile-text_block">Secondary text block 2</span>
	</p>

	<div class="g-text_tile-cta">
		<a class="g-text_tile-cta_link" href="#">Shop now</a>
	</div>
</div>
```

Text tile centered 'sweet-sans'

```html_example
<div class="g-text_tile m-centered">
	<p class="g-text_tile-main">
		<span class="g-text_tile-text_block">OUR REVOLUTION FAMILY</span>
	</p>

	<p class="g-text_tile-secondary">
		Secondary text block 1
		<span class="g-text_tile-text_block">Secondary text block 2</span>
	</p>

	<div class="g-text_tile-cta">
		<a class="g-text_tile-cta_link" href="#">Shop now</a>
	</div>
</div>
```

Text tile centered 'small-caps'

```html_example
<div class="g-text_tile m-centered m-smallcaps">
	<p class="g-text_tile-main">
		<span class="g-text_tile-text_block">Ageless. Unisex. Affordable. Yours.</span>
	</p>

	<p class="g-text_tile-secondary">
		Secondary text block 1
		<span class="g-text_tile-text_block">Secondary text block 2</span>
	</p>

	<div class="g-text_tile-cta">
		<a class="g-text_tile-cta_link" href="#">Shop now</a>
	</div>
</div>
```

Text tile right aligned  'sweet-sans'

```html_example
<div class="g-text_tile m-rightaligned">
	<p class="g-text_tile-main">
		<span class="g-text_tile-text_block">Your solid text block 1</span>
		Your text block 2
	</p>

	<p class="g-text_tile-secondary">
		Secondary text block 1
		<span class="g-text_tile-text_block">Secondary text block 2</span>
	</p>

	<div class="g-text_tile-cta">
		<a class="g-text_tile-cta_link" href="#">Shop now</a>
	</div>
</div>
```
*/

.g-text_tile {
	&-main {
		@include font(bold, 40px);

		color: color(dark);
		line-height: 1;

		@include media(m) {
			font-size: 40px;
		}

		@include media(s) {
			font-size: 28px;
		}
	}

	&-secondary {
		@include font(medium, 16px, 1.4);

		color: color(dark);
		margin-top: rh(2);
	}

	&-text_block {
		display: inline-block;
	}

	&-cta {
		@include font(bold, 26px, 1.3);

		color: color(dark);

		@include media(s) {
			@include font(bold, 24px);
		}
	}

	&-cta_link {
		@include font(medium, 14px);

		color: color(dark);
		display: inline-block;
		margin-top: rh(5);
		text-transform: uppercase;

		&::after {
			border-bottom: solid 1px #141414;
			content: '';
			display: block;
			transform: scaleX(1);
			transition: transform 500ms ease-in-out;
		}

		&:hover::after {
			transform: scaleX(0.5);
			transition: transform 500ms ease-in-out;
		}
	}

	&-question {
		font-size: 49px;
		left: -12px;
		position: relative;

		@include media(s) {
			font-size: 28px;
			left: -9px;
		}
	}

	&-wrap {
		@include media(m-up) {
			margin-bottom: rh(18);
		}
	}

	&.m-centered &-main,
	&.m-centered &-secondary,
	&.m-centered &-cta {
		text-align: center;
	}

	&.m-rightaligned &-main,
	&.m-rightaligned &-secondary,
	&.m-rightaligned &-cta {
		text-align: right;
	}

	&.m-smallcaps &-main {
		font-variant: small-caps;
		text-transform: none;
	}

	&.m-slogan &-main {
		letter-spacing: 9.1px;
	}

	&.m-druk &-text_block {
		@include font(druk, 70px, 1);

		font-weight: normal;
		letter-spacing: 4px;

		@include media(s) {
			font-size: 40px;
		}
	}

	&.m-mobilemax420 &-text_block {
		@include media(s) {
			max-width: 420px;
		}
	}
}
