/*md
# CLP hero banner

Banner with title, text content and CTA block on one hand and image area on the other
May have optional color background (inline style)

## Example. CLP hero banner with color background:

```html_example
<div class="b-clp_hero" style="background-color: silver;">
	<div class="b-clp_hero-section">
		<div class="b-clp_hero-wrapper">
			<div class="b-clp_hero-media">
				<img src="//placehold.it/900x500" alt="">
			</div>
			<div class="b-clp_hero-title">
				Eyes
			</div>
			<div class="b-clp_hero-text">
				<p>
					Our eyes are the first to tell tales of our late night endeavours, poor diet and stress! Luckily, the right eye make up can disguise a multitude of sins so invest in a flesh-toned pencil, such as Charlotte Tilbury's Rock N' Kohl Eye Cheat to brighten the white; a highlighter dotted beside inner corners to widen and youthify; a black {eye liner} to help instantly fake fuller lashes and a volumising mascara, to lengthen thicken and create the best frame for your peepers.
				</p>
			</div>
			<div class="b-clp_hero-cta">
				<div class="b-clp_hero-cta_area">
					<a class="b-clp_hero-cta_link" href="#">Eyeshadow palette</a>
					<a class="b-clp_hero-cta_link" href="#">Eye foil</a>
					<a class="b-clp_hero-cta_link" href="#">Eyeshadow</a>
					<a class="b-clp_hero-cta_link" href="#">Eye shadow primer</a>
					<a class="b-clp_hero-cta_link" href="#">All</a>
				</div>
				<div class="b-clp_hero-cta_area">
					<a class="b-clp_hero-cta_link" href="#">Brows</a>
					<a class="b-clp_hero-cta_link" href="#">Eyeliner</a>
					<a class="b-clp_hero-cta_link" href="#">Mascara</a>
					<a class="b-clp_hero-cta_link" href="#">Eye glitter</a>
				</div>
			</div>
		</div>
	</div>
</div>
```

# CLP hero banner with inverted (dark) color scheme

Banner with title, text content and CTA block on one hand and image area on the other
with black color background and white text elements

## Example. Black CLP hero banner:

```html_example
<div class="b-clp_hero m-invert-color">
	<div class="b-clp_hero-section">
		<div class="b-clp_hero-wrapper">
			<div class="b-clp_hero-media">
				<img src="//placehold.it/900x500" alt="">
			</div>
			<div class="b-clp_hero-title">
				Eyes
			</div>
			<div class="b-clp_hero-text">
				<p>
					Our eyes are the first to tell tales of our late night endeavours, poor diet and stress! Luckily, the right eye make up can disguise a multitude of sins so invest in a flesh-toned pencil, such as Charlotte Tilbury's Rock N' Kohl Eye Cheat to brighten the white; a highlighter dotted beside inner corners to widen and youthify; a black {eye liner} to help instantly fake fuller lashes and a volumising mascara, to lengthen thicken and create the best frame for your peepers.
				</p>
			</div>
			<div class="b-clp_hero-cta">
				<div class="b-clp_hero-cta_area">
					<a class="b-clp_hero-cta_link" href="#">Eyeshadow palette</a>
					<a class="b-clp_hero-cta_link" href="#">Eye foil</a>
					<a class="b-clp_hero-cta_link" href="#">Eyeshadow</a>
					<a class="b-clp_hero-cta_link" href="#">Eye shadow primer</a>
					<a class="b-clp_hero-cta_link" href="#">All</a>
				</div>
				<div class="b-clp_hero-cta_area">
					<a class="b-clp_hero-cta_link" href="#">Brows</a>
					<a class="b-clp_hero-cta_link" href="#">Eyeliner</a>
					<a class="b-clp_hero-cta_link" href="#">Mascara</a>
					<a class="b-clp_hero-cta_link" href="#">Eye glitter</a>
				</div>
			</div>
		</div>
	</div>
</div>
```
*/

.b-clp_hero {
	color: color(dark);
	margin-top: var(--grid-gutter);

	&.m-invert-color {
		background: color(dark);
		color: color(light);
	}

	&-wrapper {
		display: grid;
		grid-template-areas:
			'title    media'
			'text     media'
			'cta      media';
		grid-template-columns: 1fr 2fr;
		margin: calc(-1 * var(--grid-gutter));

		@include media(s) {
			display: block;
		}
	}

	&-title {
		@include font(bold, 35px);

		display: block;
		grid-area: title;
		margin: rh(12) var(--grid-gutter) rh(7);
		text-transform: uppercase;

		@include media(s) {
			display: none;
		}

		@include media(s) {
			font-size: 24px;
			margin-bottom: rh(5);
			margin-top: rh(9);
		}
	}

	&-media {
		grid-area: media;
		margin: 0 var(--grid-gutter);
	}

	&-image {
		height: 100%;
		object-fit: cover;

		@include media(s) {
			display: none;
		}
	}

	&-text {
		@include font(medium, 14px, 1.4);

		grid-area: text;
		margin: 0 var(--grid-gutter);

		@include media(s) {
			display: none;
		}
	}

	&-cta {
		display: flex;
		grid-area: cta;

		@include media(s) {
			margin-top: rh(4);
		}
	}

	&-cta_area {
		display: flex;
		flex-direction: column;
	}

	&-cta_area + &-cta_area {
		margin-left: rh(8);
	}

	&-cta_link {
		@include font(medium, 12px);

		color: color(dark);
		margin-top: rh(3);
		text-transform: uppercase;
	}

	&-cta_link:hover {
		text-decoration: underline;
	}

	&.m-invert-color &-cta_link {
		color: color(light); // TODO: fix, add styles to use currentColor
	}
}
