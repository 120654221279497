@mixin g-back_button {
	@include g-button-control {
		@include font(regular, 12px);

		align-items: center;
		background-color: color(lighter6);
		cursor: pointer;
		display: flex;
		text-transform: uppercase;

		@include icon(arrow-left) {
			margin-right: rh(4);
			opacity: 0.5;
		}
	}

	@include media(m-down) {
		padding: rh(5);
		width: 100%;
	}
}
