.b-recommendation_products {
	@include site-width;
	@include g-tabs_underlined;

	margin-bottom: rh(10);

	&-head {
		@include g-tabs_underlined(_head);
	}

	&-list {
		@include g-tabs_underlined(_list);

		justify-content: center;
	}

	&-control {
		@include g-tabs_underlined(_control);

		max-width: 25%;

		@include media(m) {
			max-width: 35%;
		}

		@include media(s) {
			max-width: 50%;
		}
	}

	&-control.m-tabs-active {
		@include g-tabs_underlined(_control_active);

		.l-cart-slot & {
			&::after {
				display: none;
			}
		}
	}

	&-control_name {
		@include g-tabs_underlined(_control_name);
	}

	&-content {
		display: flex;
		flex-direction: column-reverse;
	}

	&-item {
		@include g-tabs_underlined(_item);

		width: 100%;

		@include media(s) {
			padding-top: rh(7);
		}
	}

	&-item.m-tabs-shown {
		@include g-tabs_underlined(_item_shown);
	}

	&-item_name {
		@include font(bold, 14px, 1.2);

		color: color(dark);
		display: none;
		padding: rh(5 3);
		text-align: center;
		text-transform: uppercase;

		@include media(s) {
			display: block;
		}
	}

	&-carousel_container {
		align-items: stretch;
		display: flex;

		.l-plp-product_tile {
			height: 100%;
			position: relative;
		}

		.b-product_tile-action {
			padding: 0;
			position: static;
		}
	}

	&-carousel {
		max-width: 100%;
	}

	.b-product_tile-content {
		position: relative;
	}

	&.m-strict {
		.tns-controls {
			[data-controls='prev'],
			[data-controls='next'] {
				padding: 0;
			}

			[data-controls='prev'] {
				left: 0;
			}

			[data-controls='next'] {
				right: 0;
			}
		}

		.b-product_tile {
			margin: rh(0 4);
		}

		.b-product_tile-title {
			line-height: 22px;
			margin: rh(0 0 5);
		}

		.b-product_tile-content {
			margin-top: rh(12.5);

			@include media(s) {
				margin-top: rh(2.5);
			}
		}

		.b-product_tile-category {
			margin-bottom: rh(2);
		}

		.b-product_price {
			font-size: 14px;
		}

		.b-wishlist_button {
			height: 41px;
			width: 41px;

			&::before {
				font-size: 17px;
				line-height: 41px;
			}
		}
	}

	&.m-strict-grid {
		/* stylelint-disable declaration-no-important */
		.tns-controls {
			@include media(s) {
				padding-top: calc(265% - 16px) !important;
			}

			@include media(l-up) {
				padding-top: calc(35% - 16px) !important;
			}
		}
		/* stylelint-enable declaration-no-important */
		.b-recommendation_products-carousel_container .l-plp-product_tile {
			@include media(s) {
				height: 500px;
				padding-bottom: 35px;
				position: relative;
			}
		}

		.b-recommendation_products-carousel_group {
			@include media(s) {
				align-content: space-between;
				display: grid;
				height: auto;
				padding-bottom: 25px;
			}
		}

		.tns-nav > [aria-controls].tns-nav-active {
			background-color: #262121;
		}
	}

	&:not(.m-product-set) {
		.b-product_tile-title {
			@include media(m-down) {
				font-size: 14px;
			}
		}

		.tns-controls {
			left: 50%;
			padding-top: calc(33.3% - 16px);
			pointer-events: none;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 100%;

			@include media(s) {
				padding-top: calc(50% - 16px);
			}

			@include media(l-up) {
				padding-top: calc(25% - 16px);
			}
		}

		.tns-controls [data-controls] {
			pointer-events: all;
		}

		.b-product_tile-wishlist {
			@include media(s) {
				bottom: 0;
			}
		}

		.tns-controls [aria-controls] {
			background: color(pdp_slider_arrow);
			border: 1px solid color(lighter4);
			border-radius: 50%;
			cursor: pointer;
			height: 38px;
			text-align: center;
			transition: background 0.2s linear;
			width: 38px;

			@include media(m-down) {
				height: 30px;
				width: 30px;
			}

			&:hover {
				background: color(pdp_slider_arrow_hover);
				opacity: 1;
			}

			&::before {
				color: color(dark);
				font-size: 10px;
				line-height: 36px;

				@include media(m-down) {
					line-height: 28px;
				}
			}
		}

		.tns-controls [data-controls='next'] {
			@include icon(arrow-right);

			right: 16px;

			@include media(s) {
				right: 0;
			}

			.l-cart & {
				@include media(s) {
					right: 16px;
				}
			}
		}

		.tns-controls [data-controls='prev'] {
			@include icon(arrow-left);

			left: 16px;

			@include media(s) {
				left: 0;
			}

			.l-cart & {
				@include media(s) {
					left: 16px;
				}
			}
		}

		/* stylelint-disable declaration-no-important */
		.b-swatch_colors-item {
			background-color: transparent !important; //overwrite inline css
		}
		/* stylelint-enable declaration-no-important */
	}

	.b-product_tile-price {
		.b-button_1 {
			bottom: 0;
			margin-top: 4px;
			position: absolute;
			z-index: 1;
		}
	}

	.l-cart-slot & {
		padding: 0;

		@include media(s) {
			border-bottom: 40px solid color(lighter5);
			padding: 0 0 40px;
		}
	}

	&.m-product-set {
		margin-bottom: 0;
		padding: 0;
		/* stylelint-disable selector-no-qualifying-type */
		.tns-controls button[data-controls='next'] {
			right: 0;
		}

		.tns-controls button[data-controls='prev'] {
			left: 0;
		}
		/* stylelint-enable selector-no-qualifying-type */
		.b-recommendation_products-item_name,
		.b-recommendation_products-head {
			display: none;
		}

		.b-recommendation_products-item {
			padding-top: 0;
		}

		.b-product_tile-content {
			padding-left: 10px;
			padding-right: 10px;

			@include media(s) {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
		/* stylelint-disable no-descending-specificity */
		.b-product_tile-action,
		.b-product_tile-availability,
		.b-product_tile-badge,
		.b-product_tile-error,
		.b-product_tile-promo,
		.b-product_tile-ratings_wrapper,
		.b-product_tile-swatches,
		.b-product_tile-wishlist {
			display: none;
		}

		.b-product_tile-category,
		.b-product_tile-title,
		.b-product_tile-price {
			text-align: left;
		}

		/* stylelint-enable no-descending-specificity */

		/* stylelint-disable declaration-no-important */
		.b-product_tile-more {
			display: block !important; //override h-hidden
			margin-top: auto;
			padding-top: 15px;
		}
		/* stylelint-enable declaration-no-important */
		.b-pdp_accordion-container & {
			a {
				text-decoration: none;
			}
		}

		.b-product_tile-category {
			margin-bottom: 0;
		}

		.b-product_tile-price {
			.b-product_price {
				font-size: 14px;
			}
		}

		/* stylelint-disable no-descending-specificity */
		.b-product_tile-title {
			margin: rh(0 0 3);
		}

		.b-product_tile-action {
			padding: rh(3 0 0);
			position: static;
		}

		.b-add_to_bag {
			margin-left: 0;
			margin-right: 0;
		}

		.b-recommendation_products-carousel {
			margin-bottom: 20px;
		}

		.b-recommendation_products-carousel_container .l-plp-product_tile {
			padding: 0;
		}
		/* stylelint-enable no-descending-specificity */
	}
}
