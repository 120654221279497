/*md
# Button control

CTA Button

HTML structure example:
```
<button class="b-button_control">Customer Service</button>
```

SCSS structure example:
```
.b-button_control {
	@include g-button_control;
}
```
*/

@mixin g-button_control($padding: null) {
	appearance: none;
	background: none;
	border: 0;
	cursor: pointer;

	@if $padding != null {
		padding: $padding;
	} @else {
		padding: 0;
	}

	@content;
}
