/*md
# Banner type 2

Image banner with text and CTA aside.
Test and CTA area goes below the image for s-size viewports.

## HTML structure example:
```
<figure class="g-banner_2">
	<img class="g-banner_2-image" src="banner_2.jpg" alt="" />

	<figcaption class="g-banner_2-wrapper">
		<p class="g-banner_2-text">Your text</p>

		<a class="g-banner_2-cta" href="#">Shop now</a>
	</figcaption>
</figure>
```

## SCSS usage example:
```
.g-banner_2 {
	@include g-banner_2;

	&-image {
		@include g-banner_2(_image);
	}

	&-wrapper {
		@include g-banner_2(_wrapper);
	}

	&-text {
		@include g-banner_2(_text);
	}

	&-cta {
		@include g-banner_2(_cta);
	}

	&-title {
		@include g-banner_2(_title);
	}

	&.m-initial &-wrapper {
		@include g-banner_2(_wrapper, initial);
	}

	&.m-reverse {
		@include g-banner_2(root, reverse);
	}

	&.m-reverse &-wrapper {
		@include g-banner_2(_wrapper, reverse);
	}
}
```
*/

@mixin g-banner_2($el: root, $mod: default) {
	@if $el == root {
		@if $mod == default {
			align-items: center;
			display: flex;

			@include media(m-up) {
				flex-direction: row-reverse;
			}

			@include media(s) {
				flex-direction: column;
			}
		}

		@if $mod == reverse {
			@include media(m-up) {
				flex-direction: row;
			}
		}
	}

	@if $el == _image {
		@if $mod == default {
			width: 66%;

			@include media(s) {
				width: 100%;
			}
		}
	}

	@if $el == _wrapper {
		@if $mod == default {
			flex: 1 1 auto;
			padding: rh(5);
			text-align: center;

			@include media(s) {
				padding: rh(7 0 0);
			}
		}

		@if $mod == initial {
			align-self: flex-start;
			padding: 0;
			text-align: left;
		}

		@if $mod == reverse {
			padding: 0;
			text-align: left;

			@include media(l-up) {
				padding-left: rh(15);
			}

			@include media(m) {
				padding-left: rh(7);
			}
		}
	}

	@if $el == _text {
		@if $mod == default {
			@include font(bold, 26px, 1.3);

			color: color(dark);

			@include media(s) {
				@include font(bold, 24px);
			}
		}
	}

	@if $el == _cta {
		@if $mod == default {
			@include font(medium, 14px);

			border-bottom: 1px solid color(dark);
			color: color(dark);
			display: inline-block;
			margin-top: rh(5);
			text-transform: uppercase;
		}
	}

	@if $el == _title {
		@if $mod == default {
			@include font(bold, 35px);

			color: color(dark);
			margin-bottom: rh(7);
			text-transform: uppercase;
		}
	}
}
