@use 'sass:math';

.b-mini_cart {
	$quantity-box-size: 14px;
	/* stylelint-disable */
	$quantity-box-shift: math.div($quantity-box-size, -2);
	/* stylelint-enable */
	&-link {
		align-items: center;
		display: flex;
		position: relative;

		@include icon(cart) {
			font-size: 19px;
		}

		@include media(l-up) {
			&::before {
				font-size: 16px;
				margin-right: rh(1);
			}
		}
	}

	&-quantity {
		white-space: nowrap;

		@include media(m-down) {
			bottom: 100%;
			left: 100%;
			position: absolute;
		}

		@include media(l-up) {
			&::after,
			&::before {
				@include font(regular, 12px);
			}

			&::before {
				content: '(';
				margin-right: -4px;
			}

			&::after {
				content: ')';
				margin-left: -4px;
			}
		}
	}

	&-quantity_number {
		@include font(regular, 10px);

		@include media(l-up) {
			@include font(bold, 12px);
		}

		@include media(m-down) {
			background: color(dark);
			border-radius: 7px;
			color: color(light);
			left: 50%;
			min-height: 14px;
			min-width: 14px;
			padding: 2px 3px;
			position: absolute;
			text-align: center;
			top: 50%;
			transform: translate(-50%, -50%);

			&.m-active {
				background: color(red);
			}
		}
	}

	&-popover {
		background: color(light);
		bottom: 0;
		left: auto;
		padding: rh(15);
		position: fixed;
		right: 0;
		top: 0;
		transform: translateX(100%);
		transition: transform 0.5s linear;
		width: 600px;
		z-index: 5;

		@include media(s) {
			padding: rh(10 5);
			width: calc(100% - #{rh(10)});
		}
	}

	&-popover.m-show {
		transform: translateX(0);
	}

	&-container {
		@include noscrollbar;

		height: 100%;
		overflow-x: hidden;
		overflow-y: auto;
	}

	&-close {
		cursor: pointer;
		position: absolute;
		right: rh(2);
		top: rh(2);

		@include icon(close) {
			font-size: 18px;
		}

		@include media(m-up) {
			right: rh(10);
			top: rh(5);
		}
	}

	&-title {
		@include font(bold, 22px, 1);

		padding-bottom: rh(10);
		text-transform: uppercase;

		@include media(s) {
			font-size: 18px;
			padding-bottom: rh(8);
		}
	}

	&-info {
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: rh(8 0);
	}

	&-additional_info {
		display: none;
	}

	&-items {
		@include font(regular, 14px);
	}

	&-sub_total {
		@include font(regular, 18px);
	}

	&-sub_total_number {
		@include font(bold, 18px);
	}

	&-products_list {
		border-top: 1px solid color(lighter5);
	}

	&-product {
		border-bottom: 1px solid color(lighter5);
		padding: rh(8 0);
	}

	.b-miniproduct + .b-miniproduct {
		border-top: 1px solid color(lighter5);
		margin-top: rh(8);
		padding-top: rh(8);
	}

	&-product .b-miniproduct-price {
		display: none;
	}

	&-banner {
		margin-top: rh(15);
	}

	&-banner:empty {
		display: none;
	}

	&-popover.m-extend &-additional_info {
		display: block;
	}

	&-payment_method {
		text-align: center;

		// stylelint-disable
		.dw-apple-pay-button {
			margin-top: rh(5);
			
			&:hover {
				margin-top: rh(5);
			}
		}
		// stylelint-enable
	}
}
