.b-product_promo_tile {
	background-color: color(light);
	display: flex;
	flex-direction: column;

	&-content {
		@include font(regular, 14px, 1.2);

		display: flex;
		flex-direction: column;
		flex-grow: 1;
		padding: rh(2.5 3.5);
	}

	&-image {
		display: block;
		width: 100%;
	}

	&-brand {
		@include font(medium, 10px);

		color: color(lighter2);
		margin-bottom: rh(2);
		text-transform: uppercase;
	}

	&-name {
		@include font(bold, 16px);

		margin-bottom: rh(2);
	}

	&-price {
		margin-bottom: rh(2);
	}

	&-rating {
		margin-top: rh(2);
	}

	&-buynow {
		margin-top: auto;
	}
}
