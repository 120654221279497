/*md
# Banner type 1

Image banner with bold uppercased text and CTA

## Example #1. Text with black background, centered content:

```html_example
<figure class="g-banner_1">
	<img class="g-banner_1-image" src="//placehold.it/900x100" alt="" />

	<figcaption class="g-banner_1-wrapper">
		<p class="g-banner_1-text">
			<span class="g-banner_1-text_block">Your text line 1</span><br />

			<span class="g-banner_1-text_block">Text line 2</span>
		</p>

		<div class="g-banner_1-cta">
			<a class="g-banner_1-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #2. Black text, left-aligned content:

```html_example
<figure class="g-banner_1 m-left m-black">
	<img class="g-banner_1-image" src="//placehold.it/900x200" alt="" />

	<figcaption class="g-banner_1-wrapper">
		<p class="g-banner_1-text">
			<span class="g-banner_1-text_block">Your text line 1</span><br />

			<span class="g-banner_1-text_block">Text line 2</span>
		</p>

		<div class="g-banner_1-cta">
			<a class="g-banner_1-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #3. Square image, white text, right-aligned content:

```html_example
<figure class="g-banner_1 m-right m-white">
	<img class="g-banner_1-image" src="//placehold.it/900x200" alt="" />

	<figcaption class="g-banner_1-wrapper">
		<p class="g-banner_1-text">
			<span class="g-banner_1-text_block">Your text line 1</span><br />

			<span class="g-banner_1-text_block">Text line 2</span>
		</p>

		<div class="g-banner_1-cta">
			<a class="g-banner_1-cta_link" href="#">Shop now</a>
		</div>
	</figcaption>
</figure>
```

## Example #4. Text with black background, centered content, image as link:

```html_example
<figure class="g-banner_1">
	<a href="#" class="g-banner_1-image_link">
		<img class="g-banner_1-image" src="//placehold.it/900x100" alt="" />
	</a>
	<figcaption class="g-banner_1-wrapper">
		<p class="g-banner_1-text">
			<span class="g-banner_1-text_block">Your text line 1</span><br />

			<span class="g-banner_1-text_block">Text line 2</span>
		</p>

		<div class="g-banner_1-cta">
			<a class="g-banner_1-cta_link" href="#1">Shop now</a>
		</div>
	</figcaption>
</figure>
```
*/

.g-banner_1 {
	@include g-banner_1;

	&-image {
		@include g-banner_1(_image);
	}

	&-image_link {
		@include g-banner_1(_image_link);
	}

	&-image_link &-image {
		@include g-banner_1(_image_link_pic);
	}

	&-cta_link {
		@include g-banner_1(_cta_link);
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_1(_wrapper);
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&.m-left &-wrapper {
		@include g-banner_1(_wrapper, align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_1(_wrapper, align_right);
	}

	&.m-text-center &-wrapper {
		@include g-banner_1(_wrapper, text_center);
	}

	&-text {
		@include g-banner_1(_text);
	}

	&-text_block {
		@include g-banner_1(_text_block);
	}

	&.m-black &-text_block {
		@include g-banner_1(_text_block, black);
	}

	&.m-white &-text_block {
		@include g-banner_1(_text_block, white);
	}

	&-cta {
		@include g-banner_1(_cta);
	}

	&.m-white &-cta_link {
		@include g-banner_1(_link, white);
	}
}
