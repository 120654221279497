/*md
# User Generated Content

## HTML example

```
<div class="t-user_content">
	<section>
		<h1>Title 1</h1>
		<h2>Title 2</h2>
		<h3>Title 3</h3>
		<h4>Title 4</h4>
		<h5>Title 5</h5>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
	</section>
	<section>
		<ul>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
		</ul>
	</section>
	<section>
		<ol>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit</li>
		</ol>
	</section>
</div>
```

```html_example
<div class="t-user_content">
	<section>
		<h1>Title 1 / paragraph</h1>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	</section>
	<section>
		<h2>Title 2 / paragraph</h2>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
	</section>
	<section>
		<h3>Title 3 / unordered list</h3>
		<ul>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
		</ul>
	</section>
	<section>
		<h4>Title 4 / ordered list</h4>
		<ol>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
			<li>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</li>
		</ol>
	</section>
	<section>
		<table class="t-table">
			<caption><h5>Title 5 / ordered list</h5></caption>
			<thead>
				<th>Table head cell 1</th>
				<th>Table head cell 2</th>
				<th>Table head cell 3</th>
				<th>Table head cell 4</th>
			</thead>
			<tbody>
				<tr>
					<th>First column header cell</th>
					<td>XS</td>
					<td>S</td>
					<td>M</td>
				</tr>
				<tr>
					<th>First column header cell</th>
					<td>XS</td>
					<td>S</td>
					<td>M</td>
				</tr>
				<tr>
					<th>First column header cell</th>
					<td>XS</td>
					<td>S</td>
					<td>M</td>
				</tr>
				<tr>
					<th>First column header cell</th>
					<td>XS</td>
					<td>S</td>
					<td>M</td>
				</tr>
				<tr>
					<th>First column header cell</th>
					<td>XS</td>
					<td>S</td>
					<td>M</td>
				</tr>
			</tbody>
		</table>
	</section>
</div>
```
*/

.t-user_content,
%t-user_content {
	@include clearfix;
	@include user_content;
}
