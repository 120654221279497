/*md
# Banner type 3

Image banner with bold main text and tiny secondary info text.
May have one of three content alignments: Center (default), Left or Right.

May use one of two color schemes: text color may be white (by default) or black.

If lines should break in a specific way on small viewports or on window resize,
solid text blocks are supposed to be wrapped into `<span class="g-banner_3-text_block">..</span>` containers.

## HTML structure example:
```
<figure class="g-banner_3 m-left">
	<img class="g-banner_3-image" src="banner_3.jpg" alt="" />

	<figcaption class="g-banner_3-wrapper">
		<p class="g-banner_3-main">
			<span class="g-banner_3-text_block">Your solid text block 1</span>
			Your text block 2
		</p>

		<p class="g-banner_3-secondary">
			Secondary text block 1
			<span class="g-banner_3-text_block">Secondary text block 2</span>
		</p>
	</figcaption>
</figure>
```

## SCSS usage example:
```
.g-banner_3 {
	@include g-banner_3;

	&-image {
		@include g-banner_3(_image);
	}

	&-image_link {
		@include g-banner_3(_image_link);
	}

	&-image_link &-image {
		@include g-banner_3(_image_link_pic);
	}

	&-image_link + &-wrapper {
		pointer-events: none;
	}

	&-image_link + &-wrapper &-cta_link {
		pointer-events: auto;
	}

	&-wrapper {
		@include g-banner_3(_wrapper);
	}

	&.m-left &-wrapper {
		@include g-banner_3(_align_left);
	}

	&.m-right &-wrapper {
		@include g-banner_3(_align_right);
	}

	&.m-black &-wrapper {
		@include g-banner_3(_text_black);
	}

	&.m-bottom &-wrapper {
		@include g-banner_3(_align_bottom_wrapper);
	}

	&.m-separated {
		@include g-banner_3(_separated);
	}

	&.m-separated &-wrapper {
		@include g-banner_3(_separated_wrapper);
	}

	&-main {
		@include g-banner_3(_main);
	}

	&-secondary {
		@include g-banner_3(_secondary);
	}

	&-text_block {
		@include g-banner_3(_text_block);
	}

	&-cta {
		@include g-banner_3(_cta);
	}

	&-cta_link {
		@include g-banner_3(_cta_link);
	}

	&.m-black &-cta_link {
		@include g-banner_3(_link_black);
	}
}
```
*/

@mixin g-banner_3($el: root) {
	@if $el == root {
		display: grid;
		grid-template-areas: 'contents';
		height: 100%;
	}

	@if $el == _image {
		align-self: stretch;
		grid-area: contents;
		object-fit: cover;
		width: 100%;
	}

	@if $el == _image_link {
		align-self: stretch;
		display: flex;
		grid-area: contents;
		object-fit: none;
	}

	@if $el == _image_link_pic {
		grid-area: auto;
	}

	@if $el == _wrapper {
		align-self: center;
		color: color(light);
		grid-area: contents;
		padding: rh(13 17);
		text-align: center;

		@include media(s) {
			padding: rh(22 5);
		}
	}

	@if $el == _align_left {
		text-align: left;
	}

	@if $el == _align_right {
		text-align: right;
	}

	@if $el == _text_black {
		color: color(dark);
	}

	@if $el == _link_black {
		border-bottom: 1px solid color(dark);
		color: color(dark);
	}

	@if $el == _main {
		@include font(bold, 30px, 1.3);

		@include media(s) {
			font-size: 28px;
		}
	}

	@if $el == _secondary {
		@include font(medium, 16px, 1.4);

		margin-top: rh(2);
	}

	@if $el == _text_block {
		display: inline-block;
	}

	@if $el == _align_bottom_wrapper {
		align-self: flex-end;
	}

	@if $el == _separated {
		display: flex;
		flex-direction: column;
	}

	@if $el == _separated_wrapper {
		padding: rh(9 7);
	}

	@if $el == _cta {
		margin-top: rh(7);
		text-align: center;
	}

	@if $el == _cta_link {
		@include font(medium, 14px);

		border-bottom: 1px solid color(light);
		color: color(light);
		text-transform: uppercase;
	}

	@if $el == _sticky_text {
		padding-left: rh(2);
		padding-right: rh(2);
	}
}
