/*md
# Video

HTML structure example:

## Video component with placeholder image, aspect ratio 16:9 (by default)

```html_example
<div class="b-video" data-component="clp/ClpVideo" data-component-options='{"youtubeID": "FMqADdsVmwQ"}' >
	<div data-js-video-player></div>
	<img class="b-video-placeholder" src="https://img.youtube.com/vi/FMqADdsVmwQ/0.jpg" data-js-video-image />
</div>
```

## Video component with placeholder image, aspect ratio 4:3

```html_example
<div class="b-video m-ratio43" data-component="clp/ClpVideo" data-component-options='{"youtubeID": "FMqADdsVmwQ"}' >
	<div data-js-video-player></div>
	<img class="b-video-placeholder" src="https://img.youtube.com/vi/FMqADdsVmwQ/0.jpg" data-js-video-image />
</div>
```
*/

.b-video {
	@include g-video(root);

	&.m-ratio43 {
		@include g-video(root, ratio43);
	}

	&.m-ratio219 {
		@include g-video(root, ratio219);
	}

	&.m-playing {
		@include g-video(root, playing);
	}

	&-placeholder {
		@include g-video(_placeholder);
	}

	&.m-playing &-placeholder {
		@include g-video(_placeholder, playing);
	}

	> iframe {
		@include g-video(_iframe);
	}

	&_player {
		max-height: 100%;
		width: 100%;
	}
}
