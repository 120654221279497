/*md
# Simple global grey message

## HTML

```html
<div class="b-message">
	Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit deleniti similique reiciendis deserunt a quaerat!
</div>
```

## Styles

```scss
	.b-message {
		@include g-message;
	}
```

## Example

```html_example
<div class="b-message">
	Lorem ipsum dolor, sit amet consectetur adipisicing elit. Fugit deleniti similique reiciendis deserunt a quaerat!
</div>
```
*/

.b-message {
	@include g-message;
}
