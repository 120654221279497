.b-footer_column {
	text-align: left;

	@include media(m-up) {
		max-width: 360px;
		padding-right: rh(4);
	}

	@include media(l-up) {
		width: 25%;
	}

	@include media(m) {
		width: 30%;
	}

	@include media(s) {
		border-bottom: 1px solid color(lighter5);
		padding: 0 rh(5);
	}

	&-title {
		$mobileIndent: rh(5);

		@include font(medium, 14px);

		text-transform: uppercase;

		@include media(m-up) {
			margin-bottom: rh(6);
		}

		@include media(s) {
			@include g-accordion(_control);

			font-size: 12px;
			line-height: 48px;
			margin-left: -$mobileIndent;
			margin-right: -$mobileIndent;
			padding: 0 $mobileIndent;

			&::after {
				right: $mobileIndent;
			}

			&.m-accordion-expanded {
				@include g-accordion(_control_expanded);

				margin-bottom: rh(3);
			}
		}
	}

	&-content {
		@include media(s) {
			@include g-accordion(_content);

			&.m-accordion-expanded {
				@include g-accordion(_content_expanded);

				margin-bottom: rh(3);
			}
		}
	}

	&-item {
		@include font(regular, 12px);

		@include media(s) {
			margin-bottom: rh(6);
		}

		& + & {
			@include media(m-up) {
				margin-top: rh(5);
			}
		}
	}

	&-link {
		@include link(regular) {
			font-size: 12px;
		}
	}
}
