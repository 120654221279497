/*md
@no-stat
# Fields group

## HTML structure example:

```
<div class="f-field_group">
	// <f-field> here
	<!-- Optional -->
	<div class="f-field_group-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<!-- Optional -->
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### The one element per the row

```html_example
<div class="f-field_group m-block">
	<div class="f-field">
		<label class="f-input_text">
			<span class="f-input_text-label">First Name</span>
			<input class="f-input_text-field" type="text" name="name" maxlength="50">
		</label>
		<div class="f-field-error">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field">
		<label class="f-input_email">
			<span class="f-input_email-label">Email</span>
			<input class="f-input_email-field" type="email" name="email">
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
</div>
```

### Two elements per the row

```html_example
<div class="f-field_group m-gaps">
	<div class="f-field">
		<label class="f-input_password">
			<span class="f-input_password-label">Old password</span>
			<input class="f-input_password-field" type="password" name="password">
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field">
		<label class="f-input_password">
			<span class="f-input_password-label">New password</span>
			<input class="f-input_password-field" type="password" name="password2">
		</label>
		<div class="f-field-error">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
</div>
```

### Two element in the one row and the one element in the one row

```html_example
<div class="f-field_group m-gaps">
	<div class="f-field">
		<label class="f-input_text">
			<span class="f-input_text-label">First Name</span>
			<input class="f-input_text-field" type="text" name="name2" maxlength="50">
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field">
		<label class="f-input_text">
			<span class="f-input_text-label">Middle Name</span>
			<input class="f-input_text-field" type="text" name="name3" maxlength="50">
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field m-full">
		<label class="f-input_text">
			<span class="f-input_text-label">Surname</span>
			<input class="f-input_text-field" type="text" name="name4" maxlength="50">
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
</div>
```

### Three element per the row

```html_example
<div class="f-field_group m-gaps">
	<div class="f-field">
		<label class="f-select">
			<span class="f-select-label">Choose a day</span>
			<span class="f-select-element_wrapper" required>
				<select class="f-select-element">
					<option value="01">01</option>
					<option value="02">02</option>
					<option value="03">03</option>
				</select>
			</span>
		</label>
		<div class="f-field-error">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field">
		<label class="f-select">
			<span class="f-select-label">Choose a month</span>
			<span class="f-select-element_wrapper">
				<select class="f-select-element" required>
					<option value="January">January</option>
					<option value="February">February</option>
					<option value="March">March</option>
				</select>
			</span>
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
	<div class="f-field">
		<label class="f-select">
			<span class="f-select-label">Choose a year</span>
			<span class="f-select-element_wrapper">
				<select class="f-select-element" required>
					<option value="1997">1997</option>
					<option value="1998">1998</option>
					<option value="1999">1999</option>
				</select>
			</span>
		</label>
		<div class="f-field-error h-hidden">
			<span class="f-field-error_text">The field has invalid value</span>
		</div>
	</div>
</div>
```

### Three elements per row with a single label and a single error message

```html_example
<div class="f-field_group m-gaps">
	<div class="f-field_group-caption">
		Choose a date
	</div>
	<div class="f-field">
		<span class="f-select-element_wrapper">
			<select class="f-select-element">
				<option value="1997">1997</option>
				<option value="1998">1998</option>
				<option value="1999">1999</option>
			</select>
		</span>
	</div>
	<div class="f-field">
		<span class="f-select-element_wrapper">
			<select class="f-select-element">
				<option value="01">01</option>
				<option value="02">02</option>
				<option value="03">03</option>
			</select>
		</span>
	</div>
	<div class="f-field">
		<span class="f-select-element_wrapper">
			<select class="f-select-element">
				<option value="January">January</option>
				<option value="February">February</option>
				<option value="March">March</option>
			</select>
		</span>
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### Two elements per the row without a gap

```html_example
<div class="f-field_group">
	<div class="f-field">
		<label class="f-input_text">
			<input
				type="text"
				class="f-input_text-field"
				name="couponCode"
				placeholder="Enter your code">
		</label>
	</div>
	<div class="f-field m-content">
		<button type="submit" class="b-button_1">Apply</button>
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the two checkbox in a line, the caption and the one error

```html_example
<div class="f-field_group m-row">
	<div class="f-field">
		<label class="f-input_checkbox">
			<input class="f-input_checkbox-field" type="checkbox">
			<span class="f-input_checkbox-label">I have a car</span>
		</label>
	</div>
	<div class="f-field">
		<label class="f-input_checkbox">
			<input class="f-input_checkbox-field" type="checkbox">
			<span class="f-input_checkbox-label">I don't have a car</span>
		</label>
	</div>
	<div class="f-field_group-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the two checkbox in a row, the caption and the one error

```html_example
<div class="f-field_group m-block">
	<div class="f-field">
		<label class="f-input_checkbox">
			<input class="f-input_checkbox-field" type="checkbox">
			<span class="f-input_checkbox-label">I have a car</span>
		</label>
	</div>
	<div class="f-field">
		<label class="f-input_checkbox">
			<input class="f-input_checkbox-field" type="checkbox">
			<span class="f-input_checkbox-label">I don't have a car</span>
		</label>
	</div>
	<div class="f-field_group-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the radio in a line, the caption and the one error

```html_example
<div class="f-field_group m-row">
	<div class="f-field">
		<label class="f-input_radio">
			<input class="f-input_radio-field" type="radio" name="gender1" value="male" checked>
			<span class="f-input_radio-label">Male</span>
		</label>
	</div>
	<div class="f-field">
		<label class="f-input_radio">
			<input class="f-input_radio-field" type="radio" name="gender1" value="female">
			<span class="f-input_radio-label">Female</span>
		</label>
	</div>
	<div class="f-field_group-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the radio in a row, the caption and the one error

```html_example
<div class="f-field_group m-block">
	<div class="f-field">
		<label class="f-input_radio">
			<input class="f-input_radio-field" type="radio" name="gender2" value="male" checked>
			<span class="f-input_radio-label">Male</span>
		</label>
	</div>
	<div class="f-field">
		<label class="f-input_radio">
			<input class="f-input_radio-field" type="radio" name="gender2" value="female">
			<span class="f-input_radio-label">Female</span>
		</label>
	</div>
	<div class="f-field_group-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field_group-error">
		<span class="f-field_group-error_text">The field has invalid value</span>
	</div>
</div>
```
*/

.f-field_group {
	$field-indent: rh(4);

	display: flex;
	flex-wrap: wrap;

	&-error,
	&-caption {
		width: 100%;
	}

	&-error {
		@include g-error;
	}

	&-optional_copy {
		@include f-input_label(_optional_copy);
	}

	&-caption {
		@include f-input_label;

		text-align: left;

		&.m-required {
			@include f-input_label($mod: required);
		}

		&.birthday-group_caption {
			color: var(--color-lighter2);
			font-family: 'Sweet Sans', 'Arial', sans-serif;
			font-size: 14px;
			font-weight: lighter;
			line-height: normal;
			text-align: center;
		}
	}

	&-error_text {
		display: block;
		margin-top: $field-indent;

		&:empty {
			margin-top: 0;
		}
	}

	.f-field {
		flex-grow: 1;

		&.m-content {
			display: flex;
			flex-grow: 0;
		}

		&.m-full {
			width: 100%;
		}

		&:not(.m-last) {
			margin-bottom: $field-indent;
		}
	}

	&.m-gaps {
		margin: rh(0 -2);

		.f-field,
		.f-field_group-caption {
			padding: rh(0 2);

			@include media(s) {
				padding: rh(0 0.5);
			}
		}
	}

	&.m-row {
		.f-field {
			flex-grow: 0;
			margin-right: $field-indent;
		}
	}

	&.m-block {
		display: block;

		.f-field:not(.m-last) {
			margin-bottom: $field-indent;
		}
	}

	&.birthday-group-container {
		margin: 30px auto 20px;
		max-width: 400px;
	}
}
