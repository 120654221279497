.b-hero_slider {
	--tns-controls-indent: 35px;

	@include media(m) {
		--tns-controls-indent: 25px;
	}

	@include media(s) {
		--tns-controls-indent: 20px;
	}

	.tns-nav {
		bottom: 0;
		height: auto;
		left: 0;
		padding: rh(6 0);
		position: absolute;
		width: 100%;
	}

	.tns-nav > [aria-controls] {
		background: rgba(255, 255, 255, 0.7);
		height: 9px;
		width: 9px;
	}

	.tns-outer > [data-action] {
		display: none;
	}

	.tns-nav .tns-nav-active {
		background: color(dark);
	}

	.tns-controls [data-controls='prev'] {
		@include icon(arrow-left-thin);

		left: var(--tns-controls-indent);
	}

	.tns-controls [data-controls='next'] {
		@include icon(arrow-right-thin);

		right: var(--tns-controls-indent);
	}

	.tns-controls [aria-controls] {
		font-size: 30px;
	}

	.tns-item a {
		cursor: pointer;
	}

	.b-banner_1.m-right .b-banner_1-wrapper {
		@include site-width;

		width: 100%;

		@include media(m) {
			padding: rh(19);
		}

		@include media(s) {
			justify-self: center;
			margin: 0;
			padding-bottom: 57px;
			text-align: center;
		}
	}

	.b-banner_1-cta_link {
		@include media(s) {
			@include g-button_1;

			display: inline-block;
			margin: rh(10 0 0);
			width: auto;
		}
	}
}
