/*md
# Image tile banner

Image tile with a label

<style>
	.g-image_tile {
		max-width: 300px;
	}
</style>

```html_example
<a href="#" class="g-image_tile">
	<img class="g-image_tile-image" src="//placehold.it/600x600" alt="" />
	<span class="g-image_tile-label">LABEL</span>
</a>
```
*/

.g-image_tile {
	@include g-image_tile;

	&-image {
		@include g-image_tile(_image);
	}

	&-label {
		@include g-image_tile(_label);
	}
}
