/*md
# Button component

This component is used to style a button

### HTML structure example:
```
<button class="b-button_revolution_beauty">Revolution Beauty</button>
```

### SCSS structure example:
```
.b-button_revolution_beauty {
	@include g-button;
}
```
*/

@mixin g-button {
	@include font(medium, 14px, 1.2);

	background-color: none;
	border: 1px solid color(dark);
	cursor: pointer;
	display: block;
	outline: none;
	padding: 11px rh(5); // Styleguide magic number;
	text-align: center;
	text-transform: uppercase;
	transition: background-color 0.2s, border-color 0.2s;
	width: 100%;

	&:disabled,
	&.m-disabled,
	&:disabled:hover,
	&.m-disabled:hover {
		background-color: color(lighter5);
		border-color: color(lighter5);
		color: color(lighter3);
	}
}
