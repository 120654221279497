/*md
@no-stat
# Fields

## HTML structure example:

```
<div class="f-field">
	// Form element here
	<!-- Optional -->
	<div class="f-field-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<!-- Optional -->
	<div class="f-field-error h-hidden">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```

### Default field

```html_example
<div class="f-field">
	<label class="f-input_text">
		<span class="f-input_text-label">First Name</span>
		<input class="f-input_text-field" type="text" name="name" value="" maxlength="50" required>
	</label>
	<div class="f-field-error h-hidden">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the caption

```html_example
<div class="f-field">
	<label class="f-input_text">
		<span class="f-input_text-label">First Name</span>
		<input class="f-input_text-field" type="text" name="name" value="" maxlength="50" required>
	</label>
	<div class="f-field-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field-error h-hidden">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the one error

```html_example
<div class="f-field">
	<label class="f-input_text m-error">
		<span class="f-input_text-label">First Name</span>
		<input class="f-input_text-field" type="text" name="name" value="" maxlength="50" required>
	</label>
	<div class="f-field-error">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with errors

```html_example
<div class="f-field">
	<label class="f-input_text m-error">
		<span class="f-input_text-label">First Name</span>
		<input class="f-input_text-field" type="text" name="name" value="" maxlength="50" required>
	</label>
	<div class="f-field-error">
		<span class="f-field-error_text">This field is required</span>
		<span class="f-field-error_text">The field has invalid value</span>
		<span class="f-field-error_text">Please fix this field</span>
	</div>
</div>
```

### Field with the caption and the one error

```html_example
<div class="f-field">
	<label class="f-input_text">
		<span class="f-input_text-label">First Name</span>
		<input class="f-input_text-field" type="text" name="name" value="" maxlength="50" required>
	</label>
	<div class="f-field-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field-error">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```

### Field with the one checkbox, the caption and the one error

```html_example
<div class="f-field">
	<label class="f-input_checkbox">
		<input class="f-input_checkbox-field" type="checkbox">
		<span class="f-input_checkbox-label">I have a car</span>
	</label>
	<div class="f-field-caption">
		Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsum, quia!
	</div>
	<div class="f-field-error">
		<span class="f-field-error_text">The field has invalid value</span>
	</div>
</div>
```
*/

.f-field {
	text-align: left;

	&-error_text,
	&-caption {
		@include font(regular, 12px);

		margin-top: rh(3);
	}

	&-caption {
		color: color(lighter2);

		&.birthday-group_caption {
			color: var(--color-lighter2);
			font-family: 'Sweet Sans', 'Arial', sans-serif;
			font-size: 14px;
			font-weight: lighter;
			line-height: normal;
			text-align: center;
		}
	}

	&-error {
		color: color(red);
	}

	&-error_text {
		display: block;

		&:empty {
			margin-top: 0;
		}
	}

	&.m-cvv .f-input_password-field {
		background: url('../../images/payments/cvv.png') calc(100% - 20px) 12px no-repeat;
		padding-right: rh(11);
	}

	&.card-number-wrapper {
		position: relative;

		&::after {
			background-image: url('../../images/payments/sprite.png');
			background-repeat: no-repeat;
			content: '';
			height: 22px;
			position: absolute;
			right: 20px;
			top: 40px;
		}

		&[data-type='visa']::after {
			background-position: -135px -110px;
			width: 39px;
		}

		&[data-type='mastercard']::after {
			background-position: -114px -152px;
			width: 29px;
		}

		&[data-type='amex']::after {
			background-position: -64px -152px;
			width: 30px;
		}

		&[data-type='maestro']::after {
			background-position: -163px -152px;
			width: 28px;
		}
	}

	&.birthday-group {
		margin-right: 15px;
	}
}
