/*md
# Button control

Simple CTA Button

```html_example
<button class="b-button_control">Customer Service</button>
```
*/

.b-button_control {
	@include g-button-control;
}
