/*md
# Hero With Sidebox

Image banner with text & cta inside a box on the right/left side.

## Example #1. 16x9

```html_example
<figure class="b-hero_sidebox m-black m-blended m-pride_bigtext m-separated_mobile m-rightinfo" style="--urlproud: url(//placehold.it/1920x1080)" data-js-animatable>
	<picture class="b-hero_sidebox-image">
		<source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
		<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
		<img src="//placehold.it/1920x1080" alt="image">
	</picture>

	<figcaption class="b-hero_sidebox-wrapper">
		<img class="b-hero_sidebox-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$"  alt="logo"/>

        <p class="b-hero_sidebox-main">
            <span class="b-hero_sidebox-text_block ml12">PRIDE</span>
        </p>
        <p class="b-hero_sidebox-main">
            <span class="b-hero_sidebox-pride_subheading ml14">WE ARE PROUD</span>
        </p>
        <p class="b-hero_sidebox-secondary">
            <span class="b-hero_sidebox-text_block_copy">Meet the collection made with pride in mind</span>
        </p>

        <span class="b-button_3">Button type 2</span>
    </figcaption>

</figure>
```

## Example #2. 16x9 flipped on x-axis.

```html_example
<figure class="b-hero_sidebox m-black m-blended m-pride_bigtext m-separated_mobile m-leftinfo"  data-js-animatable>
	<picture class="b-hero_sidebox-image">
		<source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
		<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
		<img src="//placehold.it/1920x1080" alt="image">
	</picture>

	<figcaption class="b-hero_sidebox-wrapper">
		<img class="b-hero_sidebox-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/revolution_makeup_svg.svg?$staticlink$" alt="logo"/>

        <p class="b-hero_sidebox-main">
            <span class="b-hero_sidebox-text_block ml12">PRIDE</span>
        </p>
        <p class="b-hero_sidebox-main">
            <span class="b-hero_sidebox-pride_subheading ml14">WE ARE PROUD</span>
        </p>
        <p class="b-hero_sidebox-secondary">
            <span class="b-hero_sidebox-text_block_copy">Meet the collection made with pride in mind</span>
        </p>

        <span class="b-button_3">Button type 2</span>
    </figcaption>

</figure>
```

*/

.b-hero_sidebox {
	display: grid;
	grid-template-areas: 'contents';
	height: 100%;
	overflow: hidden;

	&-image {
		align-self: stretch;
		grid-area: contents;
		object-fit: cover;
		transition: 0.7s;
		width: 100%;
	}

	&-text_block {
		display: inline-block;
	}

	&-main {
		@include font(bold, 32px, 1.3);

		font-variant: small-caps;

		@include media(s) {
			margin-bottom: 0;
		}
	}

	&-text_block_copy {
		@include font(medium);

		color: color(light);
		display: inline-block;
		font-size: 16px;
		position: relative;

		@include media(m) {
			padding: 15px 10%;
		}

		@include media(s) {
			max-width: 270px;
		}
	}

	&-image_link {
		align-self: stretch;
		display: flex;
		grid-area: contents;
		object-fit: none;
	}

	&-image_link &-image {
		grid-area: auto;
	}

	&-cta_link {
		@include font(medium, 14px);

		color: color(light);
		text-transform: uppercase;

		&::after {
			border-bottom: solid 1px color(light);
			content: '';
			display: block;
			padding-bottom: 1px;
			transform: scaleX(0.5);
			transition: transform 500ms ease-in-out;
		}

		&:hover::after {
			transform: scaleX(1);
			transition: transform 500ms ease-in-out;
		}
	}

	&-cta_button {
		@include g-button_1;

		margin: 0 auto;
		max-width: 300px;
	}

	&-wrapper {
		align-self: center;
		color: color(light);
		grid-area: contents;
		padding: rh(13 17);
		position: relative;
		text-align: center;

		@include media(s) {
			padding: rh(22 5);
		}
	}

	&-secondary {
		@include font(medium, 16px, 1.4);

		margin-bottom: rh(8);
	}

	&-svg_logo {
		align-self: center;
		grid-area: contents;
		margin: 0 auto rh(8);
		max-width: 200px;
		padding: 0;
		position: relative;
	}

	&.m-left &-wrapper {
		text-align: left;
	}

	&.m-right &-wrapper {
		text-align: right;
	}

	&.m-black &-wrapper {
		color: color(dark);
	}

	&.m-blended &-wrapper {
		padding: rh(15 2);
		width: 29%;

		@include media(m) {
			width: 33%;
		}

		@include media(s) {
			margin-right: 0;
			width: 100%;

			&::after {
				content: var(--urlproud);
				height: 100%;
				left: 0;
				mix-blend-mode: multiply;
				object-fit: cover;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		&::before {
			background-color: color(pride1);
			content: '';
			height: 100%;
			left: 0;
			mix-blend-mode: multiply;
			position: absolute;
			top: 0;
			width: 100%;
		}
	}

	&.m-leftinfo &-wrapper {
		justify-self: left;
		margin-left: rh(13);

		@include media(s) {
			margin-left: 0;
		}
	}

	&.m-rightinfo &-wrapper {
		justify-self: right;
		margin-right: rh(13);

		@include media(s) {
			margin-right: 0;
		}
	}

	&.m-bottom &-wrapper {
		align-self: flex-end;
		margin-bottom: rh(3);
	}

	&.m-separated {
		display: flex;
		flex-direction: column;
	}

	&.m-separated_mobile {
		@include media(s) {
			display: flex;
			flex-direction: column;
		}
	}

	&.m-separated &-wrapper {
		padding: rh(7);
	}

	&-small_text {
		@include font(regular, 12px, 1.7);

		margin: 0 auto;
		padding-top: rh(2);
		width: 70%;
	}

	&.m-pride_bigtext &-text_block {
		@include font(bold);

		color: color(light);
		font-size: 87px;
		font-variant: small-caps;
		line-height: 1;
		margin-left: -100%;
		margin-right: -100%;
		position: relative;
		text-align: center;

		@include media(m) {
			font-size: 60px;
		}

		@include media(s) {
			font-size: 75px;
		}
	}

	&-pride_subheading {
		color: color(light);
		display: inline-block;
		font-size: 34px;
		font-variant: small-caps;
		line-height: 1;
		margin-bottom: rh(5);
		margin-left: -100%;
		margin-right: -100%;
		position: relative;
		text-align: center;

		@include media(m) {
			font-size: 23px;
		}

		@include media(s) {
			font-size: 29px;
		}
	}

	&-cta {
		display: inline-block;
		margin-top: rh(7);
		text-align: center;
	}

	&.m-black &-cta_link {
		color: color(dark);
	}

	&.m-sticky_text &-wrapper {
		padding-left: rh(2);
		padding-right: rh(2);
	}

	&.m-mb_mobile {
		@include media(s) {
			margin-bottom: rh(8);
		}
	}
}
