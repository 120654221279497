/*md
# Badges

## Mixin configuration:

- @param `$bg` {string} The background color of the badge, can be also `red`; **default value**: `dark`

- @param `$type` {string} The type of the badge, can be: `pdp`, `plp`, `cart`; **default value**: `pdp`

## Simple badge HTML example:

```
<div class="g-badge">
	<span>bonus</span>
</div>
```

## Multiline badge HTML example:

```
<div class="g-badge">
	<span>SEASON</span><span>Sale</span>
</div>
```

## SCSS usage example:
```
.g-badge {
	@include g-badge;
}
```
```
*/

@mixin g-badge($bg: dark, $type: pdp) {
	$height: null;
	$width: null;
	$mobileWidth: null;
	$mobileHeight: null;
	$fontSize: null;
	$mobileFontSize: null;
	$mlFontSize: null;
	$mlFontSizeMobile: null;
	$mlFontSizeFirst: null;
	$mlFontSizeFirstMobile: null;

	@if $type == plp {
		$height: 30px;
		$width: 95px;
		$mobileWidth: 66px;
		$mobileHeight: 25px;
		$fontSize: 14px;
		$mobileFontSize: 11px;
		$mlFontSize: 14px;
		$mlFontSizeMobile: 11px;
		$mlFontSizeFirst: 10px;
		$mlFontSizeFirstMobile: 7.5px;
	} @else if $type == cart {
		$height: 50px;
		$width: 50px;
		$mobileWidth: 50px;
		$mobileHeight: 50px;
		$fontSize: 10px;
		$mobileFontSize: 10px;
		$mlFontSize: 10px;
		$mlFontSizeMobile: 10px;
		$mlFontSizeFirst: 7.5px;
		$mlFontSizeFirstMobile: 5px;
	} @else {
		$height: 30px;
		$width: 70px;
		$mobileWidth: 65px;
		$mobileHeight: 65px;
		$fontSize: 14px;
		$mobileFontSize: 14px;
		$mlFontSize: 18px;
		$mlFontSizeMobile: 14px;
		$mlFontSizeFirst: 10px;
		$mlFontSizeFirstMobile: 10px;
	}

	background-color: color($bg);
	color: color(light);
	display: flex;
	flex-direction: column;
	height: $height;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
	width: $width;

	@include media(s) {
		height: $mobileHeight;
		width: $mobileWidth;
	}

	> span {
		display: block;

		&:first-child {
			@include font(medium, $mlFontSizeFirst);

			@include media(s) {
				@include font(medium, $mlFontSizeFirstMobile);
			}
		}

		&:last-child {
			@include font(bold, $mlFontSize);

			@include media(s) {
				font-size: $mlFontSizeMobile;
			}
		}

		&:first-child:last-child {
			@include font(bold, $fontSize);

			@include media(s) {
				font-size: $mobileFontSize;
			}
		}
	}
}
