/*md
# Titles

Here is all titles you can use inside your content assets.

Additionaly you can add ```m-center``` class to center align title's text

```html_example
	<div class="t-title-1">Title 1</div>
	</br>
	</br>
	<div class="t-title-2">Title 2</div>
	</br>
	</br>
	<div class="t-title-3">Title 3</div>
	</br>
	</br>
	<div class="t-title-4">Title 4</div>
	</br>
	</br>
	<div class="t-title-5">Title 5</div>
	</br>
	</br>
	<div class="t-title-blog">Title blog</div>
```
*/

.t-title-1 {
	@include t-title-1;
}

.t-title-2 {
	@include t-title-2;
}

.t-title-3 {
	@include t-title-3;
}

.t-title-4 {
	@include t-title-4;
}

.t-title-5 {
	@include t-title-5;
}

.t-title-blog {
	@include t-title-blog;
}

.t-title-1,
.t-title-2,
.t-title-3,
.t-title-4,
.t-title-5,
.t-title-blog {
	&.m-center {
		text-align: center;
	}
}

.t-title-loyalty {
	@include t-title-loyalty;
}
