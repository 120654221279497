/*md
# Info Tabs

Tabs with gray-shaded controls

## Layout Example:
```html_example
<div class="g-tabs_info" data-component="global/Tabs">
	<div class="g-tabs_info-head">
		<ul class="g-tabs_info-list" data-js-tabs>
			<li class="g-tabs_info-control m-tabs-active" data-js-tab-item="1">
				<span class="g-tabs_info-control_name">Tab 1</span>
			</li>

			<li class="g-tabs_info-control" data-js-tab-item="2">
				<span class="g-tabs_info-control_name">Tab 2</span>
			</li>

			<li class="g-tabs_info-control" data-js-tab-item="3">
				<span class="g-tabs_info-control_name">Tab 3</span>
			</li>
		</ul>
	</div>

	<div class="g-tabs_info-content" data-js-tabs-content>
		<div class="g-tabs_info-item m-tabs-shown" data-js-tab-content="1">Tab 1 contents</div>

		<div class="g-tabs_info-item" data-js-tab-content="2">Tab 2 contents</div>

		<div class="g-tabs_info-item" data-js-tab-content="3">Tab 3 contents</div>
	</div>
</div>
```
*/

.g-tabs_info {
	@include g-tabs_info;

	&-head {
		@include g-tabs_info(_head);
	}

	&-list {
		@include g-tabs_info(_list);
	}

	&-control {
		@include g-tabs_info(_control);
	}

	&-control.m-tabs-active {
		@include g-tabs_info(_control_active);
	}

	&-control_name {
		@include g-tabs_info(_control_name);
	}

	&-content {
		padding: rh(15);
	}

	&-item {
		@include g-tabs_info(_item);
	}

	&-item.m-tabs-shown {
		@include g-tabs_info(_item_shown);
	}
}
