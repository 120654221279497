/*md
# Products Slider Banner

Carousel with annotation and rotation controls
located on the left (top for phones).
Contains a number of custom product tiles.

This widget has two versions: default and light.

## Default version

```html_example
<div class="b-products_slider" data-component="global/ProductsSlider">
	<div class="b-products_slider-annotation">
		<p class="b-products_slider-title">Title</p>

		<p class="b-products_slider-slogan">Slogan</p>

		<p class="b-products_slider-note">Lorem ipsum dolor sit amet</p>
	</div>

	<div class="b-products_slider-controls">
		<button class="b-products_slider-control_left" aria-label="Previous" data-js-product-carousel-control-left></button>

		<button class="b-products_slider-control_right" aria-label="Next" data-js-product-carousel-control-right></button>
	</div>

	<div class="b-products_slider-wrapper" data-js-product-carousel-swipe-zone>
		<ul class="b-products_slider-body" data-js-product-carousel-body>
			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
				</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>
		</ul>
	</div>
</div>
```

## Light version

In HTML code light version differs from the default one
only by adding `m-light` class to the root HTML element

```html_example
<div class="b-products_slider m-light" data-component="global/ProductsSlider">
	<div class="b-products_slider-annotation">
		<p class="b-products_slider-title">Title</p>

		<p class="b-products_slider-slogan">Slogan</p>

		<p class="b-products_slider-note">Lorem ipsum dolor sit amet</p>
	</div>

	<div class="b-products_slider-controls">
		<button class="b-products_slider-control_left" aria-label="Previous" data-js-product-carousel-control-left></button>

		<button class="b-products_slider-control_right" aria-label="Next" data-js-product-carousel-control-right></button>
	</div>

	<div class="b-products_slider-wrapper" data-js-product-carousel-swipe-zone>
		<ul class="b-products_slider-body" data-js-product-carousel-body>
			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>

			<li class="b-products_slider-item">
				<img src="//placehold.it/450x600&text=Product+tile" alt="Product tile placeholder" />
			</li>
		</ul>
	</div>
</div>
```
*/

.b-products_slider {
	background-color: color(lighter5);
	display: grid;
	grid-template: 1fr auto / 1fr 3fr calc(100vw - var(--content-width));
	grid-template-areas:
		'text      carousel  carousel'
		'controls  carousel  carousel';
	padding: rh(4 0 4 15);
	user-select: none;

	@include media(m) {
		padding-left: 34px; //34px equals to rh(8.5), the value is taken from basic_configuration.scss
	}

	@include media(s) {
		grid-template: 1fr auto auto / rh(6) 1fr;
		grid-template-areas:
			'. text controls'
			'carousel carousel carousel';
		padding: rh(0 0 4 2);
	}

	&-annotation {
		@include font(regular, 14px, 1.5);

		grid-area: text;
		padding-right: rh(22);
		padding-top: rh(5);
		text-align: left;

		@include media(s) {
			padding: rh(5 5 2 0);
			width: 100%;
		}
	}

	&-title {
		@include font(bold, 35px, 1.1);

		margin-bottom: rh(6);
		text-transform: uppercase;

		@include media(s) {
			@include font(bold, 24px);

			margin-bottom: rh(4);
		}
	}

	&-slogan {
		@include font(medium, 16px);

		margin-bottom: rh(2);

		@include media(s) {
			display: none;
		}
	}

	&-controls {
		color: color(modal_overlay);
		display: flex;
		grid-area: controls;
		margin-left: auto;
		padding: 12px;

		@include media(s) {
			margin-left: initial;
			padding: rh(5 5 3);
		}
	}

	&-control_left {
		@include g-button-control;
		@include icon(arrow-left-thin, before, 30px);

		padding-right: rh(3);

		&:focus {
			opacity: 0.7;
			outline: 0;
		}
	}

	&-control_right {
		@include g-button-control;
		@include icon(arrow-right-thin, after, 30px);

		padding-left: rh(3);

		&:focus {
			opacity: 0.7;
			outline: 0;
		}
	}

	&-wrapper {
		display: grid;
		grid-area: carousel;
		grid-template-areas: 'slides  .';
		grid-template-columns: auto calc(100vw - var(--content-width));
		overflow: hidden;
	}

	&-body {
		display: flex;
		grid-area: slides;
		height: 100%;
		transition: transform 0.5s ease;
	}

	&-item {
		flex: 0 0 274px;
		height: 100%;
		padding: rh(0 3);

		@include media(m-down) {
			flex-basis: 224px;
		}

		@include media(xl) {
			flex-basis: 344px;
		}

		@include media(s) {
			flex-basis: 314px;
			margin: 0 auto;
		}
	}

	.b-product_promo_tile {
		height: 100%;
	}

	&.m-light {
		background: color(light);

		.b-product_promo_tile {
			background-color: color(lighter6);
			box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.06);
		}
	}

	&.m-iheart {
		background: rgb(255, 206, 230);
	}

	&.m-obsession {
		background: rgb(255, 212, 79);
	}

	&.m-pro {
		background: rgba(243, 221, 212, 0.4);
	}

	&.m-makeup {
		background: rgb(250, 248, 248);
	}

	&.m-one_item {
		display: initial;
		grid-template: initial;
		margin: 0 auto;
		max-width: 677px;
		padding: 0;

		@include media(l) {
			max-width: 607px;
		}

		@include media(m) {
			max-width: 555px;
		}
	}

	&.m-two_items {
		margin: 0 auto;
		max-width: 1060px;

		@include media(l) {
			max-width: 924px;
		}

		@include media(m) {
			max-width: 794px;
		}
	}

	&.m-two_items &-controls {
		@include media(m-up) {
			display: none;
		}
	}

	&.m-one_item &-controls {
		display: none;
	}

	&.m-one_item &-wrapper {
		display: initial;
	}

	&.m-one_item &-body {
		justify-content: center;
	}

	&.m-one_item &-item {
		flex-basis: 400px;
		margin: 0 auto;

		@include media(s) {
			flex-basis: 320px;
		}
	}

	&.m-one_item &-annotation {
		padding-right: 0;

		@include media(s) {
			text-align: center;
		}
	}

	&.m-blog {
		margin-top: 22px;
	}

	&.m-blog .b-add_to_bag {
		margin-top: 12px;
	}

	&.m-blog .m-sticky {
		background: initial;
		border-top: initial;
		bottom: initial;
		left: initial;
		padding: initial;
		position: initial;
		right: initial;
		z-index: initial;
	}
}
