@mixin f-input_file {
	.f-input_file {
		&-field {
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		&-control {
			@include g-button_1;
		}

		&.m-disabled &-control {
			cursor: default;
		}

		&-label {
			@include f-input_label;
		}

		&.m-required &-label {
			@include f-input_label($mod: required);
		}

		&-optional_copy {
			@include f-input_label(_optional_copy);
		}

		&-label_description {
			@include f-input_label(_label_description);
		}

		&-label_link {
			@include f-input_label(_label_link);
		}
	}
}
