@mixin f-input_field($mod: default) {
	@if $mod == default {
		@include font(regular, 14px, 40px);

		appearance: none;
		background-color: transparent;
		border: 1px solid color(inputs_border);
		border-radius: 0;
		color: color(dark);
		outline: none;
		padding: 0 rh(5);
		transition: border-color 0.5s ease;
		width: 100%;

		&::-ms-clear { // remove IE11's "clear field" button
			display: none;
		}

		&:hover,
		&:focus {
			border-color: color(lighter3);
		}

		/* stylelint-disable property-no-vendor-prefix */
		&:-webkit-autofill { //change auto-filled background in Chrome
			box-shadow: 0 0 0 1000px color(light) inset;
		}

		&:-webkit-autofill ~ label {
			color: color(lighter3);
			font-size: 14px;
			top: 0;
		}
		/* stylelint-enable property-no-vendor-prefix */
	}

	@if $mod == error {
		border-color: color(error);
		color: color(error);
	}

	@if $mod == prefilled {
		border-color: color(lighter3);
	}

	@if $mod == disabled {
		background-color: color(lighter6);
		color: color(lighter3); // For iOS
		opacity: 1; // For iOS
		pointer-events: none;
		-webkit-text-fill-color: color(lighter3); // Override iOS

		&:hover,
		&:focus {
			&:disabled,
			&.m-disabled {
				border-color: color(inputs_border);
			}
		}
	}

	@if $mod == disabledcountry {
		opacity: 1; // For iOS
		pointer-events: none;

		&:hover,
		&:focus {
			&:disabled,
			&.m-disabled {
				border-color: color(inputs_border);
			}
		}
	}

	@if $mod == style_1 {
		border-left-width: 0;
		border-right-width: 0;
		border-top-width: 0;
		text-align: center;
	}
}

@mixin f-input_label($type: root, $mod: default) {
	@if $type == root {
		@if $mod == default {
			@include font(medium, 14px);

			display: block;
			margin-bottom: rh(4);
		}

		@if $mod == required {
			&::after {
				color: color(red);
				content: '*';
				display: inline-block;
				margin-left: 3px;
				vertical-align: middle;
			}
		}
	}

	@if $type == _optional_copy {
		@include font(medium, 12px, normal);

		color: color(lighter2);
	}

	@if $type == _label_description {
		@include font(regular, 12px, normal);

		color: color(lighter2);
		display: block;
		margin: rh(-2 0 3);

		a {
			color: color(dark);
			text-decoration: underline;

			&:hover {
				text-decoration: none;
			}
		}
	}

	@if $type == _label_link {
		color: color(dark);
		cursor: pointer;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}
