/*
	<div class="b-tabs"
		data-component="global/Tabs">

		<div class="b-tabs-head">
			<ul class="b-tabs-list" data-js-tabs>
				<li class="b-tabs-control" data-js-tab-item="1">
					<span class="b-tabs-control_name">Item 1 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="2">
					<span class="b-tabs-control_name">Item 2 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="3">
					<span class="b-tabs-control_name">Item 3 Title</span>
				</li>
				<li class="b-tabs-control" data-js-tab-item="4">
					<span class="b-tabs-control_name">Item 4 Title</span>
				</li>
			</ul>
		</div>

		<div class="b-tabs-content" data-js-tabs-content>
			<div class="b-tabs-item" data-js-tab-content="1">Item 1 Content</div>
			<div class="b-tabs-item" data-js-tab-content="2">Item 2 Content</div>
			<div class="b-tabs-item" data-js-tab-content="3">Item 3 Content</div>
			<div class="b-tabs-item" data-js-tab-content="4">Item 4 Content</div>
		</div>
	</div>
*/

//Functional Accordion styles

@mixin g-tabs(
	$_: root
) {
	@if $_ == root {}

	@if $_ == _list {
		display: flex;
	}

	@if $_ == _control {
		cursor: pointer;
		display: flex;
		flex-basis: 0;
		flex-grow: 1;
	}

	@if $_ == _control_name {
		pointer-events: none;
	}

	@if $_ == _control_active {
		cursor: default;
	}

	@if $_ == _item {
		display: none;
	}

	@if $_ == _item_shown {
		display: block;
	}
}

@mixin g-tabs_info(
	$_: root
) {
	@if $_ == root {
		background-color: color(lighter6);
	}

	@if $_ == _head {
		background-color: color(lighter5);
	}

	@if $_ == _list {
		@include g-tabs(_list);
	}

	@if $_ == _control {
		@include g-tabs(_control);
		@include font(medium, 14px, 1.2);

		background-color: color(transparent);
		color: color(lighter2);
		text-transform: uppercase;

		@include media(m) {
			@include font(medium, 12px);
		}
	}

	@if $_ == _control_name {
		@include g-tabs(_control_name);

		padding: 0 30px;
		text-align: center;
		width: 100%;
	}

	@if $_ == _control_active {
		@include g-tabs(_control_active);
		@include font(bold);

		background-color: color(lighter6);
		color: color(dark);
		transition: background-color 0.3s, color 0.3s linear;
	}

	@if $_ == _control_name {
		align-self: center;
		padding: rh(6 2);
		text-align: center;
		width: 100%;
	}

	@if $_ == _item {
		@include g-tabs(_item);
		@include font(regular, 14px, 1.2);
	}

	@if $_ == _item_shown {
		@include g-tabs(_item_shown);
	}
}
