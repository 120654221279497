/* stylelint-disable */
.b-ratings {
	.yotpo {
		div, span, p, a, img, i, strong, sup, ul, li, form, label,
		&.bottomLine .yotpo-bottomline .yotpo-icon-star,
		&.bottomLine .yotpo-bottomline .yotpo-icon-half-star,
		&.bottomLine .yotpo-bottomline .yotpo-icon-empty-star,
		&.bottomline .yotpo-bottomline .yotpo-icon-star,
		&.bottomline .yotpo-bottomline .yotpo-icon-half-star,
		&.bottomline .yotpo-bottomline .yotpo-icon-empty-star,
		.yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-star,
		.yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-half-star,
		.yotpo-modal-bottom-line .yotpo-bottomline .yotpo-icon-empty-star {
			font-size: 12px;
			line-height: 15px;
		}

		.standalone-bottomline.star-clickable,
		.standalone-bottomline .star-clickable {
			cursor: default;
		}

		.yotpo-icon-default-star,
		.yotpo-icon-star,
		.yotpo-icon-default-empty-star,
		.yotpo-icon-empty-star,
		.yotpo-icon-half-star {
			&::before {
				font-size: 15px;
				margin-left: rh(0.5);
			}
		}

		.yotpo-bottomline {
			.yotpo-icon-default-empty-star,
			.yotpo-icon-empty-star {
				color: color(lighter4);
			}

			.yotpo-icon-star,
			.yotpo-icon-half-star {
				color: color(dark);
			}
		}

		.text-m {
			@include font(regular, 10px);

			color: color(dark) !important;//to override yotpo style
			cursor: default !important;//to override yotpo style
			margin-left: rh(1);
		}
	}
}

.l-pdp_rating-wrapper {
	.yotpo {
		div, span, p, a, img, i, strong, sup, ul, li, form, label {
			@include font(regular, 14px);
		}

		.yotpo-default-button,
		input[type="button"].yotpo-default-button,
		.yotpo-default-button:hover,
		input[type="button"].yotpo-default-button:hover {
			background: color(dark);
		}

		&.yotpo-main-widget .yotpo-icon-btn,
		&-modal-dialog .yotpo-icon-btn {
			padding: 11px 20px;
		}

		&-review .yotpo-header .yotpo-user-name,
		&-comment .yotpo-header .yotpo-user-name,
		&-question .yotpo-header .yotpo-user-name,
		&-onsite-upload .yotpo-header .yotpo-user-name,
		.yotpo-nav ul .yotpo-nav-tab.yotpo-active span {
			font-weight: bold;
		}

		&-main-widget .yotpo-bottomline .based-on,
		&-modal-dialog .yotpo-bottomline .based-on,
		.yotpo-nav-dropdown span:not(.yotpo-icon) {
			text-transform: uppercase;
			color: color(lighter2);
		}

		&.yotpo-main-widget .yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2 .yotpo-star-distribution .yotpo-star-distribution-content .yotpo-distributions-graphs .yotpo-star-distribution-graph .yotpo-star-distribution-graph-background,
		.yotpo-modal-dialog .yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2 .yotpo-star-distribution .yotpo-star-distribution-content .yotpo-distributions-graphs .yotpo-star-distribution-graph .yotpo-star-distribution-graph-background {
			background-color: color(lighter5);
		}

		.yotpo-small-box {
			padding: 25px 0 0;
			border-color: color(lighter5);
		}

		&.yotpo-main-widget .yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2 .yotpo-star-distribution,
		.yotpo-modal-dialog .yotpo-bottomline.yotpo-bottomline-3-boxes .yotpo-bottomline-box-2 .yotpo-star-distribution,
		&-comments-box {
			border-color: color(lighter5);
		}

		.yotpo-regular-box {
			margin: 0;
			border-color: color(lighter5);
		}

		.yotpo-nav-dropdown .yotpo-icon {
			color: color(lighter2);
		}

		.yotpo-dropdown .list-category a {
			color: color(lighter2) !important;
		}

		.yotpo-dropdown .list-category:hover a {
			color: color(dark) !important;
		}

		.yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-answer,
		.yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-answer,
		.yotpo-question .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-answer,
		.yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-answer,
		.yotpo-review .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description,
		.yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description,
		.yotpo-question .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description,
		.yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-user-related-fields .yotpo-user-field .yotpo-user-field-description,
		.yotpo-review .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-answer,
		.yotpo-comment .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-answer,
		.yotpo-question .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-answer,
		.yotpo-onsite-upload .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-answer,
		.yotpo-review .yotpo-header .yotpo-header-element .y-label,
		.yotpo-comment .yotpo-header .yotpo-header-element .y-label,
		.yotpo-question .yotpo-header .yotpo-header-element .y-label,
		.yotpo-onsite-upload .yotpo-header .yotpo-header-element .y-label,
		.yotpo-review .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-description,
		.yotpo-comment .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-description,
		.yotpo-question .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-description,
		.yotpo-onsite-upload .yotpo-main .yotpo-multiple-rating-fields .yotpo-open-question-fields .yotpo-question-field .yotpo-question-field-description,
		.yotpo-nav ul .yotpo-nav-tab span,
		.y-label,
		span.y-label {
			font-size: 14px;
			color: color(lighter2);
		}

		.yotpo-review .yotpo-read-more,
		.yotpo-comment .yotpo-read-more,
		.yotpo-question .yotpo-read-more,
		.yotpo-onsite-upload .yotpo-read-more,
		.yotpo-review .yotpo-footer .yotpo-action:hover,
		.yotpo-comment .yotpo-footer .yotpo-action:hover,
		.yotpo-question .yotpo-footer .yotpo-action:hover,
		.yotpo-onsite-upload .yotpo-footer .yotpo-action:hover,
		.yotpo-review .yotpo-header .yotpo-header-element .y-label.yotpo-user-name,
		.yotpo-comment .yotpo-header .yotpo-header-element .y-label.yotpo-user-name,
		.yotpo-question .yotpo-header .yotpo-header-element .y-label.yotpo-user-name,
		.yotpo-onsite-upload .yotpo-header .yotpo-header-element .y-label.yotpo-user-name,
		.yotpo-review .yotpo-header .yotpo-review-stars .yotpo-icon,
		.yotpo-comment .yotpo-header .yotpo-review-stars .yotpo-icon,
		.yotpo-question .yotpo-header .yotpo-review-stars .yotpo-icon,
		.yotpo-onsite-upload .yotpo-header .yotpo-review-stars .yotpo-icon,
		.transparent-color-btn span.yotpo-icon {
			color: color(dark);
		}

		.yotpo-review .yotpo-read-more:hover,
		.yotpo-comment .yotpo-read-more:hover,
		.yotpo-question .yotpo-read-more:hover,
		.yotpo-onsite-upload .yotpo-read-more:hover {
			text-decoration: underline;
		}

		.yotpo-review .yotpo-main .content-title,
		.yotpo-comment .yotpo-main .content-title,
		.yotpo-question .yotpo-main .content-title,
		.yotpo-onsite-upload .yotpo-main .content-title,
		.yotpo-review .yotpo-main .content-review,
		.yotpo-comment .yotpo-main .content-review,
		.yotpo-question .yotpo-main .content-review,
		.yotpo-onsite-upload .yotpo-main .content-review,
		.transparent-color-btn.yotpo-disabled span,
		.transparent-color-btn.yotpo-disabled i,
		.transparent-color-btn.yotpo-disabled:hover span,
		.transparent-color-btn.yotpo-disabled:hover i {
			color: color(lighter2);
		}
	}

	.write-question-review-buttons-container .write-question-review-button .write-question-review-button-text,
	.yotpo.yotpo-main-widget .yotpo-icon-btn .yotpo-icon,
	.yotpo .yotpo-modal-dialog .yotpo-icon-btn .yotpo-icon {
		color: color(light) !important;
	}

	.write-question-review-buttons-container .write-question-review-button:hover {
		color: color(light) !important;
		background: color(dark) !important;
		border-color: color(dark) !important;
	}

	.write-question-review-buttons-container .write-question-review-button {
		border-color: color(dark);
	}

	.yotpo .yotpo-label-container {
		border-top: 0;
	}
}
/* stylelint-enable */
