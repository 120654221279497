.b-qty_selector {
	&-container {
		border: 1px solid color(dark);
		display: flex;
		justify-content: space-between;

		.l-pdp-product_info & {
			border-color: color(lighter2);
		}
	}

	&-control {
		@include icon(minus);

		cursor: pointer;

		&::before {
			font-size: 10px;
			line-height: 38px;
			margin: 0 25px;

			.l-pdp-product_info & {
				display: inline-block;
				font-size: 7px;
				margin: 0;
				text-align: center;
				width: 40px;
			}
		}

		&.m-plus::before {
			content: icon-char(plus);
		}
	}

	&-container.m-disabled {
		border-color: color(lighter5);
		color: color(lighter3);
	}

	&-container.m-disabled &-control,
	&-control.m-disabled {
		cursor: default;

		&::before {
			color: color(lighter4);
		}
	}

	&-label {
		@include font(medium, 14px, 38px);

		padding-left: rh(5);
		text-transform: uppercase;

		@include media(s) {
			padding-left: rh(4);
		}

		.l-pdp-product_info & {
			padding: 0;
		}
	}

	&-value {
		// stylelint-disable-next-line
		-moz-appearance: textfield;
		background: 0 0;
		border: 0;
		outline: 0;
		padding: 0;
		width: 20px;

		.l-pdp-product_info & {
			text-align: center;
		}
	}

	&-error {
		@include font(medium, 14px);

		color: color(red);
		margin-top: rh(1);

		.l-pdp-product_info & {
			margin-top: rh(2);
		}
	}
}
