/*md
# Parallax Image

Parallax image on scroll with image/button on top.

Options for parallax effect:
- delay: 0.3, // response delay in seconds
- orientation: 'left', // possibilities: up - right - down - left - up left - up right - down left - down right
- transition: 'cubic-bezier(0,0,0,1)', // any CSS transition
- scale: 1.2, // how much to parallax, also how much to zoom in to image
- overflow: false, // parallax outside/inside its frame
- breakpoint: 768, // don't parallax below this screen width

## Example #1. Logo in front of up/down parallax background:

```html_example
<figure class="g-banner_parallax" data-component="global/SimpleParallax" data-component-options='{
	"delay": 0.6,
	"orientation": "up",
	"transition": "cubic-bezier(0,0,0,1)",
	"scale": 1.5,
	"overflow": false,
	"breakpoint": 768}'
>
	<a class="g-banner_parallax-image_link" href="#!">
		<picture class="g-banner_parallax-image">
			<source class="thumbnail" media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
			<source media="(max-width: 767px)" srcset="//placehold.it/960x960">
			<img data-js-parallax src="//placehold.it/1920x1080" alt="image">
		</picture>
	</a>

	<figcaption class="g-banner_parallax-wrapper">
		<picture  class="g-banner_parallax-image">
			<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/OBSESSION_logo_LONDON-black.svg?$staticlink$">
			<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/OBSESSION_logo_LONDON-black.svg?$staticlink$">
			<img src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/OBSESSION_logo_LONDON-black.svg?$staticlink$" alt="logo">
		</picture>
		<span class="b-button_3 g-banner_parallax-image_link">Button type 2</span>
	</figcaption>
</figure>
```
*/

.g-banner_parallax {
	display: grid;
	grid-template-areas: 'contents';

	&-image {
		align-self: stretch;
		grid-area: contents;
		object-fit: cover;
		width: 100%;
	}

	&-image_link {
		align-self: stretch;
		display: flex;
		grid-area: contents;
		object-fit: none;
	}

	&-wrapper {
		grid-area: contents;
		margin: auto;
		text-align: center;
		transform: translateY(2px);
		width: 40%;

		@include media(s) {
			width: 70%;
		}
	}
}
