/*md
# Blogpost tile

Text tile banner with an image, a tiny content type label, a title and a read more CTA

## SCSS usage example:
```
.g-blogpost_tile {
	@include g-blogpost_tile;

	&-image {
		@include g-blogpost_tile(_image);
	}

	&-content {
		@include g-blogpost_tile(_content);
	}

	&-label {
		@include g-blogpost_tile(_label);
	}

	&-title {
		@include g-blogpost_tile(_title);
	}

	&-info {
		@include g-blogpost_tile(_info);
	}

	&-cta {
		@include g-blogpost_tile(_cta);
	}
}
```
*/

@mixin g-blogpost_tile($el: root) {
	@if $el == root {
		display: block;
		position: relative;
	}

	@if $el == _image {
		object-fit: cover;
		width: 100%;
	}

	@if $el == _content {
		margin-top: rh(6);
	}

	@if $el == _label {
		@include font(medium, 10px);

		color: color(lighter2);
		text-transform: uppercase;
	}

	@if $el == _title {
		@include font(bold, 24px, 1.2);

		margin: rh(3 0);
	}

	@if $el == _info {
		@include font(medium, 14px, 20px);

		margin: rh(3 0);
	}

	@if $el == _cta {
		@include font(medium, 14px, 1.4);

		border-bottom: 1px solid color(dark);
		display: inline-block;
		margin-bottom: rh(12);
		text-transform: uppercase;
		transition: border-bottom 0.2s;

		&:hover {
			border-bottom: 1px solid color(transparent);
		}
	}
}
