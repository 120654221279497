/*md
# Tables

```html_example
	<table class="t-table">
		<caption>Table caption</caption>
		<thead>
			<th>Table head cell 1</th>
			<th>Table head cell 2</th>
			<th>Table head cell 3</th>
		</thead>
		<tbody>
			<tr>
				<th>First column header cell</th>
				<td>XS</td>
				<td>S</td>
			</tr>
			<tr>
				<th>First column header cell</th>
				<td>XS</td>
				<td>S</td>
			</tr>
			<tr>
				<th>First column header cell</th>
				<td>XS</td>
				<td>S</td>
			</tr>
			<tr>
				<th>First column header cell</th>
				<td>XS</td>
				<td>S</td>
			</tr>
			<tr>
				<th>First column header cell</th>
				<td>XS</td>
				<td>S</td>
			</tr>
		</tbody>
	</table>
```
*/

.t-table,
%t-table {
	@include t-table;
}
