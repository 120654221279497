.b-bonus_popup {
	max-width: 900px;

	@include media(m) {
		max-width: 700px;
	}

	&-inner {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		max-height: 95vh;
		position: relative;

		@include media(m-down) {
			max-height: 85vh;
		}
	}

	&-title {
		@include font(bold, 22px);

		background-color: color(lighter6);
		border-bottom: 1px solid color(lighter5);
		flex-basis: 60px;
		padding: rh(5 0);
		text-transform: uppercase;

		@include media(s) {
			font-size: 18px;
			padding: rh(5 12);
		}
	}

	&-container {
		margin-right: rh(1);
		padding-right: rh(1.5);
		text-align: left;

		@include scrollbar {
			&::-webkit-scrollbar-button {
				height: 4px;
			}
		}
	}

	&-footer {
		align-items: center;
		background-color: color(lighter6);
		border-top: 1px solid color(lighter5);
		display: flex;
		flex-shrink: 0;
		padding: rh(7 10);

		@include media(s) {
			align-items: stretch;
			bottom: 0;
			flex-direction: column;
			left: 0;
			padding: rh(5);
			position: sticky;
			right: 0;
		}
	}

	&-summary,
	&-add_to_bag {
		@include media(m-up) {
			flex-basis: 50%;
		}
	}

	&-summary {
		@include media(l-up) {
			padding-right: rh(3);
		}
	}

	&-add_to_bag {
		width: 100%;

		@include media(m-up) {
			padding-left: rh(10);
		}
	}

	&-add_to_bag_button {
		@include g-button_1;
	}

	&-footer.m-added {
		@include media(m-up) {
			align-items: start;

			.b-bonus_summary-title {
				margin-bottom: rh(8);
			}
		}

		@include media(s) {
			.b-bonus_summary-title {
				@include g-dropdown(_control);

				justify-content: space-between;
				pointer-events: auto;
				position: relative;

				&::after {
					color: color(dark);
					line-height: 17px;
					top: 0;
				}
			}

			.b-bonus_summary-list {
				@include g-dropdown(_wrapper);
			}

			.b-bonus_summary.m-expanded .b-bonus_summary-title {
				@include g-dropdown(_control_expanded);

				border-bottom: 1px solid color(lighter5);
				padding-bottom: rh(3);
			}

			.b-bonus_summary.m-expanded .b-bonus_summary-list {
				border-bottom: 1px solid color(lighter5);
				margin-bottom: rh(5);
			}
		}
	}
}
