@mixin f-checkbox {
	.f-input_checkbox {
		display: inline-block;
		vertical-align: middle;

		&-label {
			@include font(medium, 12px, 16px);

			cursor: pointer;
			display: flex;

			@include icon(check) {
				align-items: center;
				background: color(light);
				border: 1px solid color(dark);
				color: color(light);
				display: flex;
				flex-shrink: 0;
				font-size: 10px;
				height: 16px;
				justify-content: center;
				margin-right: rh(2);
				width: 16px;
			}
		}

		&-field {
			clip: rect(1px, 1px, 1px, 1px);
			position: absolute;
		}

		&-field:checked + &-label::before {
			background: color(dark);
		}
	}
}
