///	Image Loader
///	@example
///		@include image_loader;

@mixin image_loader {
	background: url('../../images/loader.svg') no-repeat 50% 50%;
	background-size: contain;
}

@mixin image_container($type: root) {
	@if $type == root {
		padding-bottom: 100%;
		position: relative;
		width: 100%;
	}

	@if $type == _children {
		@include image_loader;

		height: 100%;
		left: 0;
		object-fit: cover;
		position: absolute;
		top: 0;
		width: 100%;
	}
}
