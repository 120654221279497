/*md
# Button link

Button styles like a simple link

HTML structure example:
```
<button class="b-button_link">Customer Service</button>
```

SCSS structure example:
```
.b-button_link {
	@include g-button_link;
}
```
*/

@mixin g-button_link($fn: 12px, $uppercase: true, $padding: null,) {
	@include font(regular, $fn);

	text-decoration: underline;

	@if $uppercase == true {
		text-transform: uppercase;
	}

	@include g-button_control($padding) {
		@content;
	}

	&:hover,
	&:focus {
		text-decoration: none;
	}
}
