/*md
# Button type 2

Simple light button, uppercased

```html_example
<span class="b-button_2">Button type 2</span>
```

Simple light button, uppercased, centered

```html_example
<span class="b-button_2 m-centered">Button type 2</span>
```
*/

.b-button_2 {
	@include g-button_2;
}

.b-button_2.m-centered {
	@include g-button_2(root, centered);
}
