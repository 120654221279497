.b-product_actions {
	&-variations,
	&-qty {
		margin-bottom: rh(1);
	}

	&-variations .b-simple_error {
		display: block;
		margin-bottom: rh(5);
	}

	.b-error_message:not(:empty) {
		display: inline-block;
		margin-bottom: rh(3);
	}

	&-hazardous_warning {
		@include g-error;

		margin-bottom: rh(3);
	}

	&-wrapper {
		margin-top: rh(3);
		position: relative;
	}

	&-attribute {
		margin: rh(3) 0;
	}

	&-back_in_stock {
		.b-notify_me {
			margin-bottom: rh(3);
		}
	}

	&-fast {
		margin: 10px 0 0;
		width: 100%;
	}

	&-fast_wrap {
		border: 1px solid color(lighter3);
		border-radius: 10px;
		margin: 15px 0;
		position: relative;
		z-index: 0;
	}

	&-fast_wrap &-label {
		background: color(light);
		display: inline-block;
		font-weight: 700;
		left: 50%;
		padding: 0 10px;
		position: absolute;
		top: 0;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	&-fast_wrap &-fast {
		margin: 20px auto;
		max-width: 216px;
	}
}
