/*md
@no-stat
# Section

* if you want to make the section centered by site width you need to `add m-site_width` for `b-section`
* if margin vertical margin: `m-margin_y`
* if margin-top - `m-margin_y_top`
* if margin-bottom - `m-margin_y_bottom`

Keep in mind that the common margin is 80px

## Example #1. The section with 3 stretched columns:

```html_example
<div class="b-section">
	<div class="b-section-row m-x-stretched">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #2. The section with 3 stretched columns with gaps:

```html_example
<div class="b-section">
	<div class="b-section-row m-x-stretched m-gaps">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #2.5. The section with 4 stretched columns with gaps:

```html_example
<div class="b-section">
	<div class="b-section-row m-x-stretched m-gaps">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/500x200" alt="" />
			</a>
		</div>
	</div>
</div>
```

## Example #3. The section with 3 columns with auto width and gaps:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #4. The section with 3 columns with auto width and gaps. Aligned by end:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps m-x-end">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #5. The section with 3 columns with auto width and gaps. Aligned by center:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps m-x-center">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #6. The section with 3 columns with auto width and gaps. Justified:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps m-x-justify">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #7. The section with 3 columns with auto width and gaps. Vertical aligned by end:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps m-y-end">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #8. The section with 3 columns with auto width and gaps. Vertical aligned by center:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps m-y-center">
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x200" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x150" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
		<div class="b-section-column">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/200x300" alt="" />
				<span class="g-image_tile-label">LABEL</span>
			</a>
		</div>
	</div>
</div>
```

## Example #9. The section with 2 strict columns:

```html_example
<div class="b-section">
	<div class="b-section-row m-gaps">
		<div class="b-section-column m-size_xlarge_4 m-size_large_6 m-size_medium_8 m-size_small_12">
			<div class="g-text_tile">
				<p class="g-text_tile-main">
					<span class="g-text_tile-text_block">Your solid text block 1</span>
					Your text block 2
				</p>

				<p class="g-text_tile-secondary">
					Secondary text block 1
					<span class="g-text_tile-text_block">Secondary text block 2</span>
				</p>

				<div class="g-text_tile-cta">
					<a class="g-text_tile-cta_link" href="#">Shop now</a>
				</div>
			</div>
		</div>
		<div class="b-section-column m-size_xlarge_8 m-size_large_6 m-size_medium_4 m-size_small_12">
			<a href="#" class="g-image_tile">
				<img class="g-image_tile-image" src="//placehold.it/600x200" alt="" />
				<span class="g-image_tile-label">CATEGORY NAME AS LABEL</span>
			</a>
		</div>
	</div>
</div>
```
*/

.b-section {
	@include grid-column;

	@include media(l) {
		@include grid-column('large');
	}

	@include media(m) {
		@include grid-column('medium');
	}

	@include media(s) {
		@include grid-column('small');
	}

	&.m-margin_y {
		margin-bottom: rh(20);
		margin-top: rh(20);
	}

	&.m-margin_y_top {
		margin-top: rh(20);
	}

	&.m-margin_y_bottom {
		margin-bottom: rh(20);
	}

	&-bgwrapper {
		background-image: var(--urlbg);
		background-repeat: no-repeat;
		background-size: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 8.2%;

		@include media(s) {
			background-position-x: 0%;
			background-size: 237%;
			margin-left: auto;
			margin-right: auto;
			padding: 0;
		}
	}

	&-row {
		display: flex;

		@include media(s) {
			flex-direction: column;
		}
	}

	&-row.m-gaps {
		margin: 0 calc(-1 * var(--grid-gutter));
	}

	&-row.m-x-end {
		justify-content: flex-end;
	}

	&-row.m-x-center {
		justify-content: center;
	}

	&-row.m-x-justify {
		justify-content: space-between;
	}

	&-row.m-y-end {
		align-items: flex-end;
	}

	&-row.m-y-center {
		align-items: center;
	}

	&-column {
		@include media(s) {
			& + & {
				margin-top: rh(7);
			}
		}
	}

	&-row.m-gaps &-column {
		padding: 0 var(--grid-gutter);
	}

	&-row.m-percent_gaps &-column {
		padding: 0 1.8%;

		@include media(s) {
			padding: 0;
		}
	}

	&-row.m-x-stretched &-column {
		flex-grow: 1;
	}

	&-row.m-4row &-column {
		@include media(m-up) {
			width: 25%;
		}
	}

	&-row.m-2column &-column {
		@include media(m-up) {
			width: 50%;
		}

		@include media(s) {
			margin-top: 0;
			width: 100%;
		}
	}

	&-title {
		--section-title-gap: 20px;

		@include font(bold, 24px);

		align-items: center;
		color: color(dark);
		display: flex;
		justify-content: space-between;
		margin: 0 calc(-1 * var(--section-title-gap)) rh(10);
		text-transform: uppercase;

		@include media(s) {
			--section-title-gap: 15px;
		}

		&::before,
		&::after {
			background: color(lighter4);
			content: '';
			flex-grow: 1;
			height: 1px;
			margin: 0 var(--section-title-gap);
		}
	}

	&.m-svgbg {
		max-width: initial;
		padding: 0 10%;

		@include media(s) {
			margin-bottom: 0;
			padding: 0;
		}
	}

	&-row.m-40pc_column &-column {
		@include media(m-up) {
			width: 40%;
		}
	}

	&.m-nomarg_mob &-column {
		@include media(s) {
			margin-bottom: 0;
		}
	}

	&.m-nomarg_mob {
		@include media(s) {
			margin-bottom: 0;
		}
	}
}
