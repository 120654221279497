/*md
# Lists

### Unordered List
```html_example
	<ul class="t-unordered_list">
		<li>Item 1</li>
		<li>Item 2</li>
		<li>Item 3</li>
	</ul>
```

### Ordered List
```html_example
	<ol class="t-ordered_list">
		<li>Item 1</li>
		<li>Item 2</li>
		<li>Item 3</li>
	</ol>
```
*/

%t-unordered_list,
.t-unordered_list {
	@include t-unordered_list;
}

%t-ordered_list,
.t-ordered_list {
	@include t-ordered_list;
}
