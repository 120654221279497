/*md
@no-stat
# Input Password

## HTML structure example:

```
<label class="f-input_password">
	<span class="f-input_password-label">Password</span>
	<input class="f-input_password-field" type="password" name="password">
</label>
```

### Default password field

```html_example
<label class="f-input_password">
	<span class="f-input_password-label">Password</span>
	<input class="f-input_password-field" type="password" name="password">
</label>
```

### Password field is required

```html_example
<label class="f-input_password m-required">
	<span class="f-input_password-label">Password</span>
	<input class="f-input_password-field" type="password" name="password" required>
</label>
```

### Active password field (optional)

```html_example
<label class="f-input_password m-prefilled">
	<span class="f-input_password-label">Password<span class="f-input_password-optional_copy"> (optional)</span></span>
	<input class="f-input_password-field" type="password" name="password" value="safasdfsd">
</label>
```

### Password field in error state

```html_example
<label class="f-input_password m-error">
	<span class="f-input_password-label">Password</span>
	<input class="f-input_password-field" type="password" name="password" value="prfsd">
</label>
```

### Disabled password field

```html_example
<label class="f-input_password m-disabled">
	<span class="f-input_password-label">Password</span>
	<input class="f-input_password-field" type="password" name="password" disabled>
</label>
```

### Password without label

```html_example
<label class="f-input_text">
	<input class="f-input_text-field" type="password" name="password" required>
</label>
```
*/

@include f-input_password;

.f-input_password {
	&-pswd {
		&::placeholder {
			color: transparent;
		}
	}
}
