.b-product_loyalty {
	align-items: flex-end;
	display: flex;
	margin-right: -20px;
	position: relative;
	z-index: 0;

	@include media(m-down) {
		align-items: flex-start;
		flex-direction: column;
	}

	&-message {
		@include font(medium, 12px);

		padding-bottom: 3px;

		@include media(m-down) {
			padding: 10px 0 0;
		}
	}

	&-image {
		margin-right: 10px;
		max-width: 130px;
	}

	&-points {
		font-weight: 600;
	}
}
