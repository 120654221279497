/*md
# Underlined Tabs

Tabs with underlined controls

HTML structure example:
```
<div class="g-tabs_underlined" data-component="global/Tabs">
	<div class="g-tabs_underlined-head">
		<ul class="g-tabs_underlined-list" data-js-tabs>
			<li class="g-tabs_underlined-control" data-js-tab-item="1">
				<span class="g-tabs_underlined-control_name">Tab 1</span>
			</li>

			<li class="g-tabs_underlined-control" data-js-tab-item="2">
				<span class="g-tabs_underlined-control_name">Tab 2</span>
			</li>
		</ul>
	</div>

	<div class="g-tabs_underlined-content" data-js-tabs-content>
		<div class="g-tabs_underlined-item" data-js-tab-content="1">Tab 1 contents</div>

		<div class="g-tabs_underlined-item" data-js-tab-content="2">Tab 2 contents</div>
	</div>
</div>
```

SCSS structure example:
```
.g-tabs_underlined {
	@include g-tabs_underlined;

	&-head {
		@include g-tabs_underlined(_head);
	}

	&-list {
		@include g-tabs_underlined(_list);
	}

	&-control {
		@include g-tabs_underlined(_control);
	}

	&-control.m-tabs-active {
		@include g-tabs_underlined(_control_active);
	}

	&-control_name {
		@include g-tabs_underlined(_control_name);
	}

	&-item {
		@include g-tabs_underlined(_item);
	}

	&-item.m-tabs-shown {
		@include g-tabs_underlined(_item_shown);
	}
}
```
*/

@mixin g-tabs_underlined(
	$_: root
) {
	@if $_ == root {}

	@if $_ == _list {
		@include g-tabs(_list);
	}

	@if $_ == _control {
		@include g-tabs(_control);
		@include font(medium, 14px, 1.2);

		color: color(lighter2);
		position: relative;
		text-transform: uppercase;

		&::after {
			border-bottom: 1px solid color(lighter4);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			transition: border-bottom 0.3s ease;
		}
	}

	@if $_ == _control_name {
		@include g-tabs(_control_name);

		align-self: center;
		padding: rh(5 3);
		text-align: center;
		width: 100%;
	}

	@if $_ == _control_active {
		@include g-tabs(_control_active);
		@include font(bold);

		color: color(dark);

		&::after {
			border-bottom: 3px solid color(dark);
		}
	}

	@if $_ == _item {
		@include g-tabs(_item);
		@include font(regular, 14px);

		align-self: center;
		padding-top: rh(14);
		text-align: center;

		@include media(s) {
			padding-top: rh(10);
		}
	}

	@if $_ == _item_shown {
		@include g-tabs(_item_shown);
	}
}
