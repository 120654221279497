@mixin f-select {
	.f-select {
		&-element_wrapper {
			display: block;
			position: relative;

			@include icon(arrow-down, after) {
				font-size: 5px;
				margin-top: -2px;
				pointer-events: none;
				position: absolute;
				right: 20px;
				top: 50%;
			}
		}

		&-element {
			@include f-input_field;

			padding-right: rh(12);

			/* stylelint-disable selector-class-pattern */
			&.flagstrap {
				border: 0;
				padding: 0;
			}
		}

		&.m-error &-element_wrapper {
			&::after {
				color: color(red);
			}
		}

		&.m-error &-element {
			@include f-input_field(error);
		}

		&.m-disabled &-element {
			@include f-input_field(disabled);
		}

		&.m-disabledcountry &-element {
			@include f-input_field(disabledcountry);
		}

		&-label {
			@include f-input_label;
		}

		&.m-required &-label {
			@include f-input_label($mod: required);
		}

		&.m-simple &-element_wrapper {
			display: inline-block;

			&::after {
				right: 0;
			}
		}

		&-optional_copy {
			@include f-input_label(_optional_copy);
		}

		&-label_description {
			@include f-input_label(_label_description);
		}

		&.m-simple &-element {
			border: none;
			line-height: normal;
			padding: 0;
			width: auto;
		}

		&-label_link {
			@include f-input_label(_label_link);
		}

		.flagstrap {
			.f-select {
				display: block;
			}

			.btn {
				border-radius: 0;
				display: block;
				line-height: 40px;
				padding: 0 50px 0 12px;
				text-align: left;
				width: 100%;
			}

			.caret {
				display: none;
			}

			.dropdown-menu {
				max-width: 100%;
			}
		}

		.hidden {
			display: none;
		}
	}
}
