@mixin g-message {
	@include font(regular, 12px);

	background: color(lighter1);
	color: color(light);
	padding: rh(4 15);
	text-align: center;

	@include media(s) {
		padding: rh(3 9);
	}
}
