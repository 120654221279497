/*md
# hero_sideinfo

Image background with white/black text on the right/left which goes below on mobile.

Custom Parallax for these modules (as they are made with css grid, unlike banner_parallax).

Add style="overflow:hidden" to the container div.

Options:
imgScale: 1.25 // how much to zoom into image

multiplier: 0.05 // how fast to move accross screen

offsetY: -20 // offset Y in pixels

## Example #1. Content on the right, black sub text, black button:

```html_example
<figure class="b-hero_sideinfo">

<picture  class="b-hero_sideinfo-image" data-component="global/CustomParallax" data-component-options='{
	"imgScale": 1.08,
    "multiplier": 0.03,
    "offsetY": -10}'>
  <source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
  <source media="(max-width: 767px)" srcset="//placehold.it/960x960">
  <img data-js-custom-parallax src="//placehold.it/1920x1080" alt="background image">
</picture>

	<figcaption class="b-hero_sideinfo-wrapper">
		<picture class="b-hero_sideinfo-svg_logo">
			<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
			<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
			<img class="b-hero_sideinfo-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$" alt="svg logo">
		</picture>

		<p class="b-hero_sideinfo-subtext">
			REFRESH YOUR MAKEUP BAG FOR 2019
		</p>

		<span class="b-button_1 m-centered">Button type 2</span>

	</figcaption>
</figure>
```

## Example #2. Content on the left, white sub text, white button:

```html_example
<figure class="b-hero_sideinfo m-content_left m-white_subtext">

<picture  class="b-hero_sideinfo-image" data-component="global/CustomParallax" data-component-options='{
	"imgScale": 1.08,
    "multiplier": 0.03,
    "offsetY": -10}'>
  <source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
  <source media="(max-width: 767px)" srcset="//placehold.it/960x960">
  <img data-js-custom-parallax src="//placehold.it/1920x1080" alt="background image">
</picture>

	<figcaption class="b-hero_sideinfo-wrapper">
		<picture class="b-hero_sideinfo-svg_logo">
		<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
		<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
		<img class="b-hero_sideinfo-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$" alt="svg logo">
		</picture>

		<p class="b-hero_sideinfo-subtext">
			REFRESH YOUR MAKEUP BAG FOR 2019
		</p>

		<span class="b-button_3 m-centered">Button type 2</span>

	</figcaption>
</figure>
```

## Example #3. Content on the right, black sub text, black button:

```html_example
<figure class="b-hero_sideinfo">

<picture class="b-hero_sideinfo-image" data-component="global/CustomParallax" data-component-options='{
	"imgScale": 1.08,
    "multiplier": 0.03,
    "offsetY": -10}'>
  <source media="(min-width: 768px)" srcset="//placehold.it/1920x1080">
  <source media="(max-width: 767px)" srcset="//placehold.it/960x960">
  <img data-js-custom-parallax src="//placehold.it/1920x1080" alt="background image">
</picture>

	<figcaption class="b-hero_sideinfo-wrapper">
		<picture class="b-hero_sideinfo-svg_logo">
		<source media="(min-width: 768px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
		<source media="(max-width: 767px)" srcset="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$">
		<img class="b-hero_sideinfo-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/revolution-skincare/2019/wk30/revolution_skincare_logo_black.svg?$staticlink$" alt="svg logo">
		</picture>

		<p class="b-hero_sideinfo-subtext">
			REFRESH YOUR MAKEUP BAG FOR 2019
		</p>

		<span class="b-button_1 m-centered">Button type 2</span>

	</figcaption>
</figure>
```

## Example #4. Roxi banner from homepage, separate image on the left with inline background color from image for the content:

```html_example
<figure class="b-hero_sideinfo m-roxy h-site_width">
	<div class="b-hero_sideinfo-imagewrapper" style="background-color:#e6dfc6">
		<img class="b-hero_sideinfo-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/small_roxi_sqaure_1.gif?$staticlink$" alt="background image">
	</div>

    <figcaption class="b-hero_sideinfo-wrapper" style="background-color:#e6dfc6">
        <img class="b-hero_sideinfo-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/roxi_collab.svg?$staticlink$" alt="svg logo">
		<p class="b-hero_sideinfo-subtext">
            Your ride or die makeup collection.
        </p>
        <span class="b-button_1 m-centered" style="margin-bottom: 20px;">Button type 2</span>
    </figcaption>
</figure>
```
## Example #5. Roxi banner flipped on x axis (m-content_left),

```html_example
<figure class="b-hero_sideinfo m-roxy m-content_left ">
	<div class="b-hero_sideinfo-imagewrapper" style="background-color:#e6dfc6">
		<img class="b-hero_sideinfo-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/small_roxi_sqaure_1.gif?$staticlink$" alt="background image">
	</div>

    <figcaption class="b-hero_sideinfo-wrapper" style="background-color:#e6dfc6">
        <img class="b-hero_sideinfo-svg_logo" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/homepage/2019/wk30/roxi_collab.svg?$staticlink$" alt="svg logo">
		<p class="b-hero_sideinfo-subtext">
            Your ride or die makeup collection.
        </p>
        <span class="b-button_1 m-centered" style="margin-bottom: 20px;">Button type 2</span>
    </figcaption>
</figure>
```

## Example #6. Obsession page 3 slots in one,

```html_example
<figure class="b-hero_sideinfo m-roxy m-content_left m-obsession m-druk">
	<div class="b-hero_sideinfo-imagewrapper" style="background-color:#FFD543" >
		<img class="b-hero_sideinfo-image" src="https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/makeup-obsession/2019/wk30/wk1_4_3_obsession_pride.jpg?$staticlink$" alt="background image">
	</div>

    <figcaption class="b-hero_sideinfo-wrapper " style="background-color:#FFD543; ">
        <p class="b-hero_sideinfo-main" style="font-variant: small-caps; ">
                we are proud
            </p>
            <p class="b-hero_sideinfo-secondary" style="margin-bottom: 30px;" >
                We’re giving £25,000 to the Dignity Trust from the sales of our Pride palette
            </p>
            <span class="b-button_1 m-centered">SHOP NOW</span>
    </figcaption>
</figure>

<div class="g-text_tile m-centered m-smallcaps m-druk" style="margin: 40px 0 50px;">
    <p class="g-text_tile-main" style="margin: 20px 0; ">
        <span class="g-text_tile-text_block">who are we <span style="font-size: 49px;position: relative;left: -12px;">?<span></span>
    </p>
</div>

<figure class="b-vimeo m-multi_bg m-4725" style="--urlbg: url(https://stg-cc.revolutionbeauty.com/on/demandware.static/-/Library-Sites-revbe-content-global/default/dw0a0772ed/images/brands/makeup-obsession/2019/wk30/bgobsession2.svg?$staticlink$);">
    <div class="b-vimeo-video_desktop">
       <iframe src="https://player.vimeo.com/video/343457878?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
	</div>
	<div class="b-vimeo-video_mobile">
       <iframe src="https://player.vimeo.com/video/343457866?autoplay=0&loop=1&autopause=1" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>

     <p class="b-vimeo-beyond_text" style="line-height: 0.9; font-size: 130px;">You are the Revolution</p>
      <p class="b-vimeo-beyond_smalltext" style=" margin-bottom: 60px;">Have fun and show us  how to wear it @iheartrevolution</p>

<div style="width: 100%;">
	<div id="olapic_specific_widget"></div><script type="text/javascript" src="https://photorankstatics-a.akamaihd.net/743d2e78a76dedeb07e0745158547931/static/frontend/latest/build.min.js"  data-olapic="olapic_specific_widget" data-instance="4f4d11b7e0b19ecddf45d2b93c52d9c1" data-apikey="3d1e19985e948755bf7ca124a4be581c17903842b369dfafb80c2d2871b671b3" async="async"></script>
</div>

</figure>
```
*/

.b-hero_sideinfo {
	display: grid;
	grid-template: auto / 1fr 1fr;
	height: 100%;
	margin-bottom: rh(10);
	overflow: hidden;

	&-imagewrapper {
		align-self: stretch;
		grid-column: 1/3;
		grid-row: 1;
		overflow: hidden;
		transition: 0.7s;
		width: 100%;
	}

	&-beyond_text {
		@include font(beyond_infinity, 28px, 1.4);

		color: color(dark);
	}

	&-image {
		align-self: stretch;
		grid-column: 1/3;
		grid-row: 1;
		object-fit: cover;
		overflow: hidden;
		transition: 0.7s;
		width: 100%;
	}

	&-svg_logo {
		align-self: center;
		margin: rh(10) auto;
		width: 100%;

		@include media(m) {
			margin: rh(8) auto;
			width: 100%;
		}

		@include media(s) {
			margin: 0 auto rh(5);
			width: 100%;
		}
	}

	&.m-mobile_on_top &-svg_logo {
		@include media(s) {
			padding-bottom: rh(5);
		}
	}

	&-wrapper {
		align-self: center;
		grid-column: 2;
		grid-row: 1;
		padding: rh(13 17);
		position: relative;
		text-align: center;

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
			padding: rh(8 5);
		}
	}

	&.m-content_left &-wrapper {
		grid-column: 1;

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
		}
	}

	&-subtext {
		color: color(dark);
		font-size: 25px;
		letter-spacing: 5.65px;
		margin-bottom: rh(8);
		margin-left: auto;
		margin-right: auto;
		max-width: 400px;
		padding: rh(0 5);

		@include media(m) {
			font-size: 16px;
		}

		@include media(s) {
			font-size: 16px;
		}
	}

	&-small_text {
		@include font(medium, 16px, 1.5);

		color: color(dark);
		margin: 0 auto rh(8);
		max-width: 330px;
		padding: rh(0 8);
	}

	&-toptext {
		@include font(medium, 28px, 1.4);

		letter-spacing: 5.65px;
		margin-bottom: 0;
		padding: 0;

		@include media(m) {
			@include font(medium, 14px, 1.4);
		}

		@include media(s) {
			@include font(medium, 15px, 1.4);
		}
	}

	&-title {
		@include font(bold, 39px, 1.3);

		color: color(dark);
		font-variant: small-caps;
		margin-bottom: rh(5);

		@include media(s) {
			font-size: rh(7);
		}
	}

	&.m-roxy &-imagewrapper {
		display: flex;
		grid-column: 1/2;

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
		}
	}

	&.m-roxy &-image {
		align-self: flex-end;
		width: 100%;
	}

	&.m-roxy &-subtext {
		font-size: 16px;
		letter-spacing: 1px;
	}

	&.m-mobile_on_top &-hidemobile {
		margin-bottom: rh(3);

		@include media(s) {
			display: none;
		}
	}

	&-showmobile {
		grid-column: 1/3;
		grid-row: 2;
		justify-self: center;
		margin-top: rh(5);

		@include media(m-up) {
			display: none;
		}
	}

	&-showmobile.m-second_btn {
		grid-row: 3;
	}

	&.m-white_subtext &-subtext {
		color: color(light);
	}

	&.m-extra_margin &-svg_logo {
		@include media(s) {
			margin-top: rh(5);
		}
	}

	&.m-roxy &-svg_logo {
		margin-bottom: 0;
		margin-top: 0;
		width: 85%;
	}

	&.m-pride &-image {
		transform-origin: 10% 40%;

		@include media(s) {
			transform-origin: 50% 70%;
		}
	}

	&.m-roxy &-wrapper {
		height: 100%;
		padding: rh(8);

		@include media(s) {
			grid-column: 1/3;
			grid-row: 1;
		}
	}

	&.m-revpro_logo &-wrapper {
		padding: rh(13 16);

		@include media(s) {
			padding: rh(8 5);
		}
	}

	&.m-revpro_logo &-svg_logo {
		@include media(m-up) {
			width: 90%;
		}
	}

	&.m-mobile_on_top &-wrapper {
		@include media(s) {
			align-self: flex-start;
			grid-column: 1/3;
			grid-row: 1;
			padding-top: 17%;
		}
	}

	&.m-revmakeup {
		grid-template: auto / 6fr 5fr;
	}

	&.m-revmakeup &-svg_logo {
		@include media(s) {
			width: 65%;
		}
	}

	&.m-revmakeup &-wrapper {
		padding-right: 0;
	}

	&.m-revskincare &-svg_logo {
		width: 58%;

		@include media(s) {
			width: 68%;
		}
	}

	&.m-iheart &-svg_logo {
		@include media(s) {
			width: 100%;
		}
	}

	&.m-makeupobsession &-svg_logo {
		margin-top: 0;

		@include media(s) {
			width: 95%;
		}
	}

	&.m-makeupobsession &-wrapper {
		@include media(s) {
			padding: rh(8 0);
		}

		@include media(m-up) {
			align-items: flex-start;
			text-align: start;
		}
	}

	&.m-small_image &-image {
		align-self: center;
		max-width: 450px;

		@include media(s) {
			max-width: 100%;
		}
	}

	&.m-small_image &-imagewrapper {
		display: flex;
		grid-column: 1/2;
		justify-content: flex-end;

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
			justify-content: center;
		}
	}

	&.m-small_image &-wrapper {
		@include media(m) {
			padding: rh(13 10);
		}

		@include media(s) {
			grid-row: 1;
			padding: rh(8 0);
		}
	}

	&.m-small_image &-small_text {
		max-width: 330px;
		padding: 0;

		@include media(m-up) {
			margin-left: 0;
			padding-left: 0;
			text-align: left;
		}
	}

	&.m-small_image &-title {
		@include media(m-up) {
			text-align: left;
		}
	}

	&.m-small_image {
		padding: 0 var(--grid-gutter);
	}

	&.m-content_left &-imagewrapper {
		grid-column: 2;

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
		}
	}

	&.m-reset_image &-image {
		max-width: initial;
	}

	&.m-druk &-title {
		@include font(druk, 70px, 1);

		font-weight: normal;
		letter-spacing: 4px;

		@include media(s) {
			font-size: 40px;
		}
	}

	&.m-obsession &-wrapper {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: rh(15);

		@include media(s) {
			grid-column: 1/3;
			grid-row: 2;
		}
	}

	&.m-obsession &-main {
		@include font(druk, 70px, 1);

		color: color(dark);
		letter-spacing: 2px;
	}

	&.m-obsession &-secondary {
		@include font(medium, 16px, 1.4);

		color: color(dark);
		margin-top: rh(2);
		max-width: 340px;
	}

	&.m-obsession &-imagewrapper {
		@include media(s) {
			grid-column: 1/3;
			grid-row: 1;
		}
	}

	&.m-mobilepadding &-wrapper {
		@include media(s) {
			padding: rh(25 12);
		}
	}
}
