/*md
# Banner type 5

Image banner with note below.

Used in BLOG

```html_example
<figure class="g-banner_5">
	<picture class="g-banner_5-image_wrapper">
		<source media="(min-width:768px)" srcset="//placehold.it/800x400 2x">
		<img class="g-banner_5-image" src="//placehold.it/800x400" alt="">
	</picture>
	<figcaption class="g-banner_5-note">I HEART REVOLUTION, Fall/Winter 2019</figcaption>
</figure>
```

*/

.g-banner_5 {
	@include g-banner_5;

	&-image_wrapper {
		@include g-banner_5(_image_wrapper);
	}

	&-note {
		@include g-banner_5(_note);
	}
}
