.b-availability {
	&-inner {
		background-color: color(dark);
		display: inline-block;
		padding: 0 rh(3);

		@include media(s) {
			margin-bottom: rh(9);
		}

		&::before {
			border-radius: 50%;
			content: '';
			display: inline-block;
			height: rh(2);
			width: rh(2);
		}
	}

	&.m-in_stock &-inner::before {
		background-color: color(green);
	}

	&.m-low_stock &-inner::before {
		background-color: color(yellow);
	}

	&.m-out_of_stock &-inner::before {
		background-color: color(red);
	}

	&-label {
		@include font(medium, 10px, 18px);

		color: color(light);
	}
}
