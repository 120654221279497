/*md
# Accordion

## Simple accordion:

```html_example
<div class="b-accordion" data-component="global/Accordion" data-component-context="small">
	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 1</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
		</div>
	</div>

	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 2</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
			<div class="b-accordion-link">Item 1 - 5</div>
		</div>
	</div>

	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 3</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
		</div>
	</div>
</div>
```

## Simple accordion without side paddings:

```html_example
<div class="b-accordion m-full" data-component="global/Accordion" data-component-context="small">
	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 1</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
		</div>
	</div>

	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 2</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
			<div class="b-accordion-link">Item 1 - 5</div>
		</div>
	</div>

	<div class="b-accordion-item" data-js-accordion-item>
		<span class="b-accordion-control" data-js-accordion-control>Item 3</span>
		<div class="b-accordion-container" data-js-accordion-container>
			<div class="b-accordion-link">Item 1 - 1</div>
			<div class="b-accordion-link">Item 1 - 2</div>
			<div class="b-accordion-link">Item 1 - 3</div>
			<div class="b-accordion-link">Item 1 - 4</div>
		</div>
	</div>
</div>
```
*/

.b-accordion {
	@include g-accordion-styles;

	&-item {
		@include g-accordion-styles(_item);
	}

	&-control {
		@include g-accordion-styles(_control);

		&.m-accordion-expanded {
			@include g-accordion-styles(_control_expanded);
		}
	}

	&-container {
		@include g-accordion-styles(_content);

		&.m-accordion-expanded {
			@include g-accordion-styles(_content_expanded);
		}
	}

	&.m-full &-control {
		@include g-accordion-styles(_control, full);
	}

	&.m-full &-container {
		@include g-accordion-styles(_content, full);
	}
}
