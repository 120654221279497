:root {
	--category-background-image: none;
	--category-background-size: auto;
	--category-background: transparent;
	--category-text-color: color(dark);
	--category-text-background: transparent;
	--category-text-color-mobile: color(dark);
	--category-text-background-mobile: transparent;
	--category-font-size-desktop: inherit;
	--category-font-size-tablet: inherit;
	--category-font-size-mobile: inherit;
}
/* stylelint-disable no-descending-specificity */
.b-nav {
	@include font(regular, 12px);

	transition: transform 0.3s linear;

	@include media(l-up) {
		padding-top: 15px;
	}

	@include media(m-down) {
		min-height: calc(100vh - 106px);
	}

	&-text {
		@include media(l-up) {
			background-color: var(--category-text-background);
			color: var(--category-text-color);
		}

		@include media(m-down) {
			background-color: var(--category-text-background-mobile);
			color: var(--category-text-color-mobile);
			padding: rh(0 0.5);
		}
	}

	&-badge_image {
		$size: 40px;

		background-position: center;
		background-repeat: no-repeat;
		background-size: $size;
		border-radius: 50%;
		height: $size;
		margin-right: 6px;
		position: relative;
		width: $size;

		@include media(m-down) {
			margin-right: 12px;
		}

		&::before {
			border: 2px solid transparent;
			border-radius: 50%;
			content: '';
			display: block;
			height: $size + 4px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			transition: border-color 0.3s linear;
			width: $size + 4px;
			z-index: 1;

			@include media(m-down) {
				display: none;
			}
		}
	}

	&-text_1 {
		@include media(l-up) {
			margin: 0 15px;
			padding: 20px 0;
			position: relative;

			&::after {
				border-bottom: solid 2px currentColor;
				bottom: 0;
				content: '';
				display: block;
				left: 0;
				position: absolute;
				right: 0;
				transform: scaleX(0);
				transition: transform 500ms ease-in-out;
				width: 100%;
			}
		}
	}

	&-text_2::after {
		border-bottom: solid 1px currentColor;
		content: '';
		display: block;
		transform: scaleX(0);
		transition: transform 500ms ease-in-out;
	}

	&-text_2,
	&-text_3 {
		padding: 0 2px;
	}

	&-list_1 {
		display: flex;
		justify-content: center;

		@include media(m-down) {
			background: color(light);
		}

		@include media(l-up) {
			position: relative;
		}
	}

	&-list_2 {
		overflow: hidden;
		position: relative;
		transition: transform 0.3s linear;
		width: 50%;

		@include media(l-up) {
			&::before {
				bottom: 0;
				content: '';
				position: absolute;
				right: 0;
				top: 0;
				width: 66.6%;
			}

			&.m-hover::before {
				width: 0;
			}
		}

		@include media(m-down) {
			background: color(light);
		}
		/* stylelint-disable declaration-block-no-redundant-longhand-properties */
		&.h-hamburger-list_2 {
			@include media(m-down) {
				align-content: flex-start;
				align-items: center;
				flex-flow: row wrap;
			}
		}
		/* stylelint-enable declaration-block-no-redundant-longhand-properties */
	}

	&-list_3 {
		flex: 1;
		margin-top: 0;
		transition: transform 0.3s linear;

		@include media(l-up) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			opacity: 0;
			padding-left: 10px;
			position: absolute;
			right: 0;
			top: 0;
			transition: none;
			visibility: hidden;
			width: 100%;
			width: 66.6%;
			z-index: 2;
		}

		@include media(m-down) {
			background: color(light);
			display: block;
			margin-top: 0;
		}

		/* stylelint-disable declaration-block-no-redundant-longhand-properties */
		&.h-hamburger-list_3 {
			@include media(m-down) {
				align-items: center;
				display: flex;
				flex-flow: row wrap;
			}
		}
		/* stylelint-enable declaration-block-no-redundant-longhand-properties */
		&::before {
			@include media(l-up) {
				background: color(light);
				border-left: 1px solid color(lighter5);
				content: '';
				height: 100vh;
				position: absolute;
				width: 100vh;
			}
		}

		.b-nav-item_2.m-has_sub_menu:hover &,
		.b-nav-item_2.m-has_sub_menu:focus & {
			@include media(l-up) {
				opacity: 1;
				transition: opacity 0.2s linear;
				visibility: visible;
			}
		}
	}

	&-megamenu {
		background: color(light);

		@include media(l-up) {
			border-top: 2px solid color(lighter5);
			left: 0;
			padding: rh(7 0 12);
			position: absolute;
			top: 100%;
			visibility: hidden;
			width: 100%;
		}
	}

	&-megamenu_wrapper {
		@include media(l-up) {
			display: flex;
			flex-wrap: wrap;
			margin: auto;
			max-width: var(--content-width);
			position: relative;
		}
	}

	&-megamenu_top {
		display: none;

		@include media(l-up) {
			display: flex;
			justify-content: space-between;
			width: 100%;
		}
	}

	&-viewall + .b-nav-badge {
		@include media(m-down) {
			display: none;
		}
	}

	&-view_all {
		@include font(medium, 12px);

		align-items: center;
		display: flex;
		margin-bottom: 10px;
		margin-left: 3px;
		min-height: 42px;
		text-transform: uppercase;

		&:hover .b-nav-view_all_text {
			color: color(pinkhover);
		}

		&:hover .b-nav-badge_image::before {
			@include media(l-up) {
				border-color: color(pinkhover);
				box-shadow: 0 0 0 2px color(light) inset;
			}
		}
	}

	&-megamenu_slot_wrapper {
		width: calc(100% - 42px);
	}

	&-megamenu_slot_top {
		margin: 10px 10px 10px 0;

		@include media(m-down) {
			display: none;
		}

		&.m-mobile {
			display: none;

			@include media(m-down) {
				display: block;
				flex: auto;
				margin: 0;
				padding: 20px 20px 15px;
				width: 100%;
			}
		}
	}

	&-megamenu_close {
		align-items: center;
		cursor: pointer;
		display: flex;
		font-size: 0;
		justify-content: center;
		line-height: 0;
		margin: 0 -10px 0 auto;
		min-height: 42px;
		min-width: 42px;
		position: absolute;
		right: 0;
		top: 0;
		transform: translateX(#{rh(1)});

		@include icon(close, 12px) {
			color: color(dark);
		}

		@include media(m-down) {
			display: none;
		}
	}

	&-item_1,
	&-item_2:not(.m-slot),
	&-item_3 {
		@include media(m-down) {
			align-items: center;
			border-bottom: 1px solid color(lighter5);
			display: flex;
			width: 100%;
		}
	}

	&-link_3 {
		align-items: center;
		display: flex;

		@include media(m-down) {
			text-transform: uppercase;
		}

		&::before {
			content: '';
			display: inline-block;
			min-height: 40px;
			width: 3px;

			@include media(m-down) {
				display: none;
			}
		}

		&:hover {
			@include media(l-up) {
				color: color(lighter2);
			}
		}
	}

	&-item_1.m-has_sub_menu,
	&-item_2.m-has_sub_menu {
		@include media(m-down) {
			position: relative;

			&::before {
				bottom: 0;
				content: '';
				cursor: pointer;
				left: 0;
				position: absolute;
				right: 0;
				top: 0;
				z-index: 11;
			}
		}
	}

	&-item_1.m-active,
	&-item_2.m-active {
		@include media(m-down) {
			position: static;
			z-index: 100;

			&::before {
				display: none;
			}
		}
	}

	&-item_1 {
		@include media(l-up) {
			align-items: center;
			cursor: pointer;
			display: flex;
			font-size: 14px;
			padding: 0;
		}

		&.m-hidden {
			@include media(l-up) {
				display: none;
			}

			.b-country {
				&_selector {
					&-flag {
						width: auto;
					}
				}
			}
		}

		.b-country_selector_header {
			justify-content: flex-end;
		}

		.b-country_selector-control {
			font-size: 12px;
		}
	}

	&-item_1:hover &-megamenu {
		@include media(l-up) {
			cursor: default;
			visibility: visible;
		}

		.b-nav-list_3.m-visible {
			@include media(l-up) {
				opacity: 1;
				visibility: visible;
				z-index: 0;
			}
		}
	}

	&-item_2,
	&-item_3 {
		&.m-nav_item_super {
			@include media(l-up) {
				display: none;
			}

			@include media(m-down) {
				width: 50%;
			}

			.b-nav-badge_image {
				@include media(m-down) {
					flex: 0 0 40px;
					margin-left: rh(3);
					margin-right: 0;
				}
			}
		}
	}

	&-item_2 {
		width: 100%;

		@include media(l-up) {
			align-items: flex-start;
			display: flex;
		}

		&:hover,
		&.m-visible {
			.b-nav-link_2 > .b-nav-badge_image::before {
				@include media(l-up) {
					border-color: color(pinkhover);
					box-shadow: 0 0 0 2px color(light) inset;
				}
			}

			/* stylelint-disable declaration-no-important */
			/* !important is required for hover to effective when user configues custom color with inline styles */
			.b-nav-text_2 {
				@include media(l-up) {
					color: color(pinkhover) !important;
				}
			}
			/* stylelint-enable declaration-no-important */
		}
	}

	&-item_3 {
		padding: 0 6px;
		text-transform: uppercase;
		width: 100%;

		@include media(l-up) {
			padding: 0;
			pointer-events: all;
			position: relative;
		}

		@include media(m-down) {
			border-bottom: 1px solid color(lighter5);
			padding: 0;
		}

		&:hover {
			.b-nav-link_3 > .b-nav-badge_image::before {
				@include media(l-up) {
					border-color: color(pinkhover);
					box-shadow: 0 0 0 2px color(light) inset;
				}
			}

			/* stylelint-disable declaration-no-important */
			/* !important is required for hover to effective when user configues custom color with inline styles */
			.b-nav-text_3 {
				@include media(l-up) {
					color: color(pinkhover) !important;
				}
			}
			/* stylelint-enable declaration-no-important */
		}
	}

	&-megamenu_left {
		display: flex;
		flex: 1;
	}

	&-item_2.m-slot {
		flex-shrink: 0;
		padding-right: 0;
		position: relative;
		width: 50%;

		@include media(m-down) {
			width: 100%;
		}
	}

	&-link_1,
	&-link_2,
	&-link_3 {
		@include font(medium, 14px);

		align-items: center;
		display: flex;
		flex-flow: row nowrap;
		min-height: 45px;
		padding-right: 20px;

		@include media(m-down) {
			background-color: var(--category-background);
			background-image: var(--category-background-image);
			background-repeat: no-repeat;
			background-size: var(--category-background-size);
			flex-grow: 1;
			line-height: 1.25;
			min-height: 55px;
			padding: 0 20px;
			text-transform: uppercase;
		}
	}

	&-link_1 {
		text-decoration: none;
		text-transform: uppercase;

		.b-nav-badge_image {
			@include media(l-up) {
				display: none;
			}
		}

		.b-nav-badge_image + .b-nav-text_1 {
			@include media(m-down) {
				margin-left: 0;
			}
		}
	}

	&-link_1.m-has_sub_menu,
	&-link_2.m-has_sub_menu {
		@include media(m-down) {
			@include icon(arrow-right, after) {
				color: color(dark);
				font-size: 10px;
				margin-left: auto;
			}
		}
	}

	&-link_2,
	&-link_3 {
		color: color(dark);
		transition: color 0.1s linear;

		@include media(l-up) {
			font-size: 12px;
		}
	}

	&-link_2 {
		text-transform: uppercase;
		width: 33.3%;

		@include media(l-up) {
			color: color(dark);
		}

		&::before {
			content: '';
			display: inline-block;
			min-height: 40px;
			width: 3px;

			@include media(m-down) {
				display: none;
			}
		}

		&.m-has_sub_menu {
			@include icon(arrow-right, after) {
				color: color(dark);
				font-size: 9px;
				margin-left: auto;
				opacity: 1;
				padding-bottom: 3px;
				padding-left: 8px;
			}
		}
	}

	&-link_2.m-nav_link_super,
	&-link_3.m-nav_link_super {
		@include media(m-down) {
			@include font(regular, 12px);

			color: color(dark);
			display: flex;
			flex-direction: row-reverse;
			justify-content: end;
			max-height: 55px;
			padding: 20px;
		}

		.b-nav-text_2,
		.b-nav-text_3 {
			color: color(dark);
		}
	}

	&-item_1:hover &-text_1 {
		@include media(l-up) {
			&::after {
				transform: scaleX(1);
				transition: transform 500ms ease-in-out;
			}
		}
	}

	.b-nav-ads {
		@include media(m-down) {
			display: none;
		}
	}

	&.m-active_level_2 .m-active .b-nav-ads,
	&.m-active_level_3 .m-active .b-nav-ads {
		@include media(m-down) {
			display: block;
			height: auto;
		}
	}

	&.m-active_level_2 &-item_1,
	&.m-active_level_3 &-item_1 {
		@include media(m-down) {
			display: none;
		}
	}

	&.m-active_level_2 &-item_1.m-active,
	&.m-active_level_3 &-item_1.m-active {
		@include media(m-down) {
			display: flex;
		}
	}

	&.m-active_level_2 .m-active .h-hamburger-list_2 > .h-hamburger-megamenu_slot,
	&.m-active_level_3 .m-active .h-hamburger-list_2 > .h-hamburger-megamenu_slot,
	&.m-active_level_3 .m-active .m-active .h-hamburger-list_3 .h-hamburger-megamenu_slot {
		height: auto;
		opacity: 1;
		visibility: visible;
	}

	&-back_button {
		@include media(m-down) {
			border: 0;
			border-bottom: 1px solid color(lighter5);
			width: 50%;
		}

		@include media(l-up) {
			display: none;
		}
	}

	&-back_button_cta {
		@include g-back_button;

		background: none;
		min-height: 55px;

		&::before {
			color: color(dark);
			font-size: 9px;
			margin-right: 10px;
			opacity: 1;
		}
	}

	&-badge {
		@include font(regular, 9px);

		background-color: color(light);
		color: color(red);
		display: inline-block;
		line-height: 1.5;
		margin-left: rh(2);
		padding: rh(0 1);
		text-transform: uppercase;
	}

	&-link_1 &-badge {
		@include media(l-up) {
			left: -5px;
			margin: 3px 4px 1px 0;
			position: relative;
		}
	}

	&-link_2 &-badge,
	&-link_3 &-badge {
		vertical-align: middle;
	}

	&-link_1.m-instead,
	&-link_2.m-instead,
	&-link_3.m-instead {
		.b-nav-badge_image,
		.b-nav-badge {
			display: none;
		}
	}

	&-link_1.m-instead .b-nav-text_1 {
		visibility: hidden;
	}

	&-link_2.m-instead .b-nav-text_2 {
		visibility: hidden;
	}

	&-link_3.m-instead .b-nav-text_3 {
		visibility: hidden;
	}

	&-link_1.m-account {
		font-size: 12px;
		justify-content: flex-start;

		.b-account_links-link {
			align-items: center;
			color: color(dark);
			display: flex;

			@include icon(user) {
				font-size: 19px;
				margin-right: 8px;

				@include media(m-down) {
					font-size: 15px;
				}
			}

			&.m-sign_in {
				.b-account_links-text {
					display: none;
				}
			}

			&.m-logout {
				display: none;
			}
		}
	}

	&.m-sticky &-account .b-account_links::before {
		font-size: 16px;
		line-height: 30px;
	}

	&.m-sticky &-megamenu {
		@include media(l-up) {
			border-top: 1px solid color(lighter5);
		}
	}

	&-ads {
		align-items: flex-start;
		display: flex;
		justify-content: space-between;
		overflow: hidden;

		@include media(m-down) {
			display: block;
		}

		&_title {
			@include font(bold, 14px);

			padding-bottom: 10px;
			text-transform: uppercase;

			@include media(m-down) {
				align-items: center;
				border-bottom: 1px solid color(lighter5);
				display: flex;
				min-height: 55px;
				padding: 8px 20px;
			}

			&.m-secondary-title,
			&.m-carousel-title {
				padding-bottom: 20px;

				@include media(m-down) {
					border: 0;
					min-height: auto;
					padding: 5px 20px 10px;
				}
			}
		}
		/* stylelint-disable selector-class-pattern */
		&_list-item {
			@include font(regular, 12px);

			text-transform: uppercase;

			@include media(m-down) {
				border-bottom: 1px solid color(lighter5);
			}
		}

		&_list {
			padding-bottom: 30px;

			@include media(m-down) {
				padding-bottom: 20px;
			}
		}

		&_list-item_link {
			align-items: center;
			display: flex;
			min-height: 32px;
			transition: color 0.1s linear;

			@include media(m-down) {
				min-height: 55px;
				padding: 8px 20px;
			}

			&:hover {
				color: color(lighter2);
			}
		}
		/* stylelint-enable selector-class-pattern */
	}

	&-ads_left {
		--parent-padding-left: 12px;
		--parent-padding-right: 20px;

		max-width: 35%;
		padding-left: var(--parent-padding-left);
		padding-right: var(--parent-padding-right);

		@include media(m-down) {
			max-width: 100%;
			padding: 20px 0 0;
		}

		.content-asset {
			@include media(l-up) {
				width: calc(((var(--content-width) / 2) * 0.35) - (var(--parent-padding-left) + var(--parent-padding-right)));
			}

			&::before {
				background: color(lighter5);
				content: '';
				display: block;
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 3px;
			}
		}
	}

	&-ads_right {
		flex: 1;
		overflow: hidden;
		position: relative;
	}

	&-ads_posters {
		display: flex;
		justify-content: space-between;

		@include media(m-down) {
			align-items: center;
			flex-direction: column;
			max-width: 100%;
			padding: 20px;
		}

		&.m-secondary {
			@include media(m-down) {
				flex-direction: row;
				-webkit-overflow-scrolling: touch;
				overflow-x: auto;
				padding-top: 10px;
			}

			.b-nav-ads_poster + .b-nav-ads_poster {
				@include media(m-down) {
					margin-left: 10px;
					margin-top: 0;
				}
			}
		}
	}

	&-ads_poster {
		max-width: calc(50% - 10px);

		@include media(m-down) {
			max-width: 100%;
		}

		.m-secondary & {
			@include media(m-down) {
				min-width: 220px;
			}
		}
	}

	&-ads_poster + &-ads_poster {
		@include media(m-down) {
			margin-top: 20px;
		}
	}
	/* stylelint-disable selector-class-pattern */
	&-ads_poster-title {
		@include font(regular, 12px 16px);

		display: block;
		padding: 10px 0;
		text-align: center;
	}
	/* stylelint-enable selector-class-pattern */
	&-ads_carousel {
		@include media(m-down) {
			border-bottom: 1px solid color(lighter5);
		}

		.tns-outer {
			margin: 0 -10px;
			z-index: initial;

			&::before {
				@include media(l-up) {
					background: color(light);
					bottom: 0;
					content: '';
					left: 0;
					position: absolute;
					top: 0;
					width: 10px;
					z-index: 1;
				}
			}
		}

		.tns-slider {
			transition-property: transform;
		}

		.tns-inner {
			@include media(m-down) {
				padding: 0 20px;
			}
		}

		.b-nav-ads_tile {
			padding: 0 10px;
		}

		.b-product_tile-badge,
		.b-product_tile-wishlist,
		.b-product_tile-availability,
		.b-product_tile-ratings_wrapper,
		.b-product_tile-swatches,
		.b-product_tile-action,
		.b-product_tile-promo {
			display: none;
		}

		.l-plp-product_tile {
			margin-bottom: 0;
		}

		.b-product_tile-content {
			padding-bottom: 20px;

			@include media(m-down) {
				padding-bottom: 30px;
			}
		}

		.b-product_tile-title {
			@include font(medium);

			margin: 0 0 5px;

			@include media(m-down) {
				font-size: 12px;
				line-height: 16px;
			}
		}

		.b-product_price {
			font-size: 16px;

			@include media(m-down) {
				font-size: 10px;
				line-height: 16px;
			}
		}

		.b-product_price-sales {
			+ .b-product_price-list .b-product_price-value {
				@include media(m-down) {
					font-size: 10px;
					line-height: 16px;
				}
			}
		}

		.tns-controls {
			border: 2px solid transparent;
			left: 50%;
			padding-top: calc(50% - 16px);
			pointer-events: none;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 100%;

			@include media(m-down) {
				display: none;
			}
		}

		.tns-controls [aria-controls] {
			background: color(pdp_slider_arrow);
			border: 1px solid color(lighter4);
			border-radius: 50%;
			cursor: pointer;
			height: 38px;
			pointer-events: all;
			text-align: center;
			transition: background 0.2s linear;
			width: 38px;

			@include media(m-down) {
				height: 30px;
				width: 30px;
			}

			&:hover {
				background: color(pdp_slider_arrow_hover);
				opacity: 1;
			}

			&::before {
				color: color(dark);
				font-size: 10px;
				line-height: 36px;

				@include media(m-down) {
					line-height: 28px;
				}
			}
		}
		/* stylelint-disable selector-no-qualifying-type */
		.tns-controls button[data-controls='prev'] {
			@include icon(arrow-left);

			left: 10px;
		}

		.tns-controls button[data-controls='next'] {
			@include icon(arrow-right);

			right: 10px;
		}
		/* stylelint-enable selector-no-qualifying-type */
	}
}
/* stylelint-enable no-descending-specificity */
