.b-selected_bonus {
	display: flex;

	&:last-child {
		@include media(s) {
			padding-bottom: rh(5);
		}
	}

	& + & {
		border-top: 1px solid color(lighter5);
		margin-top: rh(5);
		padding-top: rh(5);
	}

	&-remove {
		flex-basis: 35px;
		flex-shrink: 0;
		position: relative;

		@include icon(close) {
			font-size: 15px;

			@include media(m-up) {
				left: 0;
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		@include media(s) {
			margin-top: rh(1);
		}
	}

	&-info {
		flex-grow: 1;
	}

	&-name {
		@include font(bold, 14px);

		margin-bottom: rh(2);
	}

	&-attributes {
		@include font(regular, 14px);

		& + & {
			margin-left: rh(5);
		}
	}

	&-attributes_item:first-child {
		margin-right: rh(4);
	}

	&-attributes_value {
		@include font(bold, 14px);
	}
}
