.b-product_promo {
	@include font(medium, 9px, 2);

	background: color(dark);
	color: color(light);
	cursor: pointer;
	display: inline-block;
	padding: rh(0 2);
	text-transform: uppercase;

	&-dialog {
		@include media(m-up) {
			max-width: 350px;
		}
	}

	&-dialog_title {
		@include font(bold, 18px);

		margin-bottom: rh(5);

		@include media(s) {
			padding-top: 20px;
		}
	}

	&-dialog_body {
		@include font(regular, 14px);

		color: color(lighter2);
		padding: 0 0 rh(15);

		@include media(m) {
			padding: 0 0 rh(12);
		}

		@include media(s) {
			padding: 0 0 rh(1);
		}

		button,
		.b-button_1,
		.b-button_2 {
			margin: rh(13) auto 0;
			max-width: fit-content;
		}
	}
}
