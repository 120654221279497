/*md
# Button type 4

Simple light button.

```html_example
<button class="b-button_4">BUTTON TYPE 4</button>
```

Simple light button, centered

```html_example
<button class="b-button_4 m-centered">BUTTON TYPE 4</button>
```
*/

.b-button_4 {
	@include font(medium, 14px, 1.2);

	background-color: transparent;
	border: none;
	color: color(dark);
	display: inline-block;
	position: relative;

	&.m-centered {
		left: 50%;
		transform: translateX(-50%);
	}

	&::after {
		border-bottom: solid 1px;
		content: '';
		display: block;
		padding-bottom: 3px;
		transform: scaleX(1);
		transition: transform 900ms ease-in-out;

		@include media(s) {
			transform: scaleX(1);
		}
	}

	&:hover {
		@include media(m-up) {
			cursor: pointer;

			&::after {
				transform: scaleX(0.5);
				transition: transform 900ms ease-in-out;
			}
		}
	}
}
