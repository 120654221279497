/*md
# Notification

Notification is a component which consists of a text with a close CTA

It can be of different types:

	- info notification
	- error notification

## Regular notification

```html_example
<div
	class="b-notification"
	role="alert"
>
	<div class="b-notification-text">Notification message</div>
</div>
```

## Error notification

```html_example
<div
	class="b-notification m-error"
	role="alert"
>
	<div class="b-notification-text">Notification message</div>
</div>
```
*/

.b-notification {
	@include g-notification;

	&.m-error {
		@include g-notification(root, error);
	}

	&-text {
		@include g-notification(_text);
	}
}
