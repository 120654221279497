.b-footer {
	--content-width: 1920px;

	text-align: center;

	&-content {
		@include media(m-up) {
			@include site-width;
		}

		@include media(s) {
			overflow: hidden;
		}
	}

	&-newsletter {
		@include site-width;

		margin-bottom: 40px;
	}

	&-social {
		@include site-width;

		margin-bottom: 90px;

		@include media(s) {
			margin-bottom: 36px;
		}
	}

	&-columns {
		@include site-width;

		@include media(m-up) {
			display: flex;
			justify-content: center;
		}

		@include media(s) {
			@include g-accordion;

			border-top: 1px solid color(lighter5);
			padding: 0;
		}
	}

	&-copy {
		@include font(regular, 10px);

		border-top: 1px solid color(lighter5);
		margin-top: 0;
		padding: 15px 0;

		@include media(s) {
			margin-top: 0;
		}
	}
}
