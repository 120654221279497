/*md
# Country selector

```html_example
<div class="b-country_selector_modal">
	<p class="b-country_selector_modal-title">
		Delivering<br/>
		to United Kingdom?
	</p>
	<p class="b-country_selector_modal-description">Shop our UK website for delivery in 5 - 7 days from £1.95</p>
	<form class="b-country_selector_modal-form"
		action="/#!"
	>
		<div class="f-field_group m-block">
			<div class="f-field">
				<label class="f-select">
					<span class="f-select-label">Delivery country</span>
					<span class="f-select-element_wrapper">
						<select class="f-select-element" required>
							<option value="UK">United Kingdom</option>
						</select>
					</span>
				</label>
			</div>

			<div class="f-field">
				<label class="f-select">
					<span class="f-select-label">Currency</span>
					<span class="f-select-element_wrapper">
						<select class="f-select-element" required>
							<option value="gbp">GBP</option>
						</select>
					</span>
				</label>
			</div>
		</div>

		<p class="b-country_selector_modal-notification">
			Once you change the delivery country, you will be redirected
			to the Shopping Bag of the selected country to proceed further
			with your purchase.
		</p>

		<button class="b-country_selector_modal-submit" type="submit">Confirm</button>
	</form>
</div>
*/

.b-country_selector_modal {
	max-width: 300px;

	&-title {
		@include t-title_3;

		margin-bottom: rh(5);
		text-align: center;
	}

	&-description {
		@include font(regular, 14px, 1.4);

		margin-bottom: rh(10);
	}

	&-notification {
		@include g-error;

		line-height: 1.5;
		margin: rh(4 0);
	}

	&-submit {
		@include g-button_1;
	}

	.f-select {
		display: block;
	}
}
