/*md
# Simple error message

## Error notification
```html_example
<div class="b-simple_error">
    Please choose shade
</div>
```
*/

.b-simple_error {
	@include g-error;

	font-size: 14px;

	&:empty {
		display: none;
	}
}
