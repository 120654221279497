@mixin g-button_2($el: root, $mod: default) {
	@if $mod == default {
		@include g-button;

		background-color: color(light);
		border-color: color(lighter4);

		@include media (l-up) {
			&:hover {
				border-color: color(lighter3);
			}
		}
	}

	@if $mod == centered {
		margin: 0 auto;
		padding-left: rh(12);
		padding-right: rh(12);
		width: auto;

		@supports (width: fit-content) {
			width: fit-content;
		}
	}
}
