.b-cookies {
	&-container {
		@include g-message;

		bottom: 0;
		left: 0;
		position: fixed;
		width: 100%;
		z-index: 3;
	}

	&-close {
		color: color(light);
		cursor: pointer;
		position: absolute;
		right: rh(10);
		top: rh(4);

		@include icon(close) {
			font-size: 13px;
		}

		@include media(s) {
			right: rh(2);
			top: rh(2);
		}
	}

	&-checkbox {
		display: none;
	}

	&-checkbox:checked + &-container {
		display: none;
	}
}
