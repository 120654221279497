@mixin g-accordion(
	$_: root

) {
	@if $_ == root {
		-webkit-tap-highlight-color: color(transparent);
	}

	@if $_ == _control {
		@include icon(plus, after);

		cursor: pointer;
		display: block;
		position: relative;

		&::after {
			color: color(lighter3);
			pointer-events: none;
			position: absolute;
			right: 0;
			transform-origin: 50% 50%;
		}
	}

	@if $_ == _control_expanded {
		&::after {
			color: color(dark);
			content: icon-char(minus);
		}
	}

	@if $_ == _content {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		position: relative;
		transition: max-height 0.3s cubic-bezier(0, 1, 1, 1), opacity 0.3s, visibility 0.3s ease 0.3s, padding 0.3s;
		visibility: hidden;
		will-change: max-height, opacity, visibility;
	}

	@if $_ == _content_expanded {
		max-height: 1000px;
		opacity: 1;
		transition: max-height 0.3s cubic-bezier(1, 0, 1, 1), opacity 0.3s;
		visibility: visible;
	}
}

@mixin g-accordion-styles(
	$_: root,
	$mod: default
) {
	@if $_ == root {
		@include g-accordion;

		border-top: 1px solid color(lighter4);
	}

	@if $_ == _item {
		border-bottom: 1px solid color(lighter4);
	}

	@if $_ == _control {
		@if $mod == default {
			@include g-accordion(_control);
			@include font(medium, 12px, 1.2);

			color: color(lighter2);
			padding: rh(4 10 4 5);
			text-transform: uppercase;

			&::after {
				font-size: 14px;
				right: 20px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		@if $mod == full {
			padding: rh(4 5 4 0);

			&::after {
				right: 0;
			}
		}
	}

	@if $_ == _control_expanded {
		@include g-accordion(_control_expanded);
		@include font(bold);

		color: color(dark);
	}

	@if $_ == _content {
		@if $mod == default {
			@include g-accordion(_content);

			padding: 0 20px;
		}

		@if $mod == full {
			padding-left: 0;
			padding-right: 0;
		}
	}

	@if $_ == _content_expanded {
		@include g-accordion(_content_expanded);

		padding: rh(2) 20px rh(7);
	}
}
