.b-customer_service_menu {
	line-height: 10px;

	@include media(m-up) {
		display: flex;
		position: relative;

		&:hover &-dropdown,
		&:hover &-dropdown_list {
			visibility: visible;
		}
	}

	&-control {
		@include g-button_control {
			@include font(regular, 10px);

			color: color(dark);
			text-transform: uppercase;
		}

		@include media('m-down') {
			align-items: center;
			display: flex;

			@include icon(arrow-down, after) {
				font-size: 5px;
				margin-left: rh(1);
				opacity: 0.5;
			}
		}
	}

	&-dropdown {
		@include media(m-up) {
			background: color(light);
			border: 1px solid color(dark);
			border-top: none;
			min-width: 125px;
			padding: rh(2 4 4);
			position: absolute;
			top: 100%;
			visibility: hidden;
			z-index: 99;
		}

		@include media(m) {
			right: 0;
		}
	}

	&-dropdown_list {
		@include font(regular, 12px);

		display: flex;
		flex-direction: column;

		@include media(s) {
			background: color(light);
		}

		@include media(m) {
			position: static;
		}
	}

	&-dropdown_item,
	&-back_button_cta {
		@include media(s) {
			border-bottom: 1px solid color(lighter5);
			padding: rh(5);
		}
	}

	&-back_button_cta {
		@include g-back_button;

		background: none;
		border-bottom: 1px solid color(lighter5);
		min-height: 55px;

		@include media(m-up) {
			display: none;
		}

		&::before {
			color: color(dark);
			font-size: 9px;
			margin-right: 10px;
			opacity: 1;
		}
	}

	&-dropdown_item {
		@include media(m-up) {
			padding: rh(2 0);

			&:hover {
				text-decoration: underline;
			}
		}

		@include media(s) {
			text-transform: uppercase;
		}
	}
}
