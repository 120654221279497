.b-bonus_confirmation {
	margin: 0 auto;
	width: 500px;

	@include media(s) {
		width: 100%;
	}

	&-title {
		@include font(bold, 22px);

		margin-bottom: rh(9);
		text-transform: uppercase;
	}

	&-description {
		@include font(regular, 14px);

		margin-bottom: rh(8);
	}

	&-actions {
		display: flex;

		@include media(m-up) {
			margin: rh(0 -2.5);
		}

		@include media(s) {
			flex-direction: column;
		}
	}

	&-reject,
	&-confirm {
		@include media(m-up) {
			margin: rh(0 2.5);
		}
	}

	&-reject {
		@include g-button_2;

		@include media(s) {
			order: 2;
		}
	}

	&-confirm {
		@include g-button_1;

		@include media(s) {
			margin-bottom: rh(3);
			order: 1;
		}
	}
}
