@mixin link(
	$type: underlined,
	$link_color: lighter1
) {
	@if $type == underlined {
		@include font(regular, 14px);

		color: color($link_color);
		cursor: pointer;
		text-decoration: underline;
		@content;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	@if $type == regular {
		@include font(regular, 14px);

		color: color($link_color);
		cursor: pointer;
		text-decoration: none;
		@content;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}
