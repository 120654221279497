/*md
# 1. Vimeo Player

```html_example
<figure class="b-vimeo h-site_width" style="padding-top:50px;">
    <p class="b-vimeo-beyond_text">Who we are</p>

	<div class="b-vimeo-2cta_div" style="padding-bottom: 20px;">
		<button class="b-button_4">WHO WE ARE</button>
		<button class="b-button_4">READ OUR STORY</button>
	</div>

    <div class="b-vimeo-video">
       <iframe src="https://player.vimeo.com/video/342977721?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</figure>
```

# 2. Vimeo Player with full screen background.

```html_example
<figure class="b-vimeo m-full_bg" style="--urlbg: url(//placehold.it/1920x1080)">
    <div class="b-vimeo-video">
       <iframe src="https://player.vimeo.com/video/342977721?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</figure>
```

# 2. Vimeo Player with side margin without background with different video for mobile.

```html_example
<figure class="b-vimeo m-full_bg m-no_bg">
    <div class="b-vimeo-video_desktop">
       <iframe src="https://player.vimeo.com/video/342977721?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
	</div>
	<div class="b-vimeo-video_mobile" style="margin-bottom:20px;">
       <iframe src="https://player.vimeo.com/video/343457901?autoplay=1&loop=1&autopause=0" width="640" height="300" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
    </div>
</figure>
```
*/

.b-vimeo {
	align-items: center;
	display: flex;
	flex-direction: column;
	position: relative;
	width: 100%;

	&-beyond_text {
		@include font(beyond_infinity, 150px, 1);

		color: color(dark);
		text-align: center;

		@include media(s) {
			font-size: 88px;
		}
	}

	&-beyond_smalltext {
		@include font(regular, 16px, 1);

		color: color(dark);
		text-align: center;

		@include media(s) {
			max-width: 350px;
		}
	}

	&-2cta_div {
		display: flex;
		justify-content: space-between;
		width: 530px;

		@include media(s) {
			width: 310px;
		}
	}

	&-video {
		padding-top: 47.25%;
		position: relative;
		width: 100%;
	}

	&-video_mobile {
		padding-top: 100%;
		position: relative;
		width: 100%;

		@include media(m-up) {
			display: none;
		}
	}

	&-video_desktop {
		padding-top: 56.25%;
		position: relative;
		width: 100%;

		@include media(s) {
			display: none;
		}
	}

	&.m-4725 &-video_desktop {
		padding-top: 47.25%;
	}

	&.m-5x4 &-video {
		padding-top: 125.25%;
	}

	&.m-full_bg {
		background-image: var(--urlbg);
		background-repeat: no-repeat;
		background-size: cover;
		margin-left: auto;
		margin-right: auto;
		padding: rh(0 30);

		@include media(s) {
			padding: 0;
		}
	}

	&.m-multi_bg {
		background-image: var(--urlbg);
		background-repeat: no-repeat;
		background-size: 100%;
		margin-left: auto;
		margin-right: auto;
		padding: 0 8.2%;

		@include media(s) {
			background-position-x: 94%;
			background-size: 171%;
			margin-left: auto;
			margin-right: auto;
			padding: 0;
		}
	}

	&.m-no_bg {
		background-image: none;

		@include media(m-up) {
			padding: rh(0 10);
		}
	}

	iframe {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&.m-full_bg &-video {
		padding: rh(28);
		padding-top: 47.25%;
		position: relative;
		width: 100%;
	}
}
