.b-country_selector_header {
	display: flex;
}

.b-country_selector {
	display: flex;

	&-separator,
	&-currency {
		display: none;

		@include media(m-down) {
			display: inline;
		}
	}

	&-separator {
		margin: 0 5px;
	}

	&-flag {
		height: 12px;
		margin-right: rh(1);
		width: 16px;

		@include media(s) {
			height: 13px;
			margin-right: rh(2);
			width: 20px;
		}
	}

	&-control {
		align-items: center;
		display: flex;

		@include g-button_control {
			@include font(regular, 10px);

			text-transform: uppercase;

			@include media(l-up) {
				color: color(light);

				@include icon(arrow-down, after) {
					align-self: center;
					font-size: 5px;
					margin-left: rh(1);
					opacity: 0.5;
				}
			}
		}

		.b-nav-item_1--m-hidden & {
			@include media(m-up) {
				color: color(dark);
			}
		}
	}

	&-lang {
		@include media(m-up) {
			align-self: center;
		}
	}
}
