// stylelint-disable
.dw-apple-pay-button.dw-apple-pay-logo-black {
	min-height: 40px;
	min-width: 200px;

	@supports (-webkit-appearance: -apple-pay-button) {
		border-radius: 0;
		height: 40px;
		min-width: 200px;
		width: 100%;
		-webkit-appearance: -apple-pay-button;
		-apple-pay-button-type: buy;
		-apple-pay-button-style: white-outline;
	}

	@include media(s) {
		min-width: 100%;
		width: 100%;
	}
}
// stylelint-enable
