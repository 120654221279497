@mixin f-input_email($style: null) {
	@if $style == _style_1 {
		.f-input_email-field {
			@include f-input_field(_style_1);
		}
	} @else {
		.f-input_email {
			&-field {
				@include f-input_field;

				&:read-only {
					background-color: color(lighter5);
				}
			}

			&.m-error &-field {
				@include f-input_field(error);
			}

			&.m-disabled &-field {
				@include f-input_field(disabled);
			}

			&.m-prefilled &-field {
				@include f-input_field(prefilled);
			}

			&-label {
				@include f-input_label;
			}

			// &.m-required &-label {
			// 	@include f-input_label($mod: required);
			// }
			&-optional_copy {
				@include f-input_label(_optional_copy);
			}

			&-label_description {
				@include f-input_label(_label_description);
			}

			&-label_link {
				@include f-input_label(_label_link);
			}
		}
	}
}
