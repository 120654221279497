@mixin f-radio {
	.f-input_radio {
		display: inline-block;
		position: relative;

		&-label {
			cursor: pointer;
			display: inline-block;
			padding-left: rh(7);
			position: relative;

			&::before {
				background-color: color(light);
				border-radius: 50%;
				box-shadow: 0 0 0 1px color(dark);
				content: '';
				height: 15px;
				left: 0;
				margin: 2px;
				position: absolute;
				width: 15px;
			}
		}

		&-field:checked + &-label::before {
			background-color: color(dark);
			border: 4px solid color(light);
		}

		&-field {
			clip: rect(1px, 1px, 1px, 1px);
			position: absolute;
		}
	}
}
