.b-shortproduct {
	display: flex;

	&-picture {
		flex-shrink: 0;
		width: 100px;
	}

	&-image_link {
		@include image_container;

		display: block;
	}

	&-image {
		@include image_container(_children);
	}

	&-content {
		flex-grow: 1;
		padding: rh(0 5);
	}

	&-category {
		@include font(medium, 10px);

		margin-bottom: rh(4);
		text-transform: uppercase;
	}

	&-category_link {
		color: color(lighter2);
	}

	&-title,
	&-price {
		margin: rh(4 0);
	}

	&-title {
		@include font(bold, 14px);
	}
}
