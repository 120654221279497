/*md
# Blogpost tile banner

Text tile with an image, a tiny content type label, a title and a read more CTA

<style>
	.g-blogpost_tile {
		max-width: 300px;
	}
</style>

```html_example
<figure class="g-blogpost_tile">
	<img class="g-blogpost_tile-image" alt="blogpost-hero" src="//placehold.it/250x150" />
	<figcaption class="g-blogpost_tile-content">
		<p class="g-blogpost_tile-label">Blog</p>
		<p class="g-blogpost_tile-title">Saturday Sparcles</p>
		<p class="g-blogpost_tile-info">
			Our eyes are often the first to shows signs of ageing, as the skin that surrounds them is fragile. A lack of naturel oils together with frequent...
		</p>
		<a href="#!" class="g-blogpost_tile-cta">Read more</a>
	</figcaption>
</figure>
```
*/

.g-blogpost_tile {
	@include g-blogpost_tile;

	&-image {
		@include g-blogpost_tile(_image);
	}

	&-content {
		@include g-blogpost_tile(_content);
	}

	&-label {
		@include g-blogpost_tile(_label);
	}

	&-title {
		@include g-blogpost_tile(_title);
	}

	&-info {
		@include g-blogpost_tile(_info);
	}

	&-cta {
		@include g-blogpost_tile(_cta);
	}
}
