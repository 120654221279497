/*md
# Back to top button

```html_example
<style>
	.atlas-playground .b-button_back_to_top {position: static;}
</style>
<button type="button" class="b-button_back_to_top m-active" data-component="global/BackToTop">Top</button>
```
*/

.b-button_back_to_top {
	--side-margin: calc((100vw - 1440px) / 2);

	$button-back-to-top-size: 50px;

	@include font(medium, 12px);
	@include g-button-control;

	background: color(dark);
	bottom: 80px;
	color: color(light);
	height: $button-back-to-top-size;
	opacity: 0;
	pointer-events: none;
	position: fixed;
	right: var(--side-margin);
	text-transform: uppercase;
	transition: opacity 0.5s;
	width: $button-back-to-top-size;
	z-index: 3;

	@include icon(arrow-up) {
		display: block;
		font-size: 7px;
		margin-bottom: rh(2);
		text-align: center;
	}

	@include media(l) {
		--side-margin: calc((100vw - 1200px) / 2);
	}

	@include media(m) {
		--side-margin: 34px;
	}

	@include media(s) {
		--side-margin: 20px;

		bottom: 20px;
	}

	&.m-active {
		opacity: 1;
		pointer-events: auto;
	}
}
