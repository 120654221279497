.b-footer_social {
	&-title {
		@include font(bold, 14px);

		margin-bottom: 22px;
		text-transform: uppercase;
	}

	&-list {
		display: flex;
		justify-content: center;
	}

	&-item + &-item {
		margin-left: 30px;
	}

	&-link {
		&::before {
			font-size: 24px;
			transition: color 0.3s ease;

			@include media(s) {
				font-size: 22px;
			}
		}

		&:hover::before {
			color: color(lighter1);
		}
	}
}
